import {
  CloseOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Form, Modal, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import Upload, { UploadChangeParam, UploadFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import Buttons from "components/Global/Buttons";
import React, { useState } from "react";
import { toastText } from "utils/utils";
import styles from "./index.module.scss";
import { UploadShipmentProps } from "./types";
import {  getApiExcel, postApi } from "redux/apis";
// import * as XLSX from "xlsx";
import MissingPartsModal from "../MissingSparePartModel";


const UploadShipmentModal: React.FC<UploadShipmentProps> = (props) => {
  const { handleCancel, isUploadModalOpen, fetchShipment } = props;

  const [files, setFiles] = useState<UploadFile[]>([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [showMissingParts, setShowMissingParts] = useState(false);
  const [missingParts, setMissingParts] = useState([]);
  const [isToastShown, setIsToastShown] = useState(false);

  
  const uploadFiles = async () => {
    setIsUploadLoading(true);
    try {
      const formData = new FormData();

      for (const uploadFile of files) {
        const file = uploadFile.originFileObj;
        if (!file) {
          toastText(`File object is missing for: ${uploadFile.name}`, "error");
          continue;
        }
        formData.append("files", file);
      }

      const response = await postApi(
        "/shipment/upload-shipments",
        formData,
        true
      );
      const { processed, updated, created } = response.data.data;

      toastText(
        `File processed successfully. ${processed} items processed (${created} created, ${updated} updated)`,
        "success"
      );
      fetchShipment();
      closeModal();
    } catch (err: any) {
      const errorMessage =
        err.response?.data?.error?.message || "Failed to process file";
      const missingParts =
        err.response?.data?.error?.additionalInfo?.missingParts;
      const validationErrors = err.response?.data?.error?.validationErrors;

      if (missingParts) {
        setMissingParts(missingParts);
        setShowMissingParts(true);
      } else if (validationErrors) {
        // Handle validation errors (like date format issues)
        toastText(errorMessage, "error");
      } else {
        toastText(errorMessage, "error");
      }
    } finally {
      setIsUploadLoading(false);
    }
  };
  const closeModal = () => {
    setFiles([]);
    handleCancel();
  };

  const propsUpload = {
    name: "file",
    accept: ".xls,.xlsx",
    multiple: true,
    fileList: files,
    beforeUpload: (file: UploadFile) => {
      // Check 1: Maximum number of files
      if (files.length >= 5) {
        if (!isToastShown) {
          toastText("Maximum 5 files allowed!", "error");
          setIsToastShown(true);
        }
        return Upload.LIST_IGNORE;
      }

      // Check 2: Valid file type
      const isValidType = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ].includes(file.type!);

      if (!isValidType) {
        toastText(
          "Invalid file type! Only Excel files (.xls, .xlsx) are allowed.",
          "error"
        );
        return Upload.LIST_IGNORE;
      }

      // Check 3: Individual file size limit (5MB)
      const isLt5M = file.size! / 1024 / 1024 < 5;
      if (!isLt5M) {
        toastText("File must be smaller than 5MB!", "error");
        return Upload.LIST_IGNORE;
      }

      // Check 4: Total size of all files
      const totalSize =
        files.reduce((sum, existingFile) => sum + (existingFile.size || 0), 0) +
        (file.size || 0);
      const totalSizeInMB = totalSize / 1024 / 1024;

      if (totalSizeInMB > 5) {
        toastText("Total size of all files must be smaller than 5MB!", "error");
        return Upload.LIST_IGNORE;
      }

      return false;
    },
    onChange(info: UploadChangeParam<UploadFile>) {
      const { fileList } = info;
      if (fileList.length <= 5) {
        setFiles(fileList);
        setIsToastShown(false);
      } else {
        setFiles(fileList.slice(0, 5));
      }
    },
    onRemove: (file: UploadFile) => {
      const index = files.indexOf(file);
      const newFileList = files.slice();
      newFileList.splice(index, 1);
      setFiles(newFileList);
    },
  };

  const myButtons = [
    {
      text: "Upload",
      isLoading: isUploadLoading,
      className: "primary-button",
      fontSize: "1.8rem",
      minWidth: "12rem",
      minHeight: "4rem",
      isSubmit: false,
      disabled: isUploadLoading,
      onclick: () => {
        if (files.length === 0) {
          toastText("Please select at least one file", "error");
          return;
        }
        uploadFiles();
      },
    },
  ];

  const downloadExcel = async () => {
    try {
      const response = await getApiExcel("/shipment/excel/sample");
      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelUrl = URL.createObjectURL(excelBlob);
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", "Shipment_Sample.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      toastText("Something went wrong in downloading excel template", "error");
    }
  };

  return (
    <>
      <Modal
        open={isUploadModalOpen}
        // onOk={handleSubmit}
        onCancel={closeModal}
        maskClosable={true}
        okText={"Save"}
        closable={false}
        width={600}
        footer={null}
        className="addHolidayModal"
      >
        <div className={`${styles["modal"]} modal`}>
          <div className={styles["modal-header"]}>
            <Title level={4}>Upload Shipments</Title>
            <div className={styles["right-align-items"]}>
              <div>
                <Tooltip title="This is the sample data, remove it before you upload your data">
                  <p
                    className={styles["template-link"]}
                    onClick={downloadExcel}
                  >
                    <DownloadOutlined />
                    Download Template
                  </p>
                </Tooltip>
              </div>
              <div className={styles["close-icon"]} onClick={closeModal}>
                <CloseOutlined />
              </div>
            </div>
          </div>
          <div>
            <div className={styles["modalUploadContainer"]}>
              <Form.Item name="file">
                <Dragger {...propsUpload} className="test">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint">
                    Drag and drop files or choose files from Device
                    <br />
                    Supported file formats:
                    <br />
                    XLS, XLSX |{" "}
                    <span className="color-purple">Maximum 5 files, 5MB</span>
                  </p>
                </Dragger>
              </Form.Item>
            </div>

            <div className={styles["buttons"]}>
              <Buttons buttons={myButtons} />
            </div>
          </div>
        </div>
      </Modal>

      {missingParts?.length > 0 && (
        <MissingPartsModal
          isVisible={showMissingParts}
          handleClose={() => {
            setShowMissingParts(false);
            setMissingParts([]);
          }}
          missingParts={missingParts}
        />
      )}
    </>
  );
};

export default UploadShipmentModal;
