import { FC } from 'react';
import { UserNameBoxProps } from './types';
import styles from './index.module.scss';

// Header name showing 1 section
const UserNameBox: FC<UserNameBoxProps> = (props) => {
	// Initialize variables and destructure props
	const { name, imageUrl } = props;

	// Split the name into an array of first and last name
	const nameArr = name.split(' ');

	// Create a new name abbreviation from the first letters of the first and last name
	const newName = (nameArr[0]?.charAt(0) || '') + (nameArr[1]?.charAt(0) || '');

	// Render the user name box component
	// If the name exists, display the user's profile image if available, or the abbreviation of their name
	return name ? (
		<div className={styles['user-name-box']}>
			{imageUrl ? (
				<img
					src={imageUrl}
					className={styles['user-name-box__profile-image']}
				/>
			) : (
				<p className={styles['user-name-box__name']}>{newName}</p>
			)}
		</div>
	) : null;
};

export default UserNameBox;
