import { Button, Space, Table } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import IntegrationModal from "components/Global/IntegrationModel";
import { DynamicCategoryTableProps } from "./types";
import { useNavigate } from "react-router";
import { toastText } from "utils/utils";

const DynamicCategoryTable: FC<DynamicCategoryTableProps> = (props) => {
  // Inits

  const navigate = useNavigate();

  const { dataSource, type } = props;

  const { Column } = Table;

  const [formData, setFormData] = useState<FormData | undefined>(undefined);
  const [titleKey, setTitleKey] = useState<string | undefined>(undefined);
  const [softwareType, setSoftwareType] = useState<string | undefined>(
    undefined
  );
  const [ButtonLabel, SetButtonLabel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectCompany, setSelectCompany] = useState(false);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleStorageChange = (data: any) => {
    const { key } = data;
    if (key === "callBackUpdatedData") {
      const callbackdata = JSON.parse(localStorage.getItem(key) as string);
      if (!callbackdata.error) {
        toastText("App Connected Successfully", "success");
        navigate("/settings/integrations/connectedApps");
      } else {
        toastText(callbackdata?.message || "Fail to Connect", "error");
        setIsModalOpen(false);
      }
    }
  };

  //   For cancel operation
  const handleCancel = () => {
    setSelectCompany(false);
    setIsModalOpen(false);
  };
  //
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onConnect = async (card: CardInterface) => {
    if (card.formData) {
      setIsLoading(true);
      setFormData(card.formData);
      setTitleKey(card.titleKey);
      setSoftwareType(card.type);
      showModal();
    }
    //Clear Books
    if (card.titleKey == "Clear Books") {
      setTitleKey(card.titleKey);
    }

    //QBD
    if (card.titleKey === "QuickbooksDesktop") {
      navigate("/settings/integrations/qbd");
    }
  };

  // JSX
  return (
    <div className={"dynamic-table"}>
      <Table
        dataSource={dataSource}
        scroll={{ y: "calc(85vh - 190px)", x: "63vh" }}
        className="connectButtons table-global"
        pagination={false}
        // onChange={tableChangeHandler}
      >
        <Column
          title={`${type} Apps`}
          dataIndex="titleKey"
          key="titleKey"
          width={"10%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: CardInterface) => (
            <>
              <div className={styles[`bg-${record.cssSpriteKey}`]}> </div>
            </>
          )}
        />

        <Column
          title="Description"
          dataIndex="description"
          key="description"
          width={"25%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: CardInterface) => {
            return (
              <>
                <span style={{ fontWeight: "bold" }}>
                  {record.DescriptionKey + " "}
                </span>
                <span>{record.description}</span>
              </>
            );
          }}
        />
        <Column
          title="Action"
          dataIndex="buttonText"
          key="buttonText"
          className="bg-white"
          width={"5%"}
          render={(values, data: CardInterface) => (
            <Space size={20}>
              {!(data.isCompanyAdmin || data.isAdmin) ? (
                <>
                  <div className="cursor-pointer flex align-center justify-center">
                    <Button
                      type={"primary"}
                      ghost={data.ghost}
                      className={styles.connectButtons__btnConnect}
                      onClick={() => onConnect(data)}
                      danger={data?.connect}
                    >
                      {data.buttonText}
                    </Button>
                  </div>
                </>
              ) : null}
            </Space>
          )}
        />
      </Table>
      <IntegrationModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        formData={formData}
        isLoading={isLoading}
        titleKey={titleKey}
        SetButtonLabel={SetButtonLabel}
        ButtonLabel={ButtonLabel}
        softwareType={softwareType}
        selectCompany={selectCompany}
        setSelectCompany={setSelectCompany}
      />
    </div>
  );
};

export default DynamicCategoryTable;

interface CardInterface {
  cssSpriteKey: string;
  title: string;
  description: string;
  buttonText: string;
  logo: string;
  ghost: boolean;
  select: boolean;
  type: string;
  connect: boolean;
  titleKey: string;
  formData?: FormData;
  isCompanyAdmin: boolean;
  isAdmin: boolean;
  DescriptionKey: string;
}

interface FormData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
