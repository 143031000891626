export const capitalizeFirstLetter = (inputString:string) => {
  // Check if the inputString is a string and not empty
  if (typeof inputString !== 'string' || inputString.length === 0) {
    throw new Error('Input must be a non-empty string');
  }

  // Convert the first character to uppercase
  const firstCharUpperCase = inputString.charAt(0).toUpperCase();

  // Combine the first uppercase character with the rest of the string
  return firstCharUpperCase + inputString.slice(1);
};

