import { Col, Image, Row, Space, Typography } from "antd";
import { FORMDATA } from "constants/Data";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApi } from "redux/apis";
import { UUID, exportXmlFile, toastText } from "utils/utils";
import { v4 as uuidv4 } from "uuid";
import IntegrationModal from "../IntegrationModel";
import styles from "./index.module.scss";

const QuickbookDesktop = () => {
  const { Title } = Typography;

  let connectionID = useSelector((state: any) => state?.qbdUser?.data);
  const connectedAppsCount = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  if (!connectionID) {
    if (connectedAppsCount && connectedAppsCount.length > 0) {
      // Assuming the first object in the array contains the connectionDetails
      connectionID = connectedAppsCount[0]?.companyId;
    }
  }
  // State and variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ButtonLabel, SetButtonLabel] = useState(false);
  const [uniqueUsername, SetuniqueUsername] = useState("");

  // Handle cancel action for IntegrationModal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle submit action for IntegrationModal
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Show IntegrationModal
  const showModal = () => {
    setIsModalOpen(true);
    const val = generateUniqueUsername();
    SetuniqueUsername(val);
  };

  // Download QWC (QuickBooks Configuration) file
  const downloadQWC = async () => {
    if (connectionID) {
      try {
        postApi(`/connection/generateQBWCXML`, {
          id: connectionID,
        }).then(async (res: any) => {
          if (res?.status === 200) {
            exportXmlFile(res?.data?.data, `QBDIntegrationConfig_${UUID()}`);
            toastText("Downloaded Successfully", "success");
            await handleOk(); // Handle submission logic
          } else {
            await handleCancel();
            toastText("Failed to download", "error");
          }
        });
      } catch (error) {
        await handleCancel();
        toastText("Fail to download", "error");
      }
    }
  };
  return (
    <div className={styles["main"]}>
      <Row>
        <Space direction="vertical" size={20}>
          <Col span={24}>
            <Image
              src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/quickbooks-desktop.png`}
              preview={false}
              height={50}
              crossOrigin={
                process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
              }
            />
          </Col>
          <Col span={24}>
            <Title level={4} style={{ color: "#286fd1" }}>
              Quickbooks Desktop Configuration Steps
            </Title>
          </Col>
          <Col span={24}>
            <Title level={5}>
              Step 1: Please Start QuickBooks Desktop Application.
            </Title>
            <Title level={5}>
              Step 2: Select Your Company File and open it. If you don&apos;t
              have any company, then create one by clicking on &quot;Create a
              new company.&quot;
            </Title>
            <Title level={5}>
              Step 3: Once QuickBooks starts with the company, open Web
              Connector to connect with the app.
            </Title>
            <Title level={5}>Step 4: Now run your web connector</Title>
            <Title level={5}>
              Step 5: Now click on &quot;Enter Password&quot; and remember the
              same.
            </Title>
            <Title level={5}>
              <button
                disabled={connectedAppsCount && connectedAppsCount.length > 0}
                className={`btn-blue ${
                  connectedAppsCount && connectedAppsCount.length > 0
                    ? "btn-disabled"
                    : ""
                }`}
                onClick={() => {
                  showModal();
                }}
              >
                Enter Password
              </button>
            </Title>
            <Title level={5}>
              Step 6: Now you need to download the QuickBooks Desktop
              Configuration file by clicking on the &quot;DOWNLOAD CONFIGURATION
              FILE&quot; button.
            </Title>
            <Title level={5}>
              <button
                className={`btn-blue ${
                  connectedAppsCount &&
                  connectedAppsCount.length > 0 &&
                  connectedAppsCount[0]?.connectionDetails?.isActiveConnection
                    ? "btn-disabled"
                    : ""
                }`}
                onClick={downloadQWC}
                disabled={
                  connectedAppsCount &&
                  connectedAppsCount.length > 0 &&
                  connectedAppsCount[0]?.connectionDetails?.isActiveConnection
                }
              >
                Download Configuration File
              </button>
            </Title>
            <Title level={5}>
              Step 7: Now open the web connector and click on the &quot;Add
              Application&quot; button.
            </Title>
            <Title level={5}>Step 8: Go to the File Save Path.</Title>
            <Title level={5}>
              Step 9: Select the downloaded file and click on Open. Note: You
              must start QuickBooks Desktop and open your company where you need
              to sync data.
            </Title>
            <Title level={5}>
              Step 10: Now you need permission for the certification of
              Encryption Connection. Please allow for Certification.
            </Title>
            <Title level={5}>
              Step 11: One more permission is required for the certification of
              QuickBooks connection. Please allow for Certification.
            </Title>
            <Title level={5}>
              Step 12: Now you need to type the password into the web connector.
              This password can be found from the App Connection Wizard.
            </Title>
            <Title level={5}>
              Step 13: Now your Web Connector is ready to serve data.
            </Title>
          </Col>
        </Space>
      </Row>
      <IntegrationModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        formData={FORMDATA.qbdIntegrationFields}
        titleKey={"QuickbooksDesktop"}
        SetButtonLabel={SetButtonLabel}
        ButtonLabel={ButtonLabel}
        softwareType="Accounting"
        uniqueUsername={uniqueUsername}
      />
    </div>
  );
};

export default QuickbookDesktop;

function generateUniqueUsername() {
  const timestamp = Date.now(); // Use a timestamp as a unique identifier
  const uniqueIdentifier = uuidv4();

  const uniqueUsername = `${timestamp}-${uniqueIdentifier}`;
  return uniqueUsername;
}
