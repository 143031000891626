import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputRef,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FORMDATA } from "constants/Data";
import { useEffect, useState, useRef } from "react";
import { getApi, putApi } from "redux/apis";
import styles from "./index.module.scss";
import { toastText } from "utils/utils";
import "./index.scss";
import dayjs from "dayjs";

interface RegisterModalProps {
  open: boolean;
  onCancel: () => void;
  fetchRegisterData: any;
  selectedRegisterSP: any;
  isAdmin: any;
}

interface ServiceCenter {
  value: string;
  label: string;
}

export const RegisterSpareParts = (props: RegisterModalProps) => {
  const { open, onCancel, fetchRegisterData, selectedRegisterSP, isAdmin } = props;
  const { registerSparePartsFields } = FORMDATA;
  const [form] = Form.useForm();
  const [serviceCenters, setServiceCenters] = useState([]);
  const [items, setItems] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [isSparePartDisabled, setIsSparePartDisabled] = useState(true);
  const [isProductDisabled, setIsProductDisabled] = useState(true);
  const [isServiceCenterDisabled, setIsServiceCenterDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedServiceCenter, setSelectedServiceCenter] = useState<
    string | null
  >(null);

  const customerNameInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        customerNameInputRef.current?.focus();
      }, 100); // Adding a slight delay ensures focus is set correctly
    }
  }, [open]);

  const onFinish = async (values: any) => {
    const finalData = {
      serviceCenter: values.serviceCenterName,
      status: values.status,
      productName: values.quickbookProduct,
      productDescription: values.productDescription,
      inventoryId: selectedRegisterSP ? selectedRegisterSP.id : "",
      sparePartItem: values.sparePart,
      useDate: values.useDate ? values.useDate.format("YYYY-MM-DD") : null,
      customerName: values.customerName,
      serviceCenterTicket: values.serviceCenterTicket,
      comments: values.comments,
    };

    try {
      setLoading(true);
      const response = await putApi("/inventory/registerSpareParts", finalData);
      const message =
        response?.data?.message || "Spare part details created successfully.";
      toastText(message, "success");
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
      form.resetFields();
      onCancel();
      fetchRegisterData();
    }
  };

  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          setServiceCenters(
            response.data.data.map((center: any) => ({
              value: center.id,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await getApi("/shipment/getProducts?type=false");
        if (response.data.data.length > 0) {
          setProducts(
            response.data.data.map((product: any) => ({
              value: product.name,
              label: product.name,
              description: product.description,
            }))
          );
        } else {
          setProducts([]);
        }
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };
    fetchServiceCenters();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (selectedRegisterSP) {
      form.setFieldsValue({
        serviceCenterName: selectedRegisterSP.serviceCenter,
        quickbookProduct: selectedRegisterSP.productName,
        sparePart: selectedRegisterSP.sparePartItem,
        customerName: selectedRegisterSP.customerName,
        serviceCenterTicket: selectedRegisterSP.serviceCenterTicket,
        productDescription: selectedRegisterSP.productDescription,
        useDate: selectedRegisterSP.useDate && 
               selectedRegisterSP.useDate !== "-" && 
               selectedRegisterSP.useDate !== "null" && 
               selectedRegisterSP.useDate !== "undefined"
        ? dayjs(selectedRegisterSP.useDate, "YYYY-MM-DD")
        : undefined,
        status: selectedRegisterSP.status,
        comments: selectedRegisterSP.registerComments,
      });
      setIsServiceCenterDisabled(true);
      setIsSparePartDisabled(true);
      setIsProductDisabled(true);
    } else {
      const storedServiceCenter = localStorage.getItem("selectedServiceCenter");
      if (storedServiceCenter) {
        const parsedServiceCenter = JSON.parse(storedServiceCenter);
        form.setFieldsValue({
          serviceCenterName: parsedServiceCenter.value,
        });
        handleServiceCenterChange(parsedServiceCenter.value);
      }
    }
  }, [selectedRegisterSP, form]);

  const handleServiceCenterChange = async (value: string) => {
    form.setFieldsValue({ sparePart: null, quickbookProduct: null });

    // Only store in localStorage if we're not editing (no selectedRegisterSP)
    if (!selectedRegisterSP) {
      // Find the selected service center from serviceCenters array
      const selectedCenter = serviceCenters.find(
        (center: ServiceCenter) => center.value === value
      ) as ServiceCenter | undefined;

      // Store in localStorage with the required format
      if (selectedCenter) {
        const storageFormat = {
          value: selectedCenter?.value,
          label: selectedCenter?.label,
        };
        localStorage.setItem(
          "selectedServiceCenter",
          JSON.stringify(storageFormat)
        );
      }
    }
    setSelectedServiceCenter(value);
    const response = await getApi(
      `/inventory/dropdown-spareparts?serviceCenterId=${value}`
    );
    if (response.data.data.length > 0) {
      setItems(
        response.data.data.map((center: any) => ({
          value: center.itemNumber,
          label: `${center.itemNumber} - ${center.quantity}`,
        }))
      );
    } else {
      setItems([]);
    }
    setIsSparePartDisabled(false);
    setIsProductDisabled(false);
  };
  const handleUseDateChange = (date: any) => {
    if (date) {
      form.setFieldsValue({ status: "IN_USE" });
    } else {
      form.setFieldsValue({ status: "UNUSED" });
    }
  };
  const handleStatusChange = (value: string) => {
    if (value === "UNUSED") {
      form.setFieldsValue({ useDate: null });
    }
  };
  const handleProductChange = (value: string) => {
    // Find the selected product from the products array
    const selectedProduct = products.find((product) => product.value === value);
    if (selectedProduct) {
      // Set the product description in the form
      form.setFieldsValue({ productDescription: selectedProduct.description });
    }
  };

  useEffect(() => {
    if (products.length > 0 && selectedRegisterSP) {
      form.setFieldsValue({
        quickbookProduct: products.find(
          (product) => product.value === selectedRegisterSP.productName
        )?.value,
      });
    }
  }, [products]);

  useEffect(() => {
    if (items.length > 0 && selectedRegisterSP) {
      form.setFieldsValue({
        sparePart: items.find(
          (product) => product.value === selectedRegisterSP.sparePartItem
        )?.value,
      });
    }
  }, [items]);

  return (
    <Modal
      title={
        selectedRegisterSP ? (
          <div>
            <div>Edit Register Spare Parts</div>
            <div
              style={{
                fontSize: "14px",
                color: "#8C8C8C",
                fontWeight: "normal",
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              For products marked as in use that are changed to unused within
              three days, please ensure you assign a customer during editing.
              Otherwise, the change will not be reflected on the listing screen
            </div>
          </div>
        ) : (
          "Add Register Spare Parts"
        )
      }
      open={open}
      footer={null}
      maskClosable={true}
      closable={false}
      width={800}
      onCancel={onCancel}
      rootClassName="addModalRoot"
      centered={true}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          serviceCenterName: selectedServiceCenter,
          productDescription: "",
          quickbookProduct: null,
          customerName: "",
          useDate: null,
          status: "UNUSED",
          sparePart: null,
          comments: "",
        }}
      >
        <Row gutter={24}>
          {registerSparePartsFields.map((singleField: any, index: number) => {
            if (singleField.id === "comments" && !isAdmin) {
              return null;
            }
            return (
              <Col  span={singleField.id === "productDescription" && isAdmin ? 24 : 12}  style={{ marginBottom: "16px" }} key={index}>
                <label className={styles["side-drawer-form__role--label"]}>
                  {singleField.title}{" "}
                  {singleField?.required && (
                    <span
                      className="required-color"
                      style={{ marginLeft: "5px" }}
                    >
                      *
                    </span>
                  )}
                </label>
                {singleField.id === "customerName" && (
                  <Form.Item
                    name="customerName"
                    rules={[
                      {
                        required: true,
                        message: `Please enter ${singleField.title}`,
                      },
                    ]}
                    normalize={(value) =>
                      value.trimStart().replace(/\s+/g, " ")
                    }
                  >
                    <Input
                      ref={customerNameInputRef}
                      size="large"
                      placeholder={`Enter ${singleField.title}`}
                    />
                  </Form.Item>
                )}

                {singleField.id === "comments" && (
                  <Form.Item name="comments">
                    <Input
                      size="large"
                      placeholder={`Enter ${singleField.title}`}
                    />
                  </Form.Item>
                )}

                {singleField.id === "serviceCenterTicket" && (
                  <Form.Item name="serviceCenterTicket">
                    <Input
                      size="large"
                      placeholder={`Enter ${singleField.title}`}
                    />
                  </Form.Item>
                )}

                {singleField.id === "serviceCenterName" && (
                  <Form.Item
                    name="serviceCenterName"
                    rules={[
                      {
                        required: true,
                        message: `Please select ${singleField.title}`,
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${singleField.title}`}
                      options={serviceCenters}
                      showSearch
                      value={selectedServiceCenter}
                      optionFilterProp="label"
                      filterOption={(input: string, option?: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      // loading={!serviceCenters.length}
                      onChange={(value) => {
                        handleServiceCenterChange(value);
                        setSelectedServiceCenter(value);
                      }}
                      disabled={isServiceCenterDisabled}
                    />
                  </Form.Item>
                )}

                {singleField.id === "quickbookProduct" && (
                  <Form.Item
                    name="quickbookProduct"
                    rules={[
                      {
                        required: true,
                        message: `Please select ${singleField.title}`,
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${singleField.title}`}
                      options={products.map((item) => ({
                        value: item.value,
                        label: (
                          <Tooltip title={item.description} placement="topLeft">
                            <div
                              style={{ lineHeight: "1.2", padding: "2px 0" }}
                            >
                              {item.label}
                              <br />
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "#888",
                                  marginTop: "-4px",
                                }}
                              >
                                {item.description}
                              </span>
                            </div>
                          </Tooltip>
                        ),
                      }))}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        option?.value
                          ?.toString()
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        (option?.label && typeof option.label === "object"
                          ? option.value
                              .toString()
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : String(option?.label)
                              .toLowerCase()
                              .includes(input.toLowerCase()))
                      }
                      disabled={isProductDisabled}
                      onChange={handleProductChange}
                    />
                  </Form.Item>
                )}

                {singleField.id === "sparePart" && (
                  <Form.Item
                    name="sparePart"
                    rules={[
                      {
                        required: true,
                        message: `Please select ${singleField.title}`,
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder={`Select ${singleField.title}`}
                      options={items.map((item) => ({
                        ...item,
                        label: `${item.label}${
                          item.description ? ` :- ${item.description}` : ""
                        }`,
                      }))}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input: string, option?: any) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={isSparePartDisabled}
                    />
                  </Form.Item>
                )}

                {singleField.id === "salesOrder" && (
                  <Form.Item
                    name="salesOrder"
                    rules={[
                      {
                        required: true,
                        message: `Please enter ${singleField.title}`,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={`Enter ${singleField.title}`}
                      //  disabled={isFieldDisabled(singleField.id)}
                    />
                  </Form.Item>
                )}

                {singleField.id === "productDescription" && (
                  <Form.Item name="productDescription">
                    <Input.TextArea
                      size="large"
                      placeholder={`${singleField.title}`}
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      disabled={true}
                    />
                  </Form.Item>
                )}

                {singleField.id === "useDate" && (
                  <Form.Item name="useDate">
                    <DatePicker
                      placeholder={`Select ${singleField.title}`}
                      style={{ width: "100%" }}
                      size="large"
                      format="MM/DD/YYYY"
                      onChange={handleUseDateChange}
                      disabledDate={(currentDate) => {
                        return (
                          currentDate && currentDate.isAfter(dayjs(), "day")
                        );
                      }}
                      // showTime={false}
                    />
                  </Form.Item>
                )}

                {singleField.id === "status" && (
                  <Form.Item name="status">
                    <Select
                      placeholder={`Select ${singleField.title}`}
                      size="large"
                      onChange={handleStatusChange}
                      options={[
                        {
                          value: "IN_USE",
                          label: "In Use",
                          disabled: form.getFieldValue("status") === "UNUSED",
                        },
                        { value: "UNUSED", label: "Unused" },
                      ]}
                    ></Select>
                  </Form.Item>
                )}
              </Col>
            );
          })}
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "20px",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="save-button"
            loading={loading}
          >
            {selectedRegisterSP ? "Update" : "Save"}
          </Button>
          <Button
            size="large"
            htmlType="button"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
            className="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
