import "./index.scss";
import ItemConfiguration from "components/settings/ItemConfiguration";

export const ItemConfigurations = () => {
  return (
    <div>
      {" "}
      <ItemConfiguration />
    </div>
  );
};
