import { Sidebar } from "components/Global";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { FC } from "react";
import { SettingsLayoutProps } from "./types";
import { useSelector } from "react-redux";
// Settings page layout component
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
  // Initialize variables and props
  const { settingsMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;
  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  //Filter settingsMenuItems based on the roleName
  const filteredSettingsMenuItems = settingsMenuItems.filter((item) => {
    if (roleName === "Service Center") {
      // No access to any settings
      return false;
    } else if (roleName === "3nStar Employee 2") {
      // No access to settings
      return false;
    } else if (roleName === "3nStar Employee 1") {
      // Has access only to "Items"
      return item.key === "item";
    } else if (roleName === "Admin 3nStar") {
      // Has full access, no filtering needed
      return true;
    }

    return false; // Default case if role is unrecognized
  });
  
  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <Sidebar
          items={filteredSettingsMenuItems}
          isGetSupportButton={true}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
