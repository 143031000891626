import IntegrationSidebar from "components/Global/IntegrationSidebar";
import { FORMDATA } from "constants/Data";
import { FC } from "react";
import styles from "./index.module.scss";
import { IntegrationLayoutProps } from "./types";

const IntegrationLayout: FC<IntegrationLayoutProps> = (props) => {
  const { newIntegrationMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;

  // JSX
  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <IntegrationSidebar
          items={newIntegrationMenuItems}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body"]}>{children}</div>
      </div>
    </div>
  );
};

export default IntegrationLayout;
