import { Form, Select } from "antd";
import { InputWithLabelAndSvg } from "components/Global";
import { FORMDATA } from "constants/Data";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import styles from "./index.module.scss";
import { SideDrawerBodyProps } from "./types";
import { editUserAction, inviteUserAction } from "redux/action/userAction";
import { SingleUserInput } from "components/Global/InputWithLabel/types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { phoneNumberFormatter } from "helper/phoneNumberFormatter";
import { invalidText } from "utils/utils";
import { getApi } from "redux/apis";

const AddUserBody: FC<SideDrawerBodyProps> = (props) => {
  // Inits
  const { addUserFields } = FORMDATA;
  const { closeDrawerByAnimation, editSelectedUser, fetchUsers } = props;
  const [form] = Form.useForm();

  // State Management
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isSelected, setIsSelected] = useState<boolean>();
  const [phoneNumber, setPhoneNumber] = useState(
    editSelectedUser ? editSelectedUser.simplePhone : ""
  );
  const [phoneError, setPhoneError] = useState("");

  const editData = {
    ...editSelectedUser,
    fullName: editSelectedUser?.name || "",
    phone: editSelectedUser?.simplePhone,
  };

  useEffect(() => {
    setValidateDisplayNum(editSelectedUser ? editSelectedUser.simplePhone : "");
  }, [editSelectedUser]);

  const [showError, setShowError] = useState<boolean>();
  const [displayNum, setDisplayNum] = useState(
    editSelectedUser ? editSelectedUser.simplePhone : ""
  );
  const [validateDisplayNum, setValidateDisplayNum] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const roleData = useSelector((state: any) => state?.roleTable?.data);
  const { isAddUserLoading } = useSelector((state: any) => state?.users);
  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const loggedInRoleName = profileData?.companyRoles[0]?.role?.roleName;
  const loggedInroleId = profileData?.companyRoles[0]?.roleId;

  const [saveAndAddLoader, setSaveAndAddLoader] = useState(false);

  const [isEditLoading, setIsEditLoading] = useState(false);
  const [serviceCenterOptions, setServiceCenterOptions] = useState([]);
  const [selectedServiceCenters, setSelectedServiceCenters] = useState<
    string[]
  >([]);
  const [disableServiceCenters, setDisableServiceCenters] =
    useState<boolean>(false);
  const [loadingServiceCenters, setLoadingServiceCenters] = useState(false);
  const [isServiceCenterRole, setIsServiceCenterRole] = useState(false);

  useEffect(() => {
    const data: any = roleData
      .filter((role: any) => role.roleName !== "Admin 3nStar")
      .map((role: any) => ({
        value: role.id,
        label: role.roleName,
      }));

    setRoleOptions(data);
  }, []);

  const phoneChangeHandler = (value: string, country: any) => {
    !value && setShowError(true);
    const formattedValue = phoneNumberFormatter(value, country?.dialCode);
    setDisplayNum(formattedValue?.formattedPhoneNumber);
    setValidateDisplayNum(formattedValue?.cleanedPhoneNumber);
    if (
      formattedValue?.cleanedPhoneNumber.replace(/\D/g, "").length !==
      country?.dialCode.length + 10
    ) {
      // setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const checkEmailValidation = async () => {
    try {
      await form.validateFields(["email"]);
      return true;
    } catch (error) {
      return false;
    }
  };

  // If form get success
  const onFinish = (values: any, buttonType = "Save") => {
    const fullName = (values.fullName as string).trim();
    const nameArray = fullName.split(" ");
    const firstName = nameArray.slice(0, 1).join(" ") || "";
    const lastName = nameArray.slice(1, nameArray.length).join(" ") || "";

    if (
      isServiceCenterRole &&
      (!selectedServiceCenters || selectedServiceCenters.length === 0)
    ) {
      form.setFields([
        {
          name: "serviceCenters",
          errors: ["Please select at least one service center"],
        },
      ]);
      return; // Stop execution if validation fails
    }

    if (editSelectedUser) {
      if (validateDisplayNum) {
        setIsEditLoading(true);
        setSaveAndAddLoader(true);
        let data: any = {
          userId: editSelectedUser?.userId,
          phone: displayNum,
          firstName,
          lastName,
          serviceCenters: selectedServiceCenters || [],
        };

        if (selectedRole) {
          data = {
            ...data,
            roleId: selectedRole,
            isChangeStatus: false,
          };
        }

        dispatch(editUserAction(data)).then(() => {
          closeDrawerByAnimation();
          fetchUsers();
        });
      } else {
        setShowError(true);
      }
    } else {
      if (selectedRole && validateDisplayNum) {
        setIsSelected(true);
        buttonType === "SaveAndAdd" && setSaveAndAddLoader(true);
        const finalData = {
          email: values.email.toLowerCase(),
          role: selectedRole,
          phone: displayNum,
          firstName,
          lastName,
          serviceCenters: selectedServiceCenters || [],
        };
        dispatch(inviteUserAction(finalData)).then((res) => {
          if (res?.payload?.data) {
            setSelectedServiceCenters([]);
            form.resetFields();
            setPhoneNumber(
              editSelectedUser ? editSelectedUser.simplePhone : ""
            );
            setDisplayNum("");
            setSelectedRole("");
            fetchUsers();
            buttonType === "Save" && closeDrawerByAnimation();
          }
          buttonType === "SaveAndAdd" && setSaveAndAddLoader(false);
        });
      } else {
        selectedRole ? setIsSelected(true) : setIsSelected(false);
      }
    }
  };

  // handle Save and Add
  const saveAndAdd = async () => {
    if (validateDisplayNum === "") {
      setShowError(true);
    }
    const formValues = form.getFieldsValue();
    form.validateFields();
    const isEmailValidate = await checkEmailValidation();

    !selectedRole && setIsSelected(false);
    if (
      isEmailValidate &&
      selectedRole &&
      validateDisplayNum != "" &&
      formValues?.fullName
    )
      onFinish(formValues, "SaveAndAdd");
  };

  // If form fails
  const onFinishFailed = () => {
    if (invalidText(validateDisplayNum)) {
      setPhoneError("Please enter a valid phone number");
      setShowError(true);
    } else {
      setPhoneError("");
      setShowError(false);
    }

    if (selectedRole) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }

    const formValues = form.getFieldsValue();

    if (
      isServiceCenterRole &&
      (!formValues.serviceCenters || formValues.serviceCenters.length === 0)
    ) {
      form.setFields([
        {
          name: "serviceCenters",
          errors: ["Please select at least one service center"],
        },
      ]);
    }
  };

  const fetchServiceCenters = async (
    loggedInRoleId: string,
    roleId?: string
  ) => {
    setLoadingServiceCenters(true);
    console.log("loggedInRoleId: ", loggedInRoleId);
    try {
      const response = await getApi(
        `/serviceCenter/dropdown?role=${loggedInRoleId}`
      );
      console.log("response: ", response);
      const centers = response.data.data.map((center: any) => ({
        value: center.id,
        label: center.name,
      }));
      setServiceCenterOptions(centers);

      const employeeRoleId = roleData.find(
        (role: any) => role.roleName === "Service Center"
      )?.id;
      console.log("employeeRoleId: ", employeeRoleId);

      // If roleId is provided, use it. Otherwise, use editSelectedUser's roleId
      const currentRoleId = roleId || editSelectedUser?.roleId;
      console.log("currentRoleId: ", currentRoleId);

      // Determine if the current role is Employee
      const isServiceCenterRole = currentRoleId === employeeRoleId;
      console.log("isServiceCenterRole: ", isServiceCenterRole);
      setIsServiceCenterRole(isServiceCenterRole);
      let selectedIds: string[] = [];

      if (editSelectedUser) {
        // If editing, pre-select service centers from editSelectedUser
        selectedIds = editSelectedUser.serviceCenters.map(
          (center: any) => center.serviceCenterId
        );
      }

      setDisableServiceCenters(false);

      setSelectedServiceCenters(selectedIds);
      form.setFieldsValue({ serviceCenters: selectedIds });
    } catch (error) {
      console.error("Error fetching service centers:", error);
      setServiceCenterOptions([]);
    } finally {
      setLoadingServiceCenters(false);
    }
  };

  const handleRoleChange = (roleId: string) => {
    setSelectedRole(roleId);
    setIsSelected(true);
    setSelectedServiceCenters([]);
    setIsServiceCenterRole(false);
    form.setFields([
      {
        name: "serviceCenters", // Ensure this matches your form's field name
        errors: [],
      },
    ]);
    fetchServiceCenters(loggedInroleId, roleId);
  };

  const handleSelectChange = (selectedValues: string[]) => {
    setSelectedServiceCenters(selectedValues);
    form.setFieldsValue({ serviceCenters: selectedValues });

    if (selectedValues.length > 0) {
      form.setFields([
        {
          name: "serviceCenters",
          errors: [],
        },
      ]);
    }
  };

  useEffect(() => {
    if (editSelectedUser) {
      setSelectedRole(editSelectedUser.roleId);
      fetchServiceCenters(loggedInroleId, editSelectedUser.roleId);
    }
  }, [editSelectedUser]);

  // JSX
  return (
    <div className={styles["side-drawer-body"]}>
      <Form
        form={form}
        name="basic"
        initialValues={editData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        labelAlign="left"
        className={styles["side-drawer-form"]}
      >
        <div className={styles["side-drawer-form__inputs"]}>
          {addUserFields.map(
            (
              singleField: SingleUserInput,
              index: React.Key | null | undefined
            ) => {
              return (
                <React.Fragment key={index}>
                  {singleField.id === "phone" && (
                    <>
                      {" "}
                      <Form.Item
                        className="side-drawer-form__role"
                        name={singleField.name}
                      >
                        <label
                          className={styles["side-drawer-form__role--label"]}
                        >
                          {singleField.title}{" "}
                          {singleField?.required && (
                            <span
                              className="required-color"
                              style={{ marginLeft: "5px" }}
                            >
                              *
                            </span>
                          )}
                        </label>
                        <PhoneInput
                          country={"us"}
                          value={displayNum}
                          onChange={phoneChangeHandler}
                          inputClass={`${styles["input-icon__form--input"]} ${styles.customPhoneInput}`}
                        />
                        {validateDisplayNum === "" && showError && (
                          <p className="ant-form-item-explain-error">
                            Please enter phone number
                          </p>
                        )}
                      </Form.Item>
                    </>
                  )}
                  {singleField.id !== "roleName" &&
                    singleField.id !== "phone" &&
                    singleField.id !== "serviceCenters" && (
                      <InputWithLabelAndSvg
                        singleUserInput={singleField}
                        disabled={
                          singleField?.name == "email" &&
                          editSelectedUser &&
                          true
                        }
                        setPhoneNumber={setPhoneNumber}
                        phoneNumber={phoneNumber}
                        phoneError={phoneError}
                        setPhoneError={setPhoneError}
                      />
                    )}
                  {singleField.id === "roleName" && (
                    <Form.Item
                      className="side-drawer-form__role"
                      name={singleField.name}
                    >
                      <label
                        className={styles["side-drawer-form__role--label"]}
                      >
                        {singleField.title}{" "}
                        {singleField?.required && (
                          <span
                            className="required-color"
                            style={{ marginLeft: "5px" }}
                          >
                            *
                          </span>
                        )}
                      </label>
                      <Select
                        placeholder="Select Role"
                        className={styles["side-drawer-form__role--select"]}
                        size="large"
                        onSelect={(role) => {
                          handleRoleChange(role);
                        }}
                        defaultValue={
                          editSelectedUser && editSelectedUser?.roleId
                        }
                      >
                        {roleOptions?.map((role: any, key) => {
                          // If the roleName is "Service Center Employee", show only that option
                          if (loggedInRoleName === "Service Center") {
                            if (role?.label === "Service Center") {
                              return (
                                <Select.Option value={role?.value} key={key}>
                                  {role?.label}
                                </Select.Option>
                              );
                            }
                          } else {
                            // If roleName is not "Service Center Employee", show all options
                            return (
                              <Select.Option value={role?.value} key={key}>
                                {role?.label}
                              </Select.Option>
                            );
                          }

                          return null; // In case no condition is met, return null
                        })}
                      </Select>
                      {isSelected == false && (
                        <p className="ant-form-item-explain-error">
                          Please select role
                        </p>
                      )}
                    </Form.Item>
                  )}
                  {singleField.id === "serviceCenters" && (
                    <Form.Item
                      className="side-drawer-form__role"
                      name={singleField.name}
                      rules={[
                        {
                          required: isServiceCenterRole,
                          message: !selectedRole
                            ? "Please select a role first"
                            : serviceCenterOptions.length === 0
                            ? "No Service Center found, please create at least one service center"
                            : "Please select at least one service center",
                        },
                      ]}
                    >
                      <label
                        className={styles["side-drawer-form__role--label"]}
                      >
                        {singleField.title}
                        {isServiceCenterRole && (
                          <span
                            className="required-color"
                            style={{ marginLeft: "5px" }}
                          >
                            *
                          </span>
                        )}
                      </label>

                      <Select
                        placeholder="Select Service Centers"
                        mode="multiple"
                        className={styles["side-drawer-form__role--select"]}
                        options={serviceCenterOptions.map((option: any) => ({
                          ...option,
                          disabled: disableServiceCenters, // Disable all options if condition is met
                        }))}
                        //  showSearch
                        showSearch={
                          selectedServiceCenters.length <
                          serviceCenterOptions.length
                        }
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          option?.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        loading={loadingServiceCenters}
                        disabled={
                          !selectedRole
                          //  || serviceCenterOptions.length === 0
                        }
                        value={selectedServiceCenters}
                        onChange={handleSelectChange}
                      />

                      {/* {isSelected == false && (
                        <p className="ant-form-item-explain-error">
                          Please select service center
                        </p>
                      )} */}
                    </Form.Item>
                  )}
                </React.Fragment>
              );
            }
          )}
        </div>
        <div className={styles["side-drawer-form__buttons"]}>
          {!editSelectedUser && !isEditLoading && (
            <Form.Item>
              <button
                type="button"
                className={`${
                  styles["side-drawer-form__buttons--save-and-add"]
                } ${isAddUserLoading && "pointer-event-none"}`}
                onClick={saveAndAdd}
              >
                {saveAndAddLoader ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                    height={40}
                  />
                ) : (
                  "Save And New"
                )}
              </button>
            </Form.Item>
          )}
          <Form.Item>
            <button
              type="submit"
              className={`${
                styles[
                  editSelectedUser
                    ? "side-drawer-form__buttons--edit"
                    : "side-drawer-form__buttons--save"
                ]
              } ${isAddUserLoading && "pointer-event-none"}`}
            >
              {isAddUserLoading && (!saveAndAddLoader || isEditLoading) ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                  height={40}
                />
              ) : (
                "Save"
              )}
            </button>
          </Form.Item>
          <Form.Item>
            <button
              type="reset"
              className={`${styles["side-drawer-form__buttons--cancel"]}`}
              onClick={closeDrawerByAnimation}
              disabled={isAddUserLoading}
            >
              Cancel
            </button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddUserBody;
