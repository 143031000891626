import { Tabs } from "antd";
import { useInventoryCounts } from "hooks";
import React from "react";
import styles from "./index.module.scss";
import "./index.scss";
import InventoryComponent from "./Inventory";
import RegisterSparePartComponent from "./RegisterSpareParts";
import ShipmentComponent from "./Shipment";

const InventoryControl = () => {
  const [activeKey, setActiveKey] = React.useState("shipment");
  const { counts, loading, refetchCounts } = useInventoryCounts();

  const changeTab = (key: string) => {
    setActiveKey(key);
  };

  // Format count to handle numbers over 99
  const formatCount = (count: number): string => {
    return count > 99 ? "99+" : count.toString();
  };
  // Reusable badge style function with explicit React.CSSProperties
  const getBadgeStyle = (tabKey: string): React.CSSProperties => ({
    position: "absolute",
    top: "5px",
    right: "-20px",
    backgroundColor: activeKey === tabKey ? "gray" : "#286fd1",
    color: "white",
    borderRadius: "50%",
    fontSize: "12px",
    width: "22px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    transform: "translate(50%, -50%)",
    lineHeight: "normal",
  });

  const tabItems = [
    {
      key: "shipment",
      label: "Shipment",
      children: (
        <ShipmentComponent
          changeTab={changeTab}
          activeKey={activeKey}
          refetchCounts={refetchCounts}
        />
      ),
    },
    {
      key: "inventory",
      label: (
        <div style={{ position: "relative" }}>
          Inventory
          {!loading && (
            <span style={getBadgeStyle("inventory")}>
              {formatCount(counts.inventory)}
            </span>
          )}
        </div>
      ),
      children: (
        <InventoryComponent
          changeTab={changeTab}
          activeKey={activeKey}
          refetchCounts={refetchCounts}
        />
      ),
    },
    {
      key: "registerSpareParts",
      label: (
        <div style={{ position: "relative" }}>
          Register Spare Parts
          {!loading && (
            <span style={getBadgeStyle("registerSpareParts")}>
              {formatCount(counts.registerSpareParts)}
            </span>
          )}
        </div>
      ),
      children: (
        <RegisterSparePartComponent
          changeTab={changeTab}
          activeKey={activeKey}
          refetchCounts={refetchCounts}
        />
      ),
    },
  ];

  return (
    <div className={styles["statutory-component"]}>
      <div className="statutory-component-tabs">
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          destroyInactiveTabPane={true}
          items={tabItems}
          onChange={(key) => changeTab(key)}
          style={{
            fontSize: "18px",
            marginBottom: "40px",
          }}
        />
      </div>
    </div>
  );
};

export default InventoryControl;
