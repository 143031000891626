export const SORTABLE_FIELDS = [
  "serviceCenter",
  "createdAt",
  "salesOrderNumber",
  "deliveryDate",
  "itemNumber",
  "status",
  "quantity",
] as const;

export const SHIPMENT_STATUS_CONFIG = {
  READY_TO_SHIP: { color: "blue" },
  SHIPPED: { color: "success" },
  IN_TRANSIT: { color: "warning" },
  RECEIVED: { color: "success" },
  DAMAGED: { color: "error" },
} as const;

export const SEARCHABLE_FIELDS = [
  { label: "Service Center", value: "serviceCenter" },
  { label: "Sales Order", value: "salesOrderNumber" },
  { label: "Spare Part", value: "itemNumber" },
];

export const REGISTER_STATUS_CONFIG = {
  IN_USE: { color: "success" },
  UNUSED: { color: "blue" },
} as const;

export const SALES_ORDER_STATUS = {
  Pending: { color: "blue" },
  Shipped: { color: "success" },
  UnderProcess: { color: "warning" },
  Done: { color: "success" },
} as const;
