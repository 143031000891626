import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteApiWithData, getApi, postApi } from "redux/apis";

// Get All Roles
export const getWarrantyRulesActionTable = createAsyncThunk(
  "warrantyRulesTable/getWarrantyRulesTable",
  async (
    {
      page,
      limit,
      searchParams,
    }: {
      page: number;
      limit: number;
      searchParams: { fields: string[]; value: string };
    },
    { rejectWithValue }: any
  ) => {
    try {
      const requestBody = {
        pageNumber: page,
        pageSize: limit,
        searchParams,
      };

      // Call POST API with the constructed body
      const response = await postApi(`/warranty-rules`, requestBody);
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const paginateWarrantyRulesTable = createAsyncThunk(
  "warrantyRulesTable/paginateTable",
  async (url: any, { rejectWithValue, getState }: any) => {
    const alreadyAvailableRoles = getState().roles.data;
    try {
      const response = await getApi(`/warranty-rules${url ? `?${url}` : ""}`);
      return [...alreadyAvailableRoles, ...response.data.data.roles];
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Add New Role
export const addWarrantyRulesActionTable = createAsyncThunk(
  "warrantyRulesTable/addWarrantyRulesTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/warranty-rules/create`, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Edit Role
export const editWarrantyRulesActionTable = createAsyncThunk(
  "warrantyRulesTable/editWarrantyRulesTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/warranty-rules/update-rule`, data);
      console.log("response", response);
      return { response: response.data, editData: data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editDefaultWarrantyMonthRuleActionTable = createAsyncThunk(
  "warrantyRulesTable/editDefaultWarrantyMonthRuleTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/warranty-rules/upsert-month`, data);
      return { response: response.data.data, editData: data };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Delete Role
export const deleteWarrantyRulesActionTable = createAsyncThunk(
  "warrantyRulesTable/deleteWarrantyRulesTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteApiWithData(`/warranty-rules`, data);
      return { response: response.data, id: data?.ruleId };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
