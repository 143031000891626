import { Button, Form } from "antd";
import { InputWithLabelAndSvg } from "components/Global";
import { FC } from "react";
import styles from "./index.module.scss";
import { LoginLayoutBodyProps } from "./types";
import { useNavigate } from "react-router-dom";
// import ReCaptchaComponent from "components/Global/Recaptcha";

// Login layout body designing
const LoginLayoutBody: FC<LoginLayoutBodyProps> = (props) => {
  // Inits
  const {
    title,
    description,
    formData: loginFields,
    buttonTitle,
    onSubmit,
    isLoading,
    redirectUrl,
    redirectText,
    setIsPasswordValidate,
    // handleRecaptcha,
    // recaptchaRef,
  } = props;

  const navigate = useNavigate();
  // JSX
  return (
    <div className={styles["login-body"]}>
      <Form
        className={styles["login-body__wrapper"]}
        name="basic"
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        initialValues={{
          rememberMe: false, // Set default value for rememberMe
        }}
      >
        <div className={styles["login-body__top"]}>
          <h4 className={styles["login-body__top--title"]}>{title}</h4>
          {description && (
            <div className={styles["login-body__top--description"]}>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </div>
        <div className={styles["login-body__center"]}>
          {loginFields.map((singleUserInput, key) => {
            return (
              <InputWithLabelAndSvg
                key={key}
                singleUserInput={singleUserInput}
                setIsPasswordValidate={setIsPasswordValidate}
              />
            );
          })}
        </div>

        {/* {title === "Log in" && (
          <ReCaptchaComponent
            handleCaptchaChange={handleRecaptcha}
            recaptchaRef={recaptchaRef}
          />
        )} */}

        <div className={styles["login-body__end"]}>
          <Button
            type="primary"
            className={`${styles["login-body__end--button"]} ${
              isLoading && "pointer-event-none"
            }`}
            size="large"
            htmlType="submit"
            // disabled={isLoading}
          >
            {isLoading ? (
              <img
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                height={40}
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
              />
            ) : (
              <>{buttonTitle}</>
            )}
          </Button>
        </div>
        <div className={styles["login-body__remberme-forgot"]}>
          <p
            className={styles["login-body__remberme-forgot--forgot-password"]}
            onClick={() => navigate(`${redirectUrl}`)}
          >
            {redirectText}
          </p>
        </div>
      </Form>
    </div>
  );
};

export default LoginLayoutBody;
