import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { AppDispatch } from "redux/store";
import NewCompany from "../Home/NoCompany/newCompany";
import { Tag, Table, Space, Popover } from "antd";
import { EditOutlined } from "@ant-design/icons"; // For edit icon
import SearchAndFilter from "pages/Home/SearchAndFilter";
import ExtendWarrantyModal from "./ExtendWarrantyModal";
import ExtendWarrantyDrawer from "./ExtendWarrantyDrawer";
import { getWarrantyDetailsActionTable } from "redux/action/productWarrantyDetailsAction";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { debounce } from "lodash";

dayjs.extend(utc);

const Sales = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { productWarrantyDetails: ProductWarrantyDetails, totalRecords } =
    useSelector((state: any) => state.ProductWarrantyDetails.data || {});

  const { isLoading } = useSelector(
    (state: any) => state.ProductWarrantyDetails
  );

  const [extendWarrantyLoader, setExtendWarrantyLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>([
    "itemName",
    "itemDesc",
    "serialNumber",
    "customerName",
    "invoiceReferenceNumber",
  ]);
  const [formData, setFormData] = useState<any>(null); /*  */
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(200); // default height

  const [initialSalesDataFlag, setInitialSalesDataFlag] = useState(false); // default height

  const allActiveConnection = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  const navigate = useNavigate();

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  useEffect(() => {
    const updateTableHeight = () => {
      const headerOffset = 400; // Adjust according to your layout needs
      setTableHeight(window.innerHeight - headerOffset);
    };

    window.addEventListener("resize", updateTableHeight);
    updateTableHeight();

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, []);

  useEffect(() => {
    if (roleName === "Service Center") {
      navigate("/sales");
    }
  }, [roleName, navigate]);

  // Handlers
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields([
      "itemName",
      "itemDesc",
      "serialNumber",
      "invoiceReferenceNumber",
      "customerName",
    ]);
    setCurrentPage(1);
  };

  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
  };

  const handleSave = (data: any) => {
    console.log("Saving data:", data);
    dispatch(
      getWarrantyDetailsActionTable({
        pageNumber: currentPage,
        pageSize,
        searchParams: { fields: searchFields, value: searchValue?.trim() },
      })
    );
  };

  const handleEditClick = (record: any) => {
    setFormData(record);
    setIsDrawerVisible(true);
  };

  // Debounce function to limit API calls
  const debouncedFetchData = debounce(async () => {
    try {
      await dispatch(
        getWarrantyDetailsActionTable({
          pageNumber: currentPage,
          pageSize,
          searchParams: { fields: searchFields, value: searchValue?.trim() },
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, 500); // Delay of 500ms before the API call is triggered

  useEffect(() => {
    const getActiveConnection = async () => {
      await dispatch(getActiveConnectionAction());
    };

    getActiveConnection();

    if (searchFields.length == 0) {
      setSearchValue("");
    }
    if (searchValue) {
      setInitialSalesDataFlag(true);
      setDataSource([]); // Clear previous data
      debouncedFetchData(); // Call the debounced function
    } else {
      setInitialSalesDataFlag(false);
    }
    // Clean up the debounce on unmount or before the next effect
    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchValue, dispatch, currentPage, pageSize, searchFields]);

  useEffect(() => {
    if (ProductWarrantyDetails) {
      setDataSource(ProductWarrantyDetails);
    }
  }, [ProductWarrantyDetails]);

  // Columns configuration for Ant Design Table
  const columns: Array<{
    title: string;
    dataIndex?: string; // Make `dataIndex` optional
    key: string;
    width?: number;
    render?: any;
  }> = [
    {
      title: "3nStar Invoice Number",
      dataIndex: "invoiceReferenceNumber",
      key: "invoiceReferenceNumber",
      width: 140,
    },
    {
      title: "3nStar Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 140,
      render: (text: string) => {
        const date = dayjs.utc(text);
        return date.format("MM/DD/YYYY");
      },
    },
    {
      title: "Item Serial Number",
      dataIndex: "serialNumber",
      key: "itemSerialNumber",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Item Description",
      dataIndex: "itemDesc",
      key: "itemDescription",
    },
    {
      title: "Warranty Expiration Date",
      dataIndex: "expiryDate",
      key: "warrantyExpirationDate",
      width: 170,
      render: (text: string, record: any) => {
        const date = dayjs.utc(text);
        const formattedDate = date.format("MM/DD/YYYY");

        // Tooltip content to show ruleName when hovered
        const content = <span>{record.ruleName}</span>;

        return (
          <div
            style={{
              maxWidth: 150,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {/* Date with popover for ruleName */}
            <span>{formattedDate}</span>
            <br />

            {/* Check if warranty is extended or show the ruleName */}
            {record.isExtendWarranty ? (
              <Popover
                content="Extended Warranty"
                trigger="hover"
                placement="top"
              >
                <Tag
                  color="green"
                  style={{ fontWeight: "bold" }}
                >
                  Extended Warranty
                </Tag>
              </Popover>
            ) : record.ruleName == "No_Rules_Matched" ? (
              <Popover
                content={content}
                trigger="hover"
                placement="top"
              >
                <Tag
                  color="red"
                  style={{ cursor: "pointer" }}
                >
                  No Rules Matched
                </Tag>
              </Popover>
            ) : (
              <Popover
                content={content}
                trigger="hover"
                placement="top"
              >
                <Tag
                  color="orange"
                  style={{ cursor: "pointer" }}
                >
                  {record.ruleName}
                </Tag>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isExpired",
      key: "status",
      width: 120,
      render: (status: boolean, record: any) => {
        const isExpired = dayjs
          .utc(record.expiryDate)
          .isBefore(dayjs.utc(), "day");
        return (
          <Tag color={isExpired ? "red" : "green"}>
            {isExpired ? "Expired" : "In Warranty"}
          </Tag>
        );
      },
    },
  ];

  if (
    profileData?.companyRoles[0]?.role?.roleName === "Admin 3nStar" ||
    profileData?.companyRoles[0]?.role?.roleName === "3nStar Employee 1"
  ) {
    columns.push({
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text: any, record: any) => (
        <Space size="middle">
          <EditOutlined
            style={{ color: "black", cursor: "pointer", fontSize: "18px" }}
            onClick={() => handleEditClick(record)}
          />
        </Space>
      ),
    });
  }

  const options: { label: string; value: string }[] = columns
    .filter(
      (column) =>
        ![
          "Actions",
          "Status",
          "Warranty Expiration Date",
          "3nStar Invoice Date",
        ].includes(column.title)
    )
    .map((column: any) => ({
      label: column.title,
      value: column.dataIndex,
    }));

  return (
    <>
      {allActiveConnection &&
      allActiveConnection.length > 0 &&
      allActiveConnection[0].isActiveConnection ? (
        <div>
          <p style={{ margin: "10px 0px", fontSize: "16px" }}>
            Sales data for:&nbsp;
            <span style={{ fontWeight: "bolder" }}>
              {allActiveConnection[0].companyName}
            </span>
          </p>

          <div style={{ backgroundColor: "white" }}>
            <SearchAndFilter
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              PageSize={pageSize}
              searchFieldsSelectHandler={searchFieldsSelectHandler}
              resetButtonHandler={resetButtonHandler}
              searchFields={searchFields}
              handleExtendWarranty={setIsModalVisible}
              menuName="Sales"
              options={options}
            />

            <ExtendWarrantyModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              setIsLoading={setExtendWarrantyLoader}
              ModalDetails={{ Name: "Import Extended Warranty" }}
              currentPage={currentPage}
              pageSize={pageSize}
              searchParams={{ fields: searchFields, value: searchValue }}
            />

            <ExtendWarrantyDrawer
              visible={isDrawerVisible}
              onClose={() => setIsDrawerVisible(false)}
              formData={formData}
              onSave={handleSave}
            />
            <Table
              columns={columns}
              dataSource={initialSalesDataFlag ? dataSource : []}
              loading={isLoading || extendWarrantyLoader}
              pagination={
                initialSalesDataFlag && dataSource.length > 0
                  ? {
                      current: currentPage,
                      pageSize: pageSize,
                      total: totalRecords,
                      onChange: paginationChangeHandler,
                    }
                  : false
              }
              scroll={{
                y: tableHeight,
                x: "100%",
              }}
              rowKey="id"
              locale={{
                emptyText: "Search your data based on the search field.",
              }}
            />
          </div>
        </div>
      ) : (
        <NewCompany />
      )}
    </>
  );
};

export default Sales;
