import { Header, Sidebar } from "components/Global";
import { Outlet, useNavigate } from "react-router-dom";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const GlobalLayout = () => {
  // Initialize variables
  const navigate = useNavigate();

  const { pageMenuItems } = FORMDATA;
  const { isLoading } = useSelector((state: any) => state.userProfile);
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);
  const profileData = useSelector((state: any) => state?.userProfile?.data);

  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  // Get the selected company and its permissions

  // Create an array of permissions with 'view' access

  // Filter menu items based on permissions

  // Function to set the initial selected sidebar
  // Function to handle sidebar changes
  const sideBarChangeHandler = (selectedValue: any) => {
    // Update the selected sidebar based on the value
    setSelectedSidebar(selectedValue.key);
    // Navigate to the selected sidebar's route
    navigate(selectedValue.key === "sales" ? "/sales" : selectedValue.key);
  };

  // const profileData = useSelector((state: any) => state?.userProfile?.data);
  // const roleName = profileData?.companyRoles[0]?.role?.roleName;

  const initialFunction = () => {
    const splitArray = pathname.split("/");
    let key = splitArray[splitArray.length - 1];

    // Check if the pathname starts with '/settings/integrations' and set the key accordingly
    if (pathname.startsWith("/settings/integrations")) {
      key = "integrations";
    }

    // if (roleName === "Service Center Employee") {
    //   navigate("/sales");
    // }
    // Set the selected sidebar
    setSelectedSidebar(key);
  };

  const filteredPageMenuItems = pageMenuItems.filter((item) => {
    if (roleName === "Service Center") {
      // Hide "Item Inventory", "sales-order", "shipping", and "packing-list" for Service Center users
      return !["", "sales-order", "shipping", "packing-list"].includes(
        item.key
      );
    } else if (roleName === "3nStar Employee 2") {
      return ![
        "inventory-control",
        "sales-order",
        "shipping",
        "packing-list",
      ].includes(item.key);
    }
    return true;
  });

  // Call the initial function when the component is mounted or when the window location changes
  useEffect(() => {
    initialFunction();
  }, [window.location.href]);
  // JSX
  return !isLoading ? (
    <div className={styles["global-layout"]}>
      <div className={styles["global-layout__wrapper"]}>
        <div className={styles["global-layout__header"]}>
          <Header />
        </div>
        <div className={styles["global-layout__body"]}>
          <div className={styles["global-layout__body--sidebar"]}>
            <Sidebar
              items={filteredPageMenuItems}
              isGetSupportButton={false}
              handleSidebar={sideBarChangeHandler}
              selectedSidebar={selectedSidebar}
            />
          </div>
          <div className={styles["global-layout__body--body"]}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default GlobalLayout;
