import { Form } from "antd";
import { InputWithLabelAndSvg } from "components/Global";
import { FORMDATA } from "constants/Data";
import { phoneNumberFormatter } from "helper/phoneNumberFormatter";
import React, { FC, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { invalidText, toastText } from "utils/utils";
import styles from "./index.module.scss";
import { SideDrawerBodyProps } from "./types";
import { postApi, putApi } from "redux/apis";

const AddServiceCenterBody: FC<SideDrawerBodyProps> = (props) => {
  // Inits
  const { addServiceCenterFields } = FORMDATA;
  const {
    closeDrawerByAnimation,
    seletcedServiceCenter,
    fetchServiceCenter,
    isViewMode,
  } = props;
  const [form] = Form.useForm();

  // State Management
  const [phoneNumber, setPhoneNumber] = useState(
    seletcedServiceCenter ? seletcedServiceCenter.contactNumber : ""
  );
  const [phoneError, setPhoneError] = useState("");
  const [isAddUserLoading, setIsAddUserLoading] = useState(false);

  const editData = {
    ...seletcedServiceCenter,
    fullName: seletcedServiceCenter?.name || "",
    contactNumber: seletcedServiceCenter?.contactNumber,
  };

  useEffect(() => {
    setValidateDisplayNum(
      seletcedServiceCenter ? seletcedServiceCenter.contactNumber : ""
    );
  }, [seletcedServiceCenter]);

  const [showError, setShowError] = useState<boolean>();
  const [displayNum, setDisplayNum] = useState(
    seletcedServiceCenter ? seletcedServiceCenter.contactNumber : ""
  );
  const [validateDisplayNum, setValidateDisplayNum] = useState("");

  const phoneChangeHandler = (value: string, country: any) => {
    !value && setShowError(true);
    const formattedValue = phoneNumberFormatter(value, country?.dialCode);
    setDisplayNum(formattedValue?.formattedPhoneNumber);
    setValidateDisplayNum(formattedValue?.cleanedPhoneNumber);
    if (
      formattedValue?.cleanedPhoneNumber.replace(/\D/g, "").length !==
      country?.dialCode.length + 10
    ) {
      //   setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const onFinish = async (values: any) => {
    if (validateDisplayNum) {
      values.contactNumber = displayNum;
      if (seletcedServiceCenter) {
        const data: any = {
          id: seletcedServiceCenter?.id,
          ...values,
        };

        await updateServiceCenter(data);
      } else {
        await addServiceCenter(values);
      }
    } else {
      setShowError(true);
    }
  };

  const updateServiceCenter = async (serviceCenterData: any) => {
    try {
      setIsAddUserLoading(true);
      const response = await putApi("/serviceCenter/update", serviceCenterData);
      const message =
        response?.data?.message || "Service center updated successfully";
      closeDrawerByAnimation();
      toastText(message, "success");
      fetchServiceCenter();
    } catch (err: any) {
      let message = "Something went wrong in updating branch code";
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
      toastText(message, "error");
    } finally {
      setIsAddUserLoading(false);
    }
  };

  const addServiceCenter = async (finalData: any) => {
    try {
      setIsAddUserLoading(true);

      const response = await postApi("/serviceCenter/create", finalData);
      const message =
        response?.data?.message || "Service Center created successfully";

      setDisplayNum("");
      closeDrawerByAnimation();
      toastText(message, "success");
      fetchServiceCenter();
    } catch (err: any) {
      let message = "Something went wrong when creating to branch code";
      if (err.response?.data?.message) {
        message = err.response.data.message;
      }
      toastText(message, "error");
    } finally {
      setIsAddUserLoading(false);
    }
  };

  const onFinishFailed = () => {
    if (invalidText(validateDisplayNum)) {
      setPhoneError("Please enter a valid phone number");
      setShowError(true);
    } else {
      setPhoneError("");
      setShowError(false);
    }
  };

  // JSX
  return (
    <div className={styles["side-drawer-body"]}>
      <Form
        form={form}
        name="basic"
        initialValues={editData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        labelAlign="left"
        className={styles["side-drawer-form"]}
        onValuesChange={(changedValues, allValues) => {
          const trimmedValues = Object.fromEntries(
            Object.entries(allValues).map(([key, value]) => [
              key,
              typeof value === "string"
                ? value.trimStart().replace(/\s+/g, " ")
                : value,
            ])
          );
          form.setFieldsValue(trimmedValues);
        }}
        // onValuesChange={(changedValues, allValues) => {
        //   const trimmedValues = Object.fromEntries(
        //     Object.entries(allValues).map(([key, value]) => [
        //       key,
        //       typeof value === "string" ? value.trimStart() : value,
        //     ])
        //   );
        //   form.setFieldsValue(trimmedValues);
        // }}
      >
        <div className={styles["side-drawer-form__inputs"]}>
          {addServiceCenterFields.map(
            (singleField: any, index: React.Key | null | undefined) => {
              return (
                <React.Fragment key={index}>
                  {singleField.id === "contactNumber" && (
                    <Form.Item name={singleField.name}>
                      <label
                        className={styles["side-drawer-form__role--label"]}
                      >
                        {singleField.title}{" "}
                        {singleField?.required && (
                          <span
                            className="required-color"
                            style={{ marginLeft: "5px" }}
                          >
                            *
                          </span>
                        )}
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={displayNum}
                        disabled={isViewMode}
                        onChange={phoneChangeHandler}
                        inputClass={`${styles["input-icon__form--input"]} ${styles.customPhoneInput}`}
                        containerClass={`${
                          isViewMode ? styles.redBorderDisabled : ""
                        }`}
                      />

                      {/* <PhoneInput
                        country={"us"}
                        value={displayNum}
                        disabled={isViewMode}
                        onChange={phoneChangeHandler}
                        inputClass={`${styles["input-icon__form--input"]} ${styles.customPhoneInput}`}
                      /> */}
                      {validateDisplayNum === "" && showError && (
                        <p className="ant-form-item-explain-error">
                          Please enter contact number
                        </p>
                      )}
                    </Form.Item>
                  )}
                  {singleField.id !== "contactNumber" && (
                    <InputWithLabelAndSvg
                      singleUserInput={singleField}
                      disabled={isViewMode}
                      setPhoneNumber={setPhoneNumber}
                      phoneNumber={phoneNumber}
                      phoneError={phoneError}
                      setPhoneError={setPhoneError}
                    />
                  )}
                </React.Fragment>
              );
            }
          )}
        </div>
        {!isViewMode && (
          <div className={styles["side-drawer-form__buttons"]}>
            <Form.Item>
              <button
                type="submit"
                className={`${
                  styles[
                    seletcedServiceCenter
                      ? "side-drawer-form__buttons--edit"
                      : "side-drawer-form__buttons--save"
                  ]
                } ${isAddUserLoading && "pointer-event-none"}`}
              >
                {isAddUserLoading ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                    height={40}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </Form.Item>
            <Form.Item>
              <button
                type="reset"
                className={`${styles["side-drawer-form__buttons--cancel"]}`}
                onClick={closeDrawerByAnimation}
                disabled={isAddUserLoading}
              >
                Cancel
              </button>
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
};

export default AddServiceCenterBody;
