const swDev = () => {
  const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

  navigator.serviceWorker
    .register(swUrl)
    .then((res) => {
      console.log(res);
    })
    .catch((err: any) => {
      console.log("from swDev", err);
    });
};

export default swDev;
