import { Table } from "antd";
import { FC } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { DynamicTableProps } from "./types";
import { DeleteOutlined } from "@ant-design/icons";

const ConnectedTable: FC<DynamicTableProps> = (props) => {
  // Initialize Table Column and State
  const { Column } = Table;
  const { integrationDataSource, showModal, setSelectedRecord } = props;

  return (
    <div className="dynamic-table">
      <Table
        dataSource={integrationDataSource}
        scroll={{ y: "calc(85vh - 190px)", x: "63vh" }}
        className="table-global"
        pagination={false}
      >
        {/* Table Columns */}
        <Column
          title={"Connected Apps"}
          dataIndex="channelName"
          key="channelName"
          width={"40%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                <div
                  style={{
                    color: "#00000066",
                    fontSize: "small",
                    textAlign: "start",
                  }}
                >
                  {record.channelName}
                </div>
                <div className={styles[`bg-QuickbooksOnline`]}> </div>
              </div>
            );
          }}
        />

        <Column
          title="Company Name"
          dataIndex="companyName"
          key="companyName"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
        />

        <Column
          title="Actions"
          width={"20%"}
          key="actions"
          className="bg-white"
          render={(_, record: any) => (
            <DeleteOutlined
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setSelectedRecord(record?.companyId);
                showModal();
              }}
            />
          )}
        />
      </Table>
    </div>
  );
};

export default ConnectedTable;
