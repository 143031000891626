import {
  Table,
  Popover,
  Modal,
  Select,
  DatePicker,
  Row,
  Col,
  Button,
} from "antd";
// import { EditActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import { pageSizeOptionsPaging } from "utils/constant";
// import moment from "moment";
import styles from "./index.module.scss";
import { toastText } from "utils/utils";
import { useEffect, useState } from "react";
import { getApi, putApi } from "redux/apis";
import dayjs from "dayjs";
// import Ellipse from "components/Global/Ellipse";

const { Option } = Select;

const { Column } = Table;

interface Props {
  open: boolean;
  onCancel: () => void;
  fetchInventoryTable: any;
}

const InventoryTableNew = (props: Props) => {
  const { open, onCancel, fetchInventoryTable } = props;

  const [allInventoryData, setAllInventoryData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 10
  );

  const [serviceCenterLoading, setServiceCentersLoading] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [expandedDetails, setExpandedDetails] = useState<Record<string, any[]>>(
    {}
  );
  const [expandedLoading, setExpandedLoading] = useState<
    Record<string, boolean>
  >({});
  const [expandedPages, setExpandedPages] = useState<Record<string, number>>(
    {}
  );

  const [localUpdates, setLocalUpdates] = useState<Record<number, any>>({});

  const [dataSource, setDataSource] = useState<any>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [bulkArrivalDate, setBulkArrivalDate] = useState<dayjs.Dayjs | null>(
    null
  );

  const [serviceCenters, setServiceCenters] = useState<
    { value: string; label: string; id: string }[]
  >([]);

  const [selectedServiceCenter, setSelectedServiceCenter] = useState("All");

  const fetchInventory = async () => {
    try {
      setServiceCentersLoading(true);
      const query: any = {
        page: currentPage,
        pageSize: PageSize,
        searchValue: selectedServiceCenter,
      };

      if (query.searchValue === "All") {
        delete query.searchValue;
      }
      const response = await getApi(
        "/inventory/service-centers-shipped",
        query
      );

      const { total, data: inventoryDetails } = response.data.data;
      setAllInventoryData(inventoryDetails);
      setTotalRecords(total);
    } catch (err) {
      toastText("Failed to get inventory", "error");
    } finally {
      setServiceCentersLoading(false);
    }
  };

  const fetchMoreData = async (record: any, initialCall: boolean) => {
    try {
      if (initialCall) {
        setSelectedRowKeys([]);
        setLocalUpdates({});
        setBulkArrivalDate(null);
      }
      setExpandedLoading((prev) => ({ ...prev, [record.id]: true }));
      const currentPage = expandedPages[record.id] || 1;
      const query = {
        page: currentPage,
        pageSize: 20,
        serviceCenterId: record.id,
      };

      const response = await getApi("/inventory/inventory-list", query);
      const newDetails = response.data.data.data;

      setExpandedDetails((prev) => ({
        ...prev,
        [record.id]: [...(prev[record.id] || []), ...newDetails],
      }));
      if (selectedRowKeys.length > 0 && !initialCall) {
        setSelectedRowKeys([
          ...selectedRowKeys,
          ...newDetails.map((item: any) => item.id.toString()),
        ]);
        //if bulkArrivalDate is selected then update the arrival date of new items
        if (bulkArrivalDate) {
          const updates = newDetails.reduce(
            (acc: Record<string, any>, item: any) => {
              acc[item.id] = {
                arrivalDate: bulkArrivalDate,
              };
              return acc;
            },
            {}
          );
          setLocalUpdates((prev) => ({
            ...prev,
            ...updates,
          }));
        }
      }
      setExpandedPages((prev) => ({
        ...prev,
        [record.id]: currentPage + 1,
      }));
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message ||
        "Failed to fetch detailed inventory";
      toastText(errorMessage, "error");
    } finally {
      setExpandedLoading((prev) => ({ ...prev, [record.id]: false }));
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null, recordKey: number) => {
    // Update localUpdates state
    setLocalUpdates((prev) => ({
      ...prev,
      [recordKey]: {
        ...prev[recordKey],
        arrivalDate: date,
        status: date ? "RECEIVED" : "IN_TRANSIT",
      },
    }));
  
    // Update the expandedDetails state
    setExpandedDetails((prev) => {
      const updatedDetails = { ...prev };
      
      // Find and update the correct service center's details
      Object.keys(updatedDetails).forEach((serviceCenterId) => {
        const updatedGroup = updatedDetails[serviceCenterId].map((item) => {
          if (item.id === recordKey) {
            return {
              ...item,
              arrivalDate: date,
              status: date ? "RECEIVED" : "IN_TRANSIT",
            };
          }
          return item;
        });
        updatedDetails[serviceCenterId] = updatedGroup;
      });
  
      return updatedDetails;
    });
  };
  
  const handleStatusChange = (value: string, recordKey: number) => {
    const updatedData = dataSource.map((row: any) =>
      row.id === recordKey ? { ...row, status: value } : row
    );
    setDataSource(updatedData);
  };
  const handleBatchAction = () => {
    if (!bulkArrivalDate || selectedRowKeys.length === 0) return;

    Modal.confirm({
      title: "Confirmation Required",
      content: `You are about to mark ${
        selectedRowKeys.length
      } items as Received with arrival date ${bulkArrivalDate.format(
        "MM/DD/YYYY"
      )}.`,
      onOk() {
        // Create updates for selected rows
        const updates = selectedRowKeys.reduce(
          (acc: Record<string, any>, id: string) => {
            acc[id] = {
              arrivalDate: bulkArrivalDate,
              status: "RECEIVED", // Explicitly set status to RECEIVED
            };
            return acc;
          },
          {}
        );

        // Update localUpdates state
        setLocalUpdates((prev) => ({
          ...prev,
          ...updates,
        }));

        // Update expandedDetails to reflect status changes in the UI
        setExpandedDetails((prev) => {
          const updatedDetails = { ...prev };

          // Update each service center's details if they contain selected rows
          Object.keys(updatedDetails).forEach((serviceCenterId) => {
            updatedDetails[serviceCenterId] = updatedDetails[
              serviceCenterId
            ].map((item) => {
              if (selectedRowKeys.includes(item.id.toString())) {
                return {
                  ...item,
                  arrivalDate: bulkArrivalDate,
                  status: "RECEIVED", // Explicitly set status to RECEIVED
                };
              }
              return item;
            });
          });

          return updatedDetails;
        });

        // Don't clear selectedRowKeys to maintain selections
        // Don't clear bulkArrivalDate as it might be needed for other operations
      },
    });
  };

  const handleBulkDateChange = (date: dayjs.Dayjs | null) => {
    // Set the bulk arrival date
    setBulkArrivalDate(date);

    // If there are selected rows, update their dates in localUpdates
    if (selectedRowKeys.length > 0) {
      // Create updates for all selected rows
      const updates = selectedRowKeys.reduce(
        (acc: Record<string, any>, id: string) => {
          acc[id] = {
            arrivalDate: date,
            // status: date ? "RECEIVED" : "IN_TRANSIT",
          };
          return acc;
        },
        {}
      );

      // Update localUpdates state
      setLocalUpdates((prev) => ({
        ...prev,
        ...updates,
      }));

      // Update expandedDetails to reflect changes in the UI
      setExpandedDetails((prev) => {
        const updatedDetails = { ...prev };

        // Update each service center's details if they contain selected rows
        Object.keys(updatedDetails).forEach((serviceCenterId) => {
          updatedDetails[serviceCenterId] = updatedDetails[serviceCenterId].map(
            (item) => {
              if (selectedRowKeys.includes(item.id.toString())) {
                return {
                  ...item,
                  arrivalDate: date,
                };
              }
              return item;
            }
          );
        });

        return updatedDetails;
      });
    }
  };

  const onFinish = async () => {
      const hasRowsWithoutDate = selectedRowKeys.some((key: any) => {
      const localUpdate = localUpdates[key];
      if (localUpdate) {
        return !localUpdate.arrivalDate || !localUpdate.status;
      }
      return !bulkArrivalDate;
    });

    if (hasRowsWithoutDate) {
      toastText(
        "Both arrival date and status are required for all selected items",
        "error"
      );
      return;
    }
    // Handle both selected rows and individual updates
    let updatesToSend = [];
  
    // Handle selected rows first
    if (selectedRowKeys.length > 0) {
      const selectedUpdates = selectedRowKeys.map((selectedKey) => {
        let selectedGroup;
        Object.values(expandedDetails).forEach((details: any[]) => {
          const found = details.find((item) => item.id === selectedKey);
          if (found) {
            selectedGroup = found;
          }
        });
  
        const update = localUpdates[selectedKey] || {
          arrivalDate: bulkArrivalDate,
          status: "RECEIVED",
        };
  
        return {
          id: selectedKey,
          arrivalDate: update.arrivalDate ? update.arrivalDate.format("YYYY-MM-DD") : null,
          status: update.status || "RECEIVED",
          ids: selectedGroup?.ids || [],
        };
      });
      updatesToSend = [...selectedUpdates];
    }
  
    // Handle individual updates (where no row is selected)
    if (Object.keys(localUpdates).length > 0) {
      const individualUpdates = Object.entries(localUpdates)
        .filter(([id]) => !selectedRowKeys.includes(id)) // Only include non-selected rows
        .map(([id, update]) => {
          let updateGroup;
          Object.values(expandedDetails).forEach((details: any[]) => {
            const found = details.find((item) => item.id === id);
            if (found) {
              updateGroup = found;
            }
          });
  
          return {
            id,
            arrivalDate: update.arrivalDate ? update.arrivalDate.format("YYYY-MM-DD") : null,
            status: update.status || "RECEIVED",
            ids: updateGroup?.ids || [],
          };
        });
      updatesToSend = [...updatesToSend, ...individualUpdates];
    }
  
    if (updatesToSend.length === 0) {
      toastText("No changes to save", "info");
      return;
    }
  
    try {
      setIsLoading(true);
      const response = await putApi("/inventory/create", updatesToSend);
      const message = response?.data?.message || "Inventory status updated successfully.";
      toastText(message, "success");
  
      // Reset states
      setLocalUpdates({});
      setSelectedRowKeys([]);
      setBulkArrivalDate(null);
      setExpandedDetails({});
      setExpandedRowKeys([]);
  
      onCancel();
      fetchInventoryTable();
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to update inventory";
      toastText(errorMessage, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const expandedRowRender = (record: any) => {
    const details = expandedDetails[record.id] || [];
    const loading = expandedLoading[record.id] || false;

    const columns = [
      {
        title: "#",
        dataIndex: "serialNumber",
        key: "serialNumber",
        width: "80px",
        render: (_: any, __: any, index: number) => index + 1,
      },
      {
        title: "Service Center",
        dataIndex: "serviceCenter",
        key: "serviceCenter",
      },
      {
        title: "3nStar Sales Order",
        dataIndex: "salesOrderNumber",
        key: "salesOrderNumber",
      },
      {
        title: "Item#",
        dataIndex: "itemNumber",
        key: "itemNumber",
      },
      {
        title: "Quantity",
        dataIndex: "totalQuantity",
        key: "totalQuantity",
      },
      {
        title: "Arrival Date",
        dataIndex: "arrivalDate",
        key: "arrivalDate",
        render: (value: dayjs.Dayjs, record: any) => {
          // Check for local updates first
          const localUpdate = localUpdates[record.id];
          const dateValue = localUpdate?.arrivalDate
            ? localUpdate.arrivalDate
            : value
            ? dayjs(value)
            : null;

          return (
            <DatePicker
              value={dateValue}
              format="MM/DD/YYYY"
              onChange={(date) => handleDateChange(date, record.id)}
              disabledDate={(current) =>
                current && current.isAfter(dayjs(), "day")
              }
            />
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_: any, record: any) => {
          const currentStatus =
            record.status === "SHIPPED" ? "IN_TRANSIT" : record.status;

          // Check for local updates first
          const localUpdate = localUpdates[record.id];
          const displayStatus = localUpdate?.status || currentStatus;

          return (
            <Select
              value={displayStatus}
              onChange={(value) => handleStatusChange(value, record.id)}
              style={{ width: 150 }}
            >
              <Option
                value="IN_TRANSIT"
                key="IN_TRANSIT"
                disabled={record.arrivalDate !== null}
              >
                In Transit
              </Option>
              <Option
                value="RECEIVED"
                key="RECEIVED"
                disabled={record.arrivalDate === null}
              >
                Received
              </Option>
            </Select>
          );
        },
      },
    ];

    return (
      <Table
        rowSelection={{
          type: "checkbox",
          selectedRowKeys,
          onChange: (keys: any) => {
            setSelectedRowKeys(keys);
          },
        }}
        rowKey={(record) => record.id}
        dataSource={details} // Pass the current record as the dataSource
        pagination={false}
        columns={columns}
        className="bg-white"
        style={{ width: "100%", padding: "1.5rem 0px" }}
        scroll={{ y: 300 }} // Enables vertical scrolling
        loading={loading}
        onScroll={(e: any) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          // Check if scrolled to bottom
          if (
            Math.abs(scrollHeight - scrollTop - clientHeight) <= 1 &&
            !loading
          ) {
            fetchMoreData(record, false);
          }
        }}
      ></Table>
    );
  };

  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const handleServiceCenterChange = (value: any) => {
    setSelectedServiceCenter(value);
    setCurrentPage(1);
    if (value === "All") {
      localStorage.removeItem("selectedServiceCenter");
      return;
    }

    // Find the selected service center from the serviceCenters array
    const selectedCenter = serviceCenters.find(
      (center) => center.value === value
    );

    if (selectedCenter) {
      localStorage.setItem(
        "selectedServiceCenter",
        JSON.stringify({
          value: selectedCenter.id,
          label: selectedCenter.label,
        })
      );
    }
  };

  useEffect(() => {
    fetchInventory();
  }, [currentPage, PageSize, selectedServiceCenter]);

  // Add this useEffect to load service center from localStorage when component mounts
  useEffect(() => {
    const storedServiceCenter = localStorage.getItem("selectedServiceCenter");

    if (storedServiceCenter && serviceCenters.length > 0) {
      const parsedServiceCenter = JSON.parse(storedServiceCenter);

      // Match by label instead of value
      const matchingServiceCenter = serviceCenters.find(
        (center) => center.label === parsedServiceCenter.label
      );

      if (matchingServiceCenter) {
        setSelectedServiceCenter(matchingServiceCenter.value);
      }
    }
  }, [serviceCenters]); // Add serviceCenters as dependency

  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        setServiceCentersLoading(true);
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          const centers = response.data.data.map((center: any) => ({
            value: center.name,
            label: center.name,
            id: center.id,
          }));

          // Only add 'All' option if there's more than one service center
          if (centers.length > 1) {
            setServiceCenters([{ value: "All", label: "All" }, ...centers]);
          } else {
            setServiceCenters(centers);
          }

          // Set initial selection
          setSelectedServiceCenter(
            centers.length === 1 ? centers[0].value : "All"
          );
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      } finally {
        setServiceCentersLoading(false);
      }
    };

    fetchServiceCenters();
  }, []);

  return (
    <Modal
      open={open}
      footer={null}
      width={1500}
      title="Add Inventory"
      onCancel={onCancel}
      className="full-screen-modal"
      destroyOnClose={true}
    >
      <div
        style={{
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <Row gutter={24} style={{ marginBottom: "16px" }}>
          <Col span={6}>
            <div className={styles["modal-header"]}>
              <p>Service Center Name: </p>
              <Select
                placeholder="Select Service Center"
                onChange={handleServiceCenterChange}
                style={{ width: "60%" }}
                value={selectedServiceCenter}
                options={serviceCenters}
              />
            </div>
          </Col>
          {selectedRowKeys.length > 0 && (
            <>
              <Col span={5}>
                <div className={styles["modal-header"]}>
                  <p>Bulk Update Arrival Date: </p>
                  <DatePicker
                    placeholder="Bulk Update Arrival Date"
                    value={bulkArrivalDate}
                    onChange={handleBulkDateChange}
                    format="MM/DD/YYYY"
                    disabledDate={(currentDate) => {
                      return currentDate && currentDate.isAfter(dayjs(), "day");
                    }}
                  />
                </div>
              </Col>
              <Col span={6}>
                <div className={styles["modal-header"]}>
                  <Button
                    type="primary"
                    onClick={handleBatchAction}
                    disabled={!bulkArrivalDate}
                  >
                    Mark as Received
                  </Button>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Table
          dataSource={allInventoryData}
          rowKey={(record) => record.id}
          loading={isLoading || serviceCenterLoading}
          className="bg-white"
          scroll={{ y: "calc(100vh - 300px)" }}
          expandable={{
            expandedRowRender,
            expandedRowKeys,
            onExpand: (expanded, record) => {
              setSelectedRowKeys([]);
              const newKeys = expanded
                ? [record.id]
                : expandedRowKeys.filter((key) => key !== record.id);
              setExpandedRowKeys(newKeys);
              if (expanded) {
                fetchMoreData(record, true);
              }
            },
          }}
          pagination={{
            total: totalRecords,
            current: currentPage,
            pageSize: PageSize,
            pageSizeOptions: pageSizeOptionsPaging,
            showSizeChanger: true,
            onChange: paginationChangeHandler,
          }}
        >
          <Column
            title="#"
            key="serialNumber"
            // className="bg-white"
            width={"40%"}
            render={(_: any, __: any, index: number) =>
              (currentPage - 1) * PageSize + index + 1
            }
          />
          <Column
            title="Service Center"
            dataIndex="name"
            key="name"
            // className="bg-white"
            width={"60%"}
            render={(text: string) => {
              if (text && text.length > 20) {
                return (
                  <Popover content={text} trigger="hover">
                    <span>{text.slice(0, 20)}...</span>
                  </Popover>
                );
              }
              return text;
            }}
          />
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginTop: "20px",
          gap: "10px",
        }}
      >
        <Button
          type="primary"
          className="save-button"
          onClick={onFinish}
          size="large"
          htmlType="submit"
          disabled={
            Object.keys(localUpdates).length === 0 &&
            selectedRowKeys.length === 0
          }
          // loading={isLoading}
        >
          Save Changes
        </Button>

        <Button
          size="large"
          onClick={onCancel}
          style={{
            marginLeft: "10px",
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default InventoryTableNew;
