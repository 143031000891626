import { Popover, Space, Table, Tag } from "antd";
import moment from "moment";
import { EditActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import SearchAndFilter from "../SearchAndFilter";
import styles from "./index.module.scss";
import { pageSizeOptionsPaging } from "utils/constant";
import { ShipmentTableProps } from "./types";
import {
  SHIPMENT_STATUS_CONFIG,
  SORTABLE_FIELDS,
  SEARCHABLE_FIELDS,
} from "../../../../utils/shipment";
import { useState } from "react";
import { invalidText, toastText } from "utils/utils";
import { putApi } from "redux/apis";

const { Column } = Table;

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const ShipmentTable = (props: ShipmentTableProps) => {
  const {
    allShipmentData,
    isLoading,
    setSelectedShipment,
    openModalHandler,
    totalRecords,
    searchValue,
    sortDirection,
    searchFields,
    performSearchHandler,
    // searchFieldsSelectHandler,
    // resetButtonHandler,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    fetchShipments,
    tableChangeHandler,
    isServiceCenter,
    selectedRowKeys,
    setSelectedRowKeys,
    rowSelection,
  } = props;

  const editDataHandler = (userObject: any) => {
    openModalHandler();
    setSelectedShipment(userObject);
  };

  const [bulkUpdateDate, setBulkUpdateDate] = useState<string | null>(null);
  // const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);

  // Handle pagination
  const paginationChangeHandler = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);

    if (fetchShipments) {
      fetchShipments({
        pageNumber: page,
        pageSize: size,
        searchParams: { fields: searchFields, value: searchValue.trim() },
      });
    }
  };

  // Bulk Update Delivery Date handler
  const handleBulkUpdateDate = (dateString: string | null) => {
    setBulkUpdateDate(dateString);
  };

  // Mark as Shipped handler
  const handleMarkAsShipped = async () => {
    if (!selectedRowKeys.length || !bulkUpdateDate) return;

    const data = {
      deliveryDate: bulkUpdateDate,
      shipmentIds: selectedRowKeys,
    };
    try {
      const response = await putApi("/shipment/bulk-update", data);
      const message =
        response?.data?.message || "Shipments status updated successfully.";
      toastText(message, "success");
    } catch (error) {
      console.log("error: ", error);
      toastText(
        "Something went wrong while updating shipments status.",
        "error"
      );
    } finally {
      // Reset state after marking as shipped
      setSelectedRowKeys([]); // Clear selected rows
      setBulkUpdateDate(null); // Clear the date picker value
      fetchShipments({
        pageNumber: currentPage,
        pageSize: pageSize,
        searchParams: { fields: searchFields, value: searchValue.trim() },
      });
    }
  };

  return (
    <div className={styles["shipment-table"]}>
      <div className={styles["shipment-table__wrapper"]}>
        {/* Search and Filter */}
        <SearchAndFilter
          performSearchHandler={performSearchHandler}
          searchValue={searchValue}
          // searchFieldsSelectHandler={searchFieldsSelectHandler}
          // resetButtonHandler={resetButtonHandler}
          searchFields={searchFields}
          options={SEARCHABLE_FIELDS}
          onBulkUpdateDate={handleBulkUpdateDate}
          onMarkAsShipped={handleMarkAsShipped}
          disableMarkAsShipped={!selectedRowKeys.length || !bulkUpdateDate}
          isServiceCenter={isServiceCenter}
          selectedRowKeys={selectedRowKeys}
        />

        {/* Shipment Table */}
        <Table
          dataSource={allShipmentData}
          rowKey={(record) => record.id}
          loading={isLoading}
          scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
          className="table-global"
          rowSelection={rowSelection}
          onChange={(pagination, filters, sorter: any) => {
            if (sorter.field && !SORTABLE_FIELDS.includes(sorter.field)) {
              return; // Don't sort if field isn't allowed
            }
            tableChangeHandler(pagination, filters, sorter);
          }}
          pagination={{
            total: totalRecords,
            current: currentPage,
            pageSize: pageSize,
            onChange: paginationChangeHandler,
            pageSizeOptions: pageSizeOptionsPaging,
            showSizeChanger: true,
          }}
        >
          <Column
            title="Service Center"
            dataIndex="serviceCenter"
            key="serviceCenter"
            width="18%"
            className="bg-white"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={true}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
            render={(text: string) => {
              if (text && text.length > 20) {
                return (
                  <Popover content={text} trigger="hover">
                    <span>{text.slice(0, 20)}...</span>
                  </Popover>
                );
              }
              return text;
            }}
          />
          <Column
            title="3nStar Sales Order"
            dataIndex="salesOrderNumber"
            key="salesOrderNumber"
            className="bg-white"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={() => {
              return null as any;
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
            width="18%"
          />
          <Column
            title="Delivery Date"
            dataIndex="deliveryDate"
            key="deliveryDate"
            width="15%"
            className="bg-white"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={() => {
              return null as any;
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
            render={(text: string) =>
              text ? moment(text).format("MM/DD/YYYY") : "-"
            }
          />
          <Column
            title="Spare Part Item"
            dataIndex="itemNumber"
            key="itemNumber"
            width="18%"
            className="bg-white"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={() => {
              return null as any;
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
          />
          <Column
            title="Item Description"
            dataIndex="itemDescription"
            key="itemDescription"
            width="12%"
            className="bg-white"
            render={(text: string) => {
              if (invalidText(text)) return "-";
              if (text && text.length > 20) {
                // Adjust the length threshold as needed
                return (
                  <Popover content={text} trigger="hover">
                    <span>{text.slice(0, 20)}...</span>
                  </Popover>
                );
              }
              return text;
            }}
            // render={(text: string) => (invalidText(text) ? "-" : text)}
          />
          <Column
            title="Quantity"
            dataIndex="quantity"
            key="quantity"
            className="bg-white"
            width="12%"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={() => {
              return null as any;
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
            render={(text: string) => (
              <div style={{ textAlign: "center" }}>{text}</div> // Center-align values
            )}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width="11%"
            showSorterTooltip={{ title: "" }}
            defaultSortOrder={sortDirection}
            sorter={() => {
              return null as any;
            }}
            sortDirections={["ascend", "descend", "ascend"]}
            sortIcon={(data) => <SortingIcon data={data} />}
            // width={120}
            className="bg-white"
            render={(status: string) => {
              // Capitalizing first letter of each word
              const formattedStatus = status
                ?.replace(/_/g, " ") // Replaces underscores with spaces
                .toLowerCase() // Converts the string to lowercase
                .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word

              return (
                <Tag
                  color={
                    SHIPMENT_STATUS_CONFIG[
                      status as keyof typeof SHIPMENT_STATUS_CONFIG
                    ]?.color
                  }
                >
                  {formattedStatus}
                </Tag>
              );
            }}
            // render={(status: string) => (
            //   <Tag
            //     color={
            //       SHIPMENT_STATUS_CONFIG[
            //         status as keyof typeof SHIPMENT_STATUS_CONFIG
            //       ]?.color
            //     }
            //   >
            //     {status?.replace(/_/g, " ")?.toLowerCase()}
            //   </Tag>
            // )}
          />
          <Column
            title="Actions"
            dataIndex="action"
            key="action"
            className="bg-white"
            width="9%"
            render={(values: any, data: any) => {
              return (
                <Space
                  size={20}
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                  style={{ display: "flex" }}
                >
                  {["DRAFT", "READY_TO_SHIP"].includes(data.status) &&
                  !isServiceCenter ? (
                    <div
                      className="cursor-pointer flex align-center justify-center"
                      onClick={() => editDataHandler(data)}
                      style={{ marginRight: "2rem" }}
                    >
                      <EditActionSvg />
                    </div>
                  ) : (
                    <div
                      className="flex align-center justify-center"
                      style={{
                        marginRight: "2rem",
                        opacity: 0.3, // Makes the icon appear disabled
                        cursor: "not-allowed",
                        // pointerEvents: "none", // Disables click events
                      }}
                    >
                      <EditActionSvg />
                    </div>
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default ShipmentTable;
