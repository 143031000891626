import { createAsyncThunk } from "@reduxjs/toolkit";
import { postApi } from "redux/apis";

export const qbdUserAction = createAsyncThunk(
  "qbdUser",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi("/connection/saveUser", data);
      return response?.data?.data?.companyId;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
