import { createSlice } from "@reduxjs/toolkit";
import { fetchProfileAction } from "redux/action/profileAction";

const initialState: any = {
  data: null,
  isLoading: true,
  updateLoader: false,

  error: null,
};

const ProfileSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Profile
    builder.addCase(fetchProfileAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchProfileAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(fetchProfileAction.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default ProfileSlice;
