/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputNumber, Popover, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { deleteApi, putApi } from "redux/apis";
import { invalidText, toastText } from "utils/utils";
import SearchAndFilter from "../SearchAndFilter";
import styles from "./index.module.scss";
import "./index.scss";
import { ShipmentTableProps } from "./types";
import { DeleteActionSvg, EditActionSvg } from "utils/svgs";
import ManageItemModal from "../manageItemModel";
import ConfirmDelete from "components/Global/confirmDeleteModel";

const { Column, ColumnGroup } = Table;

const ItemConfigurationTable = (props: ShipmentTableProps) => {
  const {
    allItemData,
    isLoading,
    performSearchHandler,
    searchValue,
    // sortDirection,
    performSortHandler,
    fetchAllItemModels,
    exportItemHandler,
    isExportLoading,
    currentPage,
    modifyPageSize,
    paginationChangeHandler,
    pageSize,
    totalRecords,
  } = props;

  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, pageInfo.current);
  };

  const [data, setData] = useState(allItemData);
  const [apiLoading, setApiLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItemModel, setSelectedItemModel] = useState<any>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useEffect(() => {
    const normalizedData = allItemData.map((item: any) => ({
      ...item,
      boxQuantity:
        item.boxQuantity === null || item.boxQuantity === undefined
          ? 1
          : item.boxQuantity,
      boxMcQuantity:
        item.boxMcQuantity === null || item.boxMcQuantity === undefined
          ? 1
          : item.boxMcQuantity,
      quantity:
        item.quantity === null || item.quantity === undefined
          ? 1
          : item.quantity,
    }));
    setData(normalizedData);
  }, [allItemData]);

  const calculatePalletWeight = (record: any) => {
    return (record.quantity || 1) * (record.mcWeight || 0) + 30;
  };

  const validateData = () => {
    const errors: any = {};
    data.forEach((item: any) => {
      if (!item.length && item.length !== 0) {
        errors[item.id] = { ...errors[item.id], length: true };
      }
      if (!item.width && item.width !== 0) {
        errors[item.id] = { ...errors[item.id], width: true };
      }
      if (!item.height && item.height !== 0) {
        errors[item.id] = { ...errors[item.id], height: true };
      }
      if (!item.weight && item.weight !== 0) {
        errors[item.id] = { ...errors[item.id], weight: true };
      }

      if (!item.boxQuantity && item.boxQuantity !== 0) {
        errors[item.id] = { ...errors[item.id], boxQuantity: true };
      }
      if (!item.mcLength && item.mcLength !== 0) {
        errors[item.id] = { ...errors[item.id], mcLength: true };
      }
      if (!item.mcWidth && item.mcWidth !== 0) {
        errors[item.id] = { ...errors[item.id], mcWidth: true };
      }
      if (!item.mcHeight && item.mcHeight !== 0) {
        errors[item.id] = { ...errors[item.id], mcHeight: true };
      }
      if (!item.mcWeight && item.mcWeight !== 0) {
        errors[item.id] = { ...errors[item.id], mcWeight: true };
      }
      if (!item.boxMcQuantity && item.boxMcQuantity !== 0) {
        errors[item.id] = { ...errors[item.id], boxMcQuantity: true };
      }

      if (!item.quantity && item.quantity !== 0) {
        errors[item.id] = { ...errors[item.id], quantity: true };
      }
      if (!item.palletLength && item.palletLength !== 0) {
        errors[item.id] = { ...errors[item.id], palletLength: true };
      }
      if (!item.palletWidth && item.palletWidth !== 0) {
        errors[item.id] = { ...errors[item.id], palletWidth: true };
      }
      if (!item.palletHeight && item.palletHeight !== 0) {
        errors[item.id] = { ...errors[item.id], palletHeight: true };
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddItem = async () => {
    if (!validateData()) {
      toastText("Please fill out required fields.", "error");
      return;
    }
    if (data.length === 0) {
      toastText("No changes to save", "info");
      return;
    }
    try {
      setApiLoading(true);
      const response = await putApi("/itemModel/bulk-update-models", data);
      const message = `Updated ${response?.data?.data?.updatedCount} models from this table. For other models, you need to change the pagination.`;
      toastText(message, "success");
      fetchAllItemModels();
    } catch (error: any) {
      const message =
        error?.response?.data?.error ||
        "Something went wrong while updating inventory status.";
      toastText(message, "error");
    } finally {
      setApiLoading(false);
    }
  };

  const handleFieldChange = (
    recordId: string,
    field: string,
    value: string | number | null,
    isRequired: boolean = false
  ) => {
    if (isRequired && validationErrors[recordId]?.[field]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [recordId]: { ...prev[recordId], [field]: false },
      }));
    }

    let processedValue = value;

    if (value !== null && value !== "") {
      if (Number(value) < 0) {
        processedValue = 0;
      }
    } else if (!isRequired) {
      processedValue = value;
    }

    setData((prevData) => {
      const updatedData = prevData.map((item: any) => {
        if (item.id === recordId) {
          const updatedItem = { ...item, [field]: processedValue };

          // Recalculate pallet weight if quantity or mcWeight changes
          if (field === "quantity" || field === "mcWeight") {
            updatedItem.palletWeight = calculatePalletWeight(updatedItem);
          }

          return updatedItem;
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleCancel = () => {
    setIsItemModalOpen(false);
    setSelectedItemModel(null);
  };
  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteHandler = async () => {
    if (selectedItemModel) {
      try {
        setIsDeleteLoading(true);
        const response = await deleteApi(
          `/itemModel/delete-model/${selectedItemModel.id}`
        );
        const message = response?.data?.message || "Model deleted successfully";
        toastText(message, "success");
      } catch (error: any) {
        const message =
          error.response?.data?.error?.message ||
          "Something went wrong while deleting model";
        toastText(message, "error");
      } finally {
        setIsDeleteLoading(false);
        setIsDeleteModalOpen(false);
        setSelectedItemModel(null);
        fetchAllItemModels();
      }
    }
  };
  return (
    <div className={styles["shipment-table"]}>
      <div className={styles["shipment-table__wrapper"]}>
        <SearchAndFilter
          performSearchHandler={performSearchHandler}
          searchValue={searchValue}
          addItemData={handleAddItem}
          apiLoading={apiLoading}
          exportItemHandler={exportItemHandler}
          isExportLoading={isExportLoading}
          setIsItemModalOpen={setIsItemModalOpen}
        />
        <Table
          dataSource={data}
          rowKey={(record) => record.id}
          loading={isLoading}
          scroll={{ x: "max-content", y: "calc(100vh - 455px)" }}
          className="table-globall"
          onChange={tableChangeHandler}
          pagination={{
            total: totalRecords,
            current: currentPage,
            onChange: paginationChangeHandler,
            pageSize: pageSize,
            pageSizeOptions: [10, 20],
            showSizeChanger: true,
            onShowSizeChange: modifyPageSize,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} models`,
          }}
          // bordered
        >
          <ColumnGroup title="" className="column-group-items">
            <Column
              title={<div style={{ textAlign: "center" }}>Model Name</div>}
              dataIndex="modelName"
              key="modelName"
              width={230}
              className="bg-white"
              fixed="left"
              render={(text: string, record: any) => {
                if (invalidText(text)) return "-";

                const isModified = record.userUpdated;
                const suffix = isModified ? " (User Modified)" : "";

                const displayText = `${text}${suffix}`;
                const content = (
                  <>
                    {text}
                    {isModified && (
                      <span style={{ fontSize: "12px", color: "#286fd1" }}>
                        {" "}
                        (User Modified)
                      </span>
                    )}
                  </>
                );

                if (displayText.length > 20) {
                  return (
                    <Popover content={displayText} trigger="hover">
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "180px",
                          display: "inline-block",
                        }}
                      >
                        {content}
                      </span>
                    </Popover>
                  );
                }

                return content;
              }}
            />
          </ColumnGroup>

          <ColumnGroup title="Items" className="column-group-items">
            <Column
              title={<div style={{ textAlign: "center" }}>Length</div>}
              dataIndex="length"
              key="length"
              width={130}
              className="item"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "length", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.length
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Width</div>}
              dataIndex="width"
              key="width"
              width={120}
              className="item"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "width", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.width
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Height</div>}
              dataIndex="height"
              key="height"
              width={120}
              className="item"
              render={(value, record) => (
                <InputNumber
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  value={value}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "height", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.height
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Weight</div>}
              dataIndex="weight"
              key="weight"
              width={120}
              className="item"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "weight", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.weight
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Qty per box</div>}
              dataIndex="boxQuantity"
              key="boxQuantity"
              width={130}
              className="item"
              render={(value, record) => (
                <InputNumber
                  value={value ? value : 1}
                  min={1}
                  maxLength={9}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "boxQuantity", newValue, true)
                  }
                  onBlur={(e) => {
                    let roundedValue = Math.round(Number(e.target.value));
                    roundedValue = Math.max(1, roundedValue);
                    handleFieldChange(
                      record.id,
                      "boxQuantity",
                      roundedValue,
                      true
                    );
                  }}
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.boxQuantity
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
          </ColumnGroup>
          <ColumnGroup title="Master Carton" className="column-group-items">
            <Column
              title={<div style={{ textAlign: "center" }}>Length</div>}
              dataIndex="mcLength"
              key="mcLength"
              width={120}
              className="master-carton"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "mcLength", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.mcLength
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Width</div>}
              dataIndex="mcWidth"
              key="mcWidth"
              width={120}
              className="master-carton"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "mcWidth", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.mcWidth
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Height</div>}
              dataIndex="mcHeight"
              key="mcHeight"
              width={120}
              className="master-carton"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "mcHeight", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.mcHeight
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Weight</div>}
              dataIndex="mcWeight"
              key="mcWeight"
              width={120}
              className="master-carton"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "mcWeight", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.mcWeight
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Box per MC</div>}
              dataIndex="boxMcQuantity"
              key="boxMcQuantity"
              width={130}
              className="master-carton"
              render={(value, record) => (
                <InputNumber
                  value={value ? value : 1}
                  min={1}
                  // max={999999999}
                  maxLength={9}
                  onChange={(newValue) =>
                    handleFieldChange(
                      record.id,
                      "boxMcQuantity",
                      newValue,
                      true
                    )
                  }
                  onBlur={(e) => {
                    let roundedValue = Math.round(Number(e.target.value));
                    roundedValue = Math.max(1, roundedValue);
                    handleFieldChange(
                      record.id,
                      "boxMcQuantity",
                      roundedValue,
                      true
                    );
                  }}
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.boxMcQuantity
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
          </ColumnGroup>
          <ColumnGroup title="Pallet" className="column-group-items">
            <Column
              title={<div style={{ textAlign: "center" }}>Quantity</div>}
              dataIndex="quantity"
              key="quantity"
              width={120}
              className="pallet"
              render={(value, record) => (
                <InputNumber
                  value={value ? value : 1}
                  min={1}
                  // max={999999999}
                  maxLength={9}
                  onBlur={(e) => {
                    let roundedValue = Math.round(Number(e.target.value)); // Round the value
                    roundedValue = Math.max(1, roundedValue); // Ensure the value is not below the minimum (1)
                    handleFieldChange(
                      record.id,
                      "quantity",
                      roundedValue,
                      true
                    );
                  }}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "quantity", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.quantity
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Length</div>}
              dataIndex="palletLength"
              key="palletLength"
              width={120}
              className="pallet"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "palletLength", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.palletLength
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Width</div>}
              dataIndex="palletWidth"
              key="palletWidth"
              width={120}
              className="pallet"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "palletWidth", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.palletWidth
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Height</div>}
              dataIndex="palletHeight"
              key="palletHeight"
              width={120}
              className="pallet"
              render={(value, record) => (
                <InputNumber
                  value={value}
                  min={0}
                  // max={999999999}
                  maxLength={9}
                  precision={2}
                  step={0.01}
                  onChange={(newValue) =>
                    handleFieldChange(record.id, "palletHeight", newValue, true)
                  }
                  disabled={record.isDisabled}
                  style={{
                    width: "100px",
                    borderColor: validationErrors[record.id]?.palletHeight
                      ? "red"
                      : "",
                  }}
                />
              )}
            />
            <Column
              title={<div style={{ textAlign: "center" }}>Weight</div>}
              dataIndex="palletWeight"
              key="palletWeight"
              width={120}
              className="pallet"
              render={(value, record) => {
                const calculatedWeight = calculatePalletWeight(record);

                // If no value is set (initial load), default to the calculated weight
                const displayValue = value ?? calculatedWeight;

                return (
                  <div style={{ textAlign: "center", width: "100px" }}>
                    <InputNumber
                      value={displayValue}
                      min={0}
                      maxLength={9}
                      precision={2}
                      step={0.01}
                      onChange={(newValue) => {
                        handleFieldChange(
                          record.id,
                          "palletWeight",
                          newValue,
                          true
                        );
                      }}
                      disabled={record.isDisabled}
                      style={{
                        width: "100px",
                        borderColor: validationErrors[record.id]?.palletWeight
                          ? "red"
                          : "",
                      }}
                    />
                  </div>
                );
              }}
            />
          </ColumnGroup>
          <ColumnGroup title="" className="column-group-items">
            <Column
              title={<div style={{ textAlign: "center" }}>Actions</div>}
              dataIndex="action"
              key="action"
              width={180}
              className="bg-white"
              fixed="right"
              render={(value, data: any) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Space size={20}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedItemModel(data);
                        setIsItemModalOpen(true);
                      }}
                    >
                      <EditActionSvg />
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedItemModel(data);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <DeleteActionSvg />
                    </div>
                  </Space>
                </div>
              )}
            />
          </ColumnGroup>
        </Table>
      </div>
      <ManageItemModal
        isVisible={isItemModalOpen}
        handleCancel={handleCancel}
        selectedModal={selectedItemModel}
        setSelectedItemModal={setSelectedItemModel}
        fetchAllItemModels={fetchAllItemModels}
      />
      <ConfirmDelete
        handleCancel={handleCancelDelete}
        handleOk={handleDeleteOk}
        isModalOpen={isDeleteModalOpen}
        deleteHandler={deleteHandler}
        isAddUserLoading={isDeleteLoading}
      />
    </div>
  );
};

export default ItemConfigurationTable;
