import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";
import { FC, useEffect, useState } from "react";
import { getApi } from "redux/apis";
import styles from "./index.module.scss";

const SearchAndFilter: FC<any> = (props) => {
  const {
    shipData,
    performSearchHandler,
    searchValue,
    isButtonDisabled,
    onCustomerChange,
    isDefaultCustomer,
    defaultCustomer,
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [loading, setLoading] = useState(false); // Loading state for the button
  useEffect(() => {
    if (!isDefaultCustomer) {
      const fetchCustomers = async () => {
        try {
          const response = await getApi("/sales-order/customerName");
          setSelectedCustomers(
            response.data.data.map((center: any) => ({
              value: center.id,
              label: center.name,
            }))
          );
        } catch (error) {
          console.error("Failed to fetch customer:", error);
        }
      };
      fetchCustomers();
    } else {
      if (defaultCustomer) {
        const customers = defaultCustomer.map((center: any) => ({
          value: center.id,
          label: center.name,
        }));
        setSelectedCustomers(customers);

        // Auto-select the first customer
        if (customers.length > 0) {
          setSelectedCustomer(customers[0].value);
          onCustomerChange(customers[0].value);
        }
      }
    }
  }, [isDefaultCustomer, defaultCustomer]);

  const handleCustomerChange = (value: any) => {
    setSelectedCustomer(value);
    onCustomerChange(value); // Notify parent about the change
  };

  const handleShipData = async () => {
    setLoading(true); // Set loading to true
    await shipData(); // Call the parent shipData function
    setLoading(false); // Set loading back to false
  };

  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Select
                placeholder="Select Customer"
                style={{ width: isDefaultCustomer ? "260px" : "300px" }}
                value={selectedCustomer}
                options={selectedCustomers}
                onChange={handleCustomerChange}
                size="large"
                showSearch
                optionFilterProp="label"
                filterOption={(input: string, option?: any) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
              <Input
                className={styles["search-filter__search"]}
                placeholder="Search by sales order..."
                suffix={<SearchOutlined />}
                onChange={performSearchHandler}
                value={searchValue}
                size="large"
                style={{ width: isDefaultCustomer ? "250px" : "300px" }} // Conditionally set the width
              />
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                type="primary"
                className={styles["search-filter__markReceived"]}
                onClick={handleShipData}
                disabled={isButtonDisabled || loading}
                loading={loading} // Show loader if loading
                //    style={{ marginBottom: "15px" }}
              >
                {isDefaultCustomer ? "Add" : " Ready to Ship"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
