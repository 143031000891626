import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { SearchAndFilterProps } from "./types";
import dayjs from "dayjs";
import { getApi, getApiCSV, getApiExcel } from "redux/apis";
import "./index.scss";
import Export from "components/Global/Export";
import { toastText } from "utils/utils";
import moment from "moment";
const SearchAndFilter: FC<SearchAndFilterProps> = (props) => {
  const {
    performSearchHandler,
    searchValue,
    // isServiceCenter,
    onApply,
    handleServiceCenterChange,
    selectedServiceCenter,
    handleSparePartsChange,
    selectedSpareParts,
    handleFromDateChange,
    handleToDateChange,
    fromDate,
    toDate,
    handleSalesOrderNumberChange,
    selectedSalesOrderNumbers,
    handleProductNameChange,
    selectedProductName,
    handleStatusChange,
    selectedStatus,
    setFiltersReset,
  } = props;

  const [selectedKey, setSelectedKey] = useState("serviceCenter");
  const [serviceCenters, setServiceCenters] = useState([]);
  const [spareParts, setSpareParts] = useState([]);
  const [salesOrderNumberOptions, setSalesOrderNumberOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const items = [
    {
      key: "serviceCenter",
      label: "Service Center",
    },
    {
      key: "itemNumber",
      label: "Item Name",
    },
    // {
    //   key: "arrivalDate",
    //   label: "Arrival Date",
    // },
    {
      key: "salesOrderNumber",
      label: "Sales Order",
    },
    {
      key: "productName",
      label: "Product Name",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "useDate",
      label: "UseDate",
    },
  ];

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleCancel = () => {
    setSelectedKey("serviceCenter");
    handleServiceCenterChange(null);
    handleSparePartsChange([]);
    handleSalesOrderNumberChange([]);
    handleProductNameChange([]);
    handleFromDateChange(null);
    handleStatusChange(null);
    handleToDateChange(null);
    setFiltersReset(true);
  };
  const handleExportExcel = async () => {
    try {
      const query: any = {
        serviceCenterId: selectedServiceCenter,
        itemNumber: selectedSpareParts,
        salesOrderNumber: selectedSalesOrderNumbers,
        productName: selectedProductName,
        excludeShipped: true,
        status: selectedStatus,
      };
      console.log("query in front ", query);
      if (fromDate) {
        query.startDate = dayjs(fromDate).format("YYYY-MM-DD");
      }
      if (toDate) {
        query.endDate = dayjs(toDate).format("YYYY-MM-DD");
      }
      const response: any = await getApiExcel(
        "/inventory/export-spare-part",
        query
      );

      const fileName =
        moment(new Date()).format("MM/DD/YYYY_hh-mm-ss") +
        `_Report_inventory.xlsx`;

      const excelBlob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excelUrl = URL.createObjectURL(excelBlob);
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      if (err.response.status === 400) {
        toastText("No data found for file export.", "error");
      } else {
        toastText("Something went wrong in exporting excel report.", "error");
      }
    } finally {
      // setIsExportLoading(false);
    }
  };

  const downloadCsv = async () => {
    try {
      const query: any = {
        serviceCenterId: selectedServiceCenter,
        itemNumber: selectedSpareParts,
        salesOrderNumber: selectedSalesOrderNumbers,
        productName: selectedProductName,
        status: selectedStatus,
        excludeShipped: true,
      };
      console.log("query in front ", query);
      if (fromDate) {
        query.startDate = dayjs(fromDate).format("YYYY-MM-DD");
      }
      if (toDate) {
        query.endDate = dayjs(toDate).format("YYYY-MM-DD");
      }

      const response = await getApiCSV(
        "/inventory/export-spare-part-csv",
        query
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      const fileName =
        moment(new Date()).format("MM/DD/YYYY_hh-mm-ss") +
        `_Report_inventory.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err: any) {
      if (err.response.status === 400) {
        toastText("No data found for file export", "error");
      } else {
        toastText("Something went wrong in exporting csv report.", "error");
      }
    }
  };

  useEffect(() => {
    const fetchServiceCenters = async () => {
      try {
        const response = await getApi("/serviceCenter/shipment-dropdown");
        if (response.data.success) {
          setServiceCenters(
            response.data.data.map((center: any) => ({
              value: center.id,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch service centers:", error);
      }
    };

    const fetchSpareParts = async () => {
      try {
        const response = await getApi("/shipment/getProducts?type=true");
        if (response.data.data.length > 0) {
          setSpareParts(
            response.data.data.map((center: any) => ({
              value: center.name,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await getApi("/shipment/getProducts?type=false");
        if (response.data.data.length > 0) {
          setProductOptions(
            response.data.data.map((center: any) => ({
              value: center.name,
              label: center.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    const fetchSalesOrderNumber = async () => {
      try {
        const response = await getApi("/inventory/sales-order");
        if (response.data.data.length > 0) {
          setSalesOrderNumberOptions(
            response.data.data.map((center: any) => ({
              value: center.salesOrderNumber,
              label: center.salesOrderNumber,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    fetchServiceCenters();
    fetchSpareParts();
    fetchProducts();
    fetchSalesOrderNumber();
  }, []);
  return (
    <div className={styles["search-filter"]}>
      <div className={styles["search-filter__wrapper"]}>
        <Row gutter={[8, 8]} align="middle" justify="space-between">
          <Col>
            <Row gutter={[8, 8]} align="middle">
              <Col>
                <Input
                  className={styles["search-filter__search"]}
                  placeholder="Search..."
                  suffix={<SearchOutlined />}
                  onChange={performSearchHandler}
                  value={searchValue}
                  size="large"
                />
              </Col>
              <Col>
                <Popconfirm
                  placement="rightBottom"
                  title={false}
                  rootClassName="filter-container"
                  description={
                    <div
                      className={styles["filer-content"] + " filter-content"}
                    >
                      <Menu
                        mode="vertical"
                        items={items}
                        onClick={handleMenuClick}
                        selectedKeys={[selectedKey]}
                        defaultSelectedKeys={[selectedKey]}
                      />
                      {selectedKey === "serviceCenter" && (
                        <div>
                          <Select
                            options={serviceCenters}
                            placeholder="Select Service Center"
                            value={selectedServiceCenter ?? null}
                            onChange={handleServiceCenterChange}
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input: string, option?: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      )}
                      {selectedKey === "itemNumber" && (
                        <div>
                          <Select
                            mode="multiple"
                            options={spareParts}
                            placeholder="Select Item Name"
                            value={selectedSpareParts ?? []}
                            onChange={(value) => {
                              handleSparePartsChange(value);
                            }}
                            showSearch
                            optionFilterProp="label"
                            maxTagCount="responsive"
                            maxTagTextLength={15}
                            filterOption={(input: string, option?: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      )}
                      {selectedKey === "useDate" && (
                        <div>
                          <p>From Date</p>
                          <DatePicker
                            size="large"
                            format={{
                              format: "MM/DD/YYYY",
                              type: "mask",
                            }}
                            value={fromDate ? dayjs(fromDate) : null}
                            onChange={(date) => handleFromDateChange(date)}
                            style={{ width: "100%" }}
                            disabledDate={(current) => {
                              const flag =
                                toDate && current && current > dayjs(toDate);
                              return Boolean(flag);
                            }}
                          />
                          <p style={{ marginTop: "15px" }}>To Date</p>
                          <DatePicker
                            size="large"
                            format={{
                              format: "MM/DD/YYYY",
                              type: "mask",
                            }}
                            style={{ width: "100%" }}
                            value={toDate ? dayjs(toDate) : null}
                            onChange={(date) => handleToDateChange(date)}
                            disabledDate={(current) => {
                              const flag =
                                fromDate &&
                                current &&
                                current < dayjs(fromDate);
                              return Boolean(flag);
                            }}
                          />
                        </div>
                      )}
                      {selectedKey === "salesOrderNumber" && (
                        <div>
                          <Select
                            mode="multiple"
                            options={salesOrderNumberOptions}
                            placeholder="Select Sales Order"
                            value={selectedSalesOrderNumbers ?? []}
                            onChange={handleSalesOrderNumberChange}
                            showSearch
                            optionFilterProp="label"
                            maxTagCount="responsive"
                            maxTagTextLength={15}
                            filterOption={(input: string, option?: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      )}
                      {selectedKey === "productName" && (
                        <div>
                          <Select
                            mode="multiple"
                            options={productOptions}
                            placeholder="Select Product Name"
                            value={selectedProductName ?? []}
                            onChange={handleProductNameChange}
                            showSearch
                            optionFilterProp="label"
                            maxTagCount="responsive"
                            maxTagTextLength={15}
                            filterOption={(input: string, option?: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      )}
                      {selectedKey === "status" && (
                        <div>
                          <Select
                            options={[
                              { value: "IN_USE", label: "Used" },
                              { value: "UNUSED", label: "Unused" },
                            ]}
                            placeholder="Select Status"
                            value={selectedStatus ?? null}
                            onChange={handleStatusChange}
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input: string, option?: any) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      )}
                    </div>
                  }
                  okText="Apply"
                  cancelText="Cancel"
                  onConfirm={() => onApply && onApply()}
                  onCancel={handleCancel}
                  icon={false}
                >
                  <Tooltip title="Filters" placement="top">
                    <Button
                      type="primary"
                      className={styles["search-filter__markReceived"]}
                    >
                      <FilterOutlined style={{ fontSize: "20px" }} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
          <Col>
            <Export
              handleExport={(type: string) => {
                if (type === "csv") {
                  downloadCsv();
                  // exportCSv();
                }
                if (type === "xls") {
                  handleExportExcel();
                }
              }}
              isLoading={false}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAndFilter;
