
import { Button, Dropdown, MenuProps, Space } from 'antd';
import React from 'react';
import styles from './index.module.scss';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

interface Props {
	handleExport: (type: string) => void;
	isLoading: boolean;
}

const Export = (props: Props) => {
	const { isLoading, handleExport } = props;
	const items: MenuProps['items'] = [
		{
			label: 'xls',
			key: 'xls',
		},
		{
			label: 'csv',
			key: 'csv',
		},
	];

	const submitHandler = (e: any) => {
		const selectedType = e.key;
		if (selectedType) {
			handleExport(selectedType);
		}
	};

	const menuProps = {
		items,
		onClick: submitHandler,
	};

	return (
		<div className={`${styles['export-data']}`}>
			<div className={styles['export-data__wrapper']}>
				<Dropdown menu={menuProps}>
					<Button className="export-button" size="large">
						<Space>
							Export As
							{isLoading ? (
								<LoadingOutlined />
							) : (
								<DownloadOutlined />
							)}
						</Space>
					</Button>
				</Dropdown>
			</div>
		</div>
	);
};

export default Export;
