import { Col, Form, Input, Modal, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "utils/utils";
import { UserProfileData as UserProfileDataSrc } from "../../constants/Data";

import "./index.scss";
import { UserProfileModalProps } from "./types";

const UserProfileModal: FC<UserProfileModalProps> = (props) => {
  const { handleCancel, isProfileModalOpen } = props;

  const profileData = useSelector(
    (state: any) => state?.userProfile?.data
  );

  const [userProfileData, setUserProfileData] = useState<any>({
    firstName: profileData?.firstName,
    lastName: profileData?.lastName,
    email: profileData?.email,
  });
  useEffect(() => {
    setUserProfileData((prev: any) => {
      const phone = prev?.phone
        ? formatPhoneNumber(prev?.phone?.toString())
        : "";
      return {
        ...prev,
        phone: phone,
      };
    });
  }, [profileData]);

  // Handle Submit Profile
  const handleSubmit = (values: any): void => {
    const profileData = new FormData();
    profileData.append("firstName", values.firstName);
    profileData.append("lastName", values.lastName);
    profileData.append("phone", values.phone);
  };

  return (
    <Modal
      open={isProfileModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      className="profile-modal profile__popup"
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={userProfileData}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <div className="profile-modal__body">
          <div className="userDetailsTitle">
            <b>User Details</b>
          </div>
          <Row gutter={24}>
            {UserProfileDataSrc?.map((item: any, index: number) => (
              <Col
                className="gutter-row fields"
                xs={24}
                sm={item.name == "email" ? 24 : 12}
                md={item.name == "email" ? 24 : 12}
                key={index}
                lg={item.name == "email" ? 24 : 12}
              >
                {item.name !== "phone" && (
                  <>
                    <label className="register-form-label">
                      {item?.title}{" "}
                     
                    </label>
                    <Form.Item
                      className="formItem"
                      name={item?.name}
                      rules={item?.rules as []}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        size="large"
                        disabled={item?.disabled}
                        type={item?.type}
                        defaultValue={userProfileData[item?.name]}
                      />
                    </Form.Item>
                  </>
                )}
              </Col>
            ))}
          </Row>
        </div>
        <hr />
      </Form>
    </Modal>
  );
};

export default UserProfileModal;
