import { ChangeEvent, useEffect, useState } from "react";
import { toastText } from "utils/utils";
import { RegisterTabProps } from "./types";
import RegisterSparePartTable from "./RegisterSparePartTable";
import { RegisterSpareParts } from "./AddRegisterSparePartModel";
import { TableActionHeader } from "components/Global";
import { AddSvg } from "utils/svgs";
import { getApi } from "redux/apis";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RegisterSparePartComponent = (props: RegisterTabProps) => {
  const { refetchCounts } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 10
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [filtersReset, setFiltersReset] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isRegisterSPLoading, setIsRegisterSPLoading] = useState(false);
  const [allRegisterData, setAllRegisterData] = useState<any>([]);
  const [isAddInventoryModalOpen, setIsAddInventoryModalOpen] = useState(false);
  const [selectedRegisterSP, setSelectedRegisterSP] = useState<any>();

  const [selectedServiceCenter, setSelectedServiceCenter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSpareParts, setSelectedSpareParts] = useState([]);
  const [selectedSalesOrderNumbers, setSelectedSalesOrderNumbers] = useState(
    []
  );
  const [selectedProductName, setSelectedProductName] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const isServiceCenter =
    profileData?.companyRoles[0]?.role?.roleName === "Service Center";

  const isEmployee =
    profileData?.companyRoles[0]?.role?.roleName === "3nStar Employee 1";

  const isAdmin =
    profileData?.companyRoles[0]?.role?.roleName === "Admin 3nStar";

  const showAddRegisterSparePartModal = () => {
    setIsAddInventoryModalOpen(true);
  };

  const cancelProp = () => {
    setIsAddInventoryModalOpen(false);
    setSelectedRegisterSP(null);
  };
  // Handle search operation
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  // Handle pagination for the table
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  // Modify the page size
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("userTableSize", size.toString());
    }
    setCurrentPage(1);
  };

  // Handle sorting operation
  const performSortHandler = (type: string, key: string) => {
    if (type !== sortOrder) {
      setSortOrder(type);
      setSortOrder(type === "ascend" ? "asc" : type ? "desc" : "");
      setSortField(key);
    }
  };

  const fetchAllRegisterSparePartDetails = async (params?: any) => {
    try {
      const query: any = {
        page: params?.pageNumber || currentPage,
        pageSize: params?.pageSize || PageSize,
        // page: currentPage,
        searchValue: searchValue,
        sortBy: sortField,
        sortOrder: sortOrder,
        // pageSize: PageSize,
        serviceCenterId: selectedServiceCenter,
        status: selectedStatus,
        itemNumber: selectedSpareParts,
        salesOrderNumber: selectedSalesOrderNumbers,
        productName: selectedProductName,
        startDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : null,
        endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : null,
      };

      setIsRegisterSPLoading(true);

      try {
        const response = await getApi("/inventory/register-spareparts", query);
        setAllRegisterData(response?.data?.data?.data);
        setTotalRecords(response?.data?.data?.total);
      } catch (error) {
        console.log("error: ", error);
      }
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText(
          "Something went wrong in fetching register spareparts",
          "error"
        );
      } else {
        toastText(error.response?.data?.error?.message, "error");
      }
    } finally {
      await refetchCounts();
      setIsRegisterSPLoading(false);
    }
  };

  useEffect(() => {
    if (filtersReset) {
      fetchAllRegisterSparePartDetails({
        pageNumber: 1,
        pageSize: 10,
      });
      setFiltersReset(false);
    }
  }, [filtersReset]);
  const handleOk = () => {
    fetchAllRegisterSparePartDetails({
      pageNumber: 1,
      PageSize,
      serviceCenterId: selectedServiceCenter,
      itemNumber: selectedSpareParts,
      salesOrderNumbers: selectedSalesOrderNumbers,
      productName: selectedProductName,
      status: selectedStatus,
      startDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : null,
      endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : null,
    });
  };
  const handleServiceCenterChange = (data: any) => {
    setSelectedServiceCenter(data);
  };

  const handleStatusChange = (data: any) => {
    setSelectedStatus(data);
  };
  const handleSparePartsChange = (data: any) => {
    setSelectedSpareParts(data);
  };

  const handleFromDateChange = (date: any) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: any) => {
    setToDate(date);
  };

  const handleProductNameChange = (data: any) => {
    setSelectedProductName(data);
  };

  const handleSalesOrderNumberChange = (data: any) => {
    setSelectedSalesOrderNumbers(data);
  };

  useEffect(() => {
    fetchAllRegisterSparePartDetails();
  }, [PageSize, searchValue, currentPage, sortOrder, sortField]);

  return (
    <>
      <div>
        <TableActionHeader title={"Register Spare Part"}>
          {!isEmployee && (
            <div>
              <button
                className={`btn-blue`}
                onClick={showAddRegisterSparePartModal}
              >
                <AddSvg />
                <p>Register Spare Part Use</p>
              </button>
            </div>
          )}
        </TableActionHeader>
        <RegisterSparePartTable
          allRegisterData={allRegisterData}
          isLoading={isRegisterSPLoading}
          isServiceCenter={isServiceCenter}
          openModalHandler={showAddRegisterSparePartModal}
          setSelectedRegisterSP={setSelectedRegisterSP}
          paginationChangeHandler={paginationChangeHandler}
          performSearchHandler={performSearchHandler}
          performSortHandler={(type: any, key: string) =>
            performSortHandler(type, key)
          }
          currentPage={currentPage}
          totalRecords={totalRecords}
          searchValue={searchValue}
          modifyPageSize={modifyPageSize}
          sortDirection={sortOrder}
          PageSize={PageSize}
          handleServiceCenterChange={handleServiceCenterChange}
          selectedServiceCenter={selectedServiceCenter}
          selectedStatus={selectedStatus}
          handleSelectedStatus={handleStatusChange}
          handleSparePartsChange={handleSparePartsChange}
          selectedSpareParts={selectedSpareParts}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          fromDate={fromDate}
          toDate={toDate}
          handleSalesOrderNumberChange={handleSalesOrderNumberChange}
          selectedSalesOrderNumbers={selectedSalesOrderNumbers}
          handleProductNameChange={handleProductNameChange}
          selectedProductName={selectedProductName}
          handleOk={handleOk}
          fetchSpareParts={fetchAllRegisterSparePartDetails}
          isEmployee={isEmployee}
          setFiltersReset={setFiltersReset}
        />
        {isAddInventoryModalOpen ? (
          <RegisterSpareParts
            open={isAddInventoryModalOpen}
            onCancel={cancelProp}
            isAdmin={isAdmin}
            selectedRegisterSP={selectedRegisterSP}
            fetchRegisterData={fetchAllRegisterSparePartDetails}
          />
        ) : null}
      </div>
    </>
  );
};

export default RegisterSparePartComponent;
