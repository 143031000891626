import { Button, Form, Modal, Radio } from "antd";
import React, { useState } from "react";

const PalletTypeModal: React.FC<any> = (props) => {
  const { onCancel, isPalletTypeModalOpen, onSave } = props;
  const [selectedPalletType, setSelectedPalletType] = useState<string>("PALLET");

  const onFinish = () => {
    onSave(selectedPalletType); // Call parent handler with selected type
  };

  const handleTypeChange = (e: any) => {
    setSelectedPalletType(e.target.value);
  };

  return (
    <Modal
      open={isPalletTypeModalOpen}
      footer={null}
      width={700}
      title="Select the pallet type you want to ship item in"
      onCancel={onCancel}
    >
      <Form onFinish={onFinish}>
        <Radio.Group
          onChange={handleTypeChange}
          value={selectedPalletType}
          style={{ margin: "16px 0px" }}
        >
          <Radio value="PALLET">Pallet</Radio>
          <Radio value="MIXPALLET">Mixed Pallet</Radio>
        </Radio.Group>
        <div style={{ display: "flex", justifyContent: "start",marginTop:'20px' }}>
          <Button type="primary" htmlType="submit" size="large"  className="save-button">
            Save
          </Button>
          <Button
            size="large"
            style={{ marginLeft: "10px" }}
            onClick={onCancel}
             className="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PalletTypeModal;
