import { TableActionHeader } from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { ChangeEvent, useEffect, useState } from "react";
import { getApi } from "redux/apis";
import { toastText } from "utils/utils";
import CompletedSalesOrderTable from "./CompletedSalesOrderTable";

const CompletedSalesOrder = () => {
  const [salesOrderData, setSalesOrderData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPacking, setSelectedPacking] = useState<any>();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const performSortHandler = (type: string) => {
    if (type !== sortOrder) {
      setSortOrder(type);
      setSortOrder(type === "ascend" ? "asc" : type ? "desc" : "");
      setSortField("updatedAt");
    }
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    //    setCurrentPage(1);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  //   For cancel operation
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchAllCompletedSalesOrder = async () => {
    try {
      const query: any = {
        searchValue: searchValue,
        sortBy: sortField,
        sortOrder: sortOrder,
      };

      try {
        setIsLoading(true);
        const response = await getApi(
          "/shipping/get-completed-shippinglist",
          query
        );
        const flattenedData = response.data.data.flatMap((item: any) =>
          item.salesOrders.map((salesOrder: any) => ({
            salesOrderId: salesOrder.id,
            packageId: item.id, // Main ID for the group
            salesOrder: salesOrder.refNum,
            date: salesOrder.txnDate, // Just using the date part
            shippingMethod: salesOrder.shippingMethod,
            notes: salesOrder.notes,
            status: salesOrder.status,
            updatedAt: item.updatedAt,
          }))
        );
        setSalesOrderData(flattenedData);
        // setTotalRecords(response?.data?.data?.total);
      } catch (error) {
        console.log("error: ", error);
      }
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText("Something went wrong in fetching sales order", "error");
      } else {
        toastText(error.response?.data?.error?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (selectedPacking) {
      try {
        setIsDeleteLoading(true);
        const query: any = {
          packageId: selectedPacking.packageId,
        };
        const response = await getApi(
          "/packing-list/delete-completed-packaging",
          query
        );
        console.log("response: ", response);
        const message =
          response?.data?.message || "Packaging deleted successfully";
        toastText(message, "success");
      } catch (error: any) {
        const message =
          error.response?.data?.error?.message ||
          "Something went wrong in deleting packaging";
        toastText(message, "error");
      } finally {
        setIsDeleteLoading(false);
        setIsModalOpen(false);
        setSelectedPacking(null);
        fetchAllCompletedSalesOrder();
      }
    }
  };

  useEffect(() => {
    fetchAllCompletedSalesOrder();
  }, [searchValue, sortOrder, sortField]);

  return (
    <>
      <div>
        <TableActionHeader title={"Completed Sales Orders"}></TableActionHeader>
        <CompletedSalesOrderTable
          salesOrderData={salesOrderData}
          isLoading={isLoading}
          performSearchHandler={performSearchHandler}
          searchValue={searchValue}
          //       sortDirection={sortOrder}
          performSortHandler={(type: any) => performSortHandler(type)}
          fetchSalesOrder={fetchAllCompletedSalesOrder}
          setSelectedPacking={setSelectedPacking}
          showModal={showModal}
        />
        <ConfirmDelete
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalOpen={isModalOpen}
          deleteHandler={handleDelete}
          isAddUserLoading={isDeleteLoading}
        />
      </div>
    </>
  );
};

export default CompletedSalesOrder;
