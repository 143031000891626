import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "redux/apis";

export const fetchProfileAction = createAsyncThunk(
  "auth/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getApi("/auth/profile");

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
