import { Popover, Space, Table, Tag } from "antd";
import moment from "moment";
import { useState } from "react";
import { getApi } from "redux/apis";
import { pageSizeOptionsPaging } from "utils/constant";
import { EditActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import { invalidText, toastText } from "utils/utils";
import SearchAndFilter from "../SearchAndFilter";
import styles from "./index.module.scss";
// import Ellipse from "components/Global/Ellipse";

const { Column } = Table;

interface Props {
  allInventoryData: any[];
  isLoading: boolean;
  onEdit: (inventoryData: any) => void;
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  tableChangeHandler: (pagination: any, filters: any, sorter: any) => void;
  isServiceCenter: boolean;
  isEmployee: boolean;
  searchValue: string;
  performSearchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchFields: string[];
  handleOk: any;
  handleServiceCenterChange: any;
  selectedServiceCenter: any;
  handleSparePartsChange: any;
  selectedSpareParts: any;
  fetchInventory: any;
  handleFromDateChange: any;
  handleToDateChange: any;
  fromDate: any;
  toDate: any;
  handleSalesOrderNumberChange: any;
  selectedSalesOrderNumbers: any;
  isAdmin?: any;
  sortDirection: any;
  handleStatusChange: any;
  selectedStatus: any;
  expandedRowKeys: any;
  expandedDetails: any;
  expandedPages: any;
  setExpandedRowKeys: any;
  setExpandedDetails: any;
  setExpandedPages: any;
  setFiltersReset: any;
}

const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const InventoryTable = (props: Props) => {
  const {
    allInventoryData,
    isLoading,
    onEdit,
    currentPage,
    pageSize,
    totalRecords,
    tableChangeHandler,
    isServiceCenter,
    isEmployee,
    searchValue,
    performSearchHandler,
    searchFields,
    handleOk,
    handleServiceCenterChange,
    selectedServiceCenter,
    handleSparePartsChange,
    selectedSpareParts,
    handleFromDateChange,
    handleToDateChange,
    fromDate,
    toDate,
    fetchInventory,
    handleSalesOrderNumberChange,
    selectedSalesOrderNumbers,
    isAdmin,
    sortDirection,
    handleStatusChange,
    selectedStatus,
    expandedRowKeys,
    expandedDetails,
    expandedPages,
    setExpandedRowKeys,
    setExpandedDetails,
    setExpandedPages,
    setFiltersReset,
  } = props;

  // Don't remove below commented code
  // const [expandedRowData, setExpandedRowData] = useState<any[]>([]);
  // const [expandedLoading, setExpandedLoading] = useState(false);
  // const [currentExpandedPage, setCurrentExpandedPage] = useState(1);

  // const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  // const [expandedDetails, setExpandedDetails] = useState<Record<string, any[]>>(
  //   {}
  // );
  const [expandedLoading, setExpandedLoading] = useState<
    Record<string, boolean>
  >({});
  // const [expandedPages, setExpandedPages] = useState<Record<string, number>>(
  //   {}
  // );

  const fetchMoreData = async (record: any) => {
    try {
      setExpandedLoading((prev) => ({ ...prev, [record.id]: true }));
      const currentPage = expandedPages[record.id] || 1;
      const query = {
        page: currentPage,
        pageSize: 10,
        serviceCenterId: record.serviceCenterId,
        salesOrderNumber: [record.salesOrderNumber],
        itemNumber: [record.itemNumber],
        status: selectedStatus,
      };

      const response = await getApi("/inventory", query);
      const newDetails = response.data.data.data;

      setExpandedDetails((prev: any) => ({
        ...prev,
        [record.id]: [...(prev[record.id] || []), ...newDetails],
      }));

      setExpandedPages((prev: any) => ({
        ...prev,
        [record.id]: currentPage + 1,
      }));
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.error?.message ||
        "Failed to fetch detailed inventory";
      toastText(errorMessage, "error");
    } finally {
      setExpandedLoading((prev) => ({ ...prev, [record.id]: false }));
    }
  };

  const expandedRowRender = (record: any) => {
    const details = expandedDetails[record.id] || [];
    const loading = expandedLoading[record.id] || false;

    return (
      <Table
        dataSource={details} // Pass the current record as the dataSource
        pagination={false}
        className="bg-white"
        style={{ width: "100%", padding: "1.5rem 0px" }}
        scroll={{ y: 300 }} // Enables vertical scrolling
        loading={loading}
        onScroll={(e: any) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          // Check if scrolled to bottom
          if (
            Math.abs(scrollHeight - scrollTop - clientHeight) <= 1 &&
            !loading
          ) {
            fetchMoreData(record);
          }
        }}
      >
        <Column
          title="#"
          key="serialNumber"
          width={"9%"}
          render={(_: any, __: any, index: number) =>
            (currentPage - 1) * pageSize + index + 1
          }
        />
        <Column
          title="Service Center"
          dataIndex="serviceCenter"
          key="serviceCenter"
          className="bg-white"
          width={"16%"}
          showSorterTooltip={{ title: "" }}
          // defaultSortOrder={sortDirection}
          // sorter={() => {
          //   return null as any;
          // }}
          // sortDirections={["ascend", "descend", "ascend"]}
          // sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => {
            if (text && text.length > 11) {
              return (
                <Popover content={text} trigger="hover" placement="top" // Add fixed placement
                getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body} >
                  <span>{text.slice(0, 11)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="3nStar Sales Order"
          dataIndex="salesOrderNumber"
          key="salesOrderNumber"
          className="bg-white"
          width={"20%"}
          // showSorterTooltip={{ title: "" }}
          // defaultSortOrder={sortDirection}
          // sorter={() => {
          //   return null as any;
          // }}
          // sortDirections={["ascend", "descend", "ascend"]}
          // sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Item#"
          dataIndex="itemNumber"
          key="itemNumber"
          className="bg-white"
          width={"19%"}
          // showSorterTooltip={{ title: "" }}
          // defaultSortOrder={sortDirection}
          // sorter={() => {
          //   return null as any;
          // }}
          // sortDirections={["ascend", "descend", "ascend"]}
          // sortIcon={(data) => <SortingIcon data={data} />}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Item Description"
          dataIndex="itemDescription"
          key="itemDescription"
          className="bg-white"
          width={"20%"}
          render={(text: string) => {
            if (invalidText(text)) return "-";
            if (text && text.length > 20) {
              // Adjust the length threshold as needed
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 20)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="Arrival Date"
          dataIndex="arrivalDate"
          key="arrivalDate"
          className="bg-white"
          width={"15%"}
          // showSorterTooltip={{ title: "" }}
          // defaultSortOrder={sortDirection}
          // sorter={() => {
          //   return null as any;
          // }}
          // sortDirections={["ascend", "descend", "ascend"]}
          // sortIcon={(data) => <SortingIcon data={data} />}
          render={(text) => (text ? moment(text).format("MM/DD/YYYY") : "-")}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"15%"}
          // showSorterTooltip={{ title: "" }}
          // defaultSortOrder={sortDirection}
          // sorter={() => {
          //   return null as any;
          // }}
          // sortDirections={["ascend", "descend", "ascend"]}
          // sortIcon={(data) => <SortingIcon data={data} />}
          render={(status: string) => {
            const statusColorMap: Record<string, string> = {
              IN_TRANSIT: "blue",
              RECEIVED: "green",
              LOST: "yellow",
              DAMAGED: "red",
            };
            const formattedStatus = status
              .replace(/_/g, " ") // Replaces underscores with spaces
              .toLowerCase() // Converts the string to lowercase
              .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word

            return (
              <Tag color={statusColorMap[status] || "default"}>
                {formattedStatus}
              </Tag>
            );
          }}
        />
        <Column
          title="Actions"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"10%"}
          render={(values: any, data: any) => {
            const isEditable =
              !isEmployee && (isAdmin || data.status === "IN_TRANSIT");

            // const isEditable = isAdmin || data.status === "IN_TRANSIT";
            return (
              <Space size={20}>
                {isEditable ? (
                  <div
                    className="cursor-pointer flex align-center justify-center"
                    onClick={() => onEdit(data)}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>
                ) : (
                  <div
                    className="flex align-center justify-center"
                    style={{
                      marginRight: "2rem",
                      opacity: 0.3, // Makes the icon appear disabled
                      cursor: "not-allowed",
                    }}
                  >
                    <EditActionSvg />
                  </div>
                )}
              </Space>
            );
          }}
        />
      </Table>
    );
  };
  [];
  return (
    <div className={styles["inventory-table__wrapper"]}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        searchFields={searchFields}
        isServiceCenter={isServiceCenter}
        handleServiceCenterChange={handleServiceCenterChange}
        selectedServiceCenter={selectedServiceCenter}
        handleSparePartsChange={handleSparePartsChange}
        selectedSpareParts={selectedSpareParts}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        onApply={handleOk}
        fromDate={fromDate}
        toDate={toDate}
        handleSalesOrderNumberChange={handleSalesOrderNumberChange}
        selectedSalesOrderNumbers={selectedSalesOrderNumbers}
        fetchInventory={fetchInventory}
        handleStatusChange={handleStatusChange}
        selectedStatus={selectedStatus}
        setFiltersReset={setFiltersReset}
      />
      <Table
        dataSource={allInventoryData}
        rowKey={(record) => record.id}
        loading={isLoading}
        scroll={{ y: "calc(100vh - 450px)", x: "63vh" }}
        onChange={tableChangeHandler}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            const newKeys = expanded
              ? [record.id]
              : expandedRowKeys.filter((key: any) => key !== record.id);

            setExpandedRowKeys(newKeys);

            if (expanded) {
              fetchMoreData(record);
            }
          },
        }}
        pagination={{
          total: totalRecords,
          current: currentPage,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
        }}
        className={styles["inventory-table"]}
      >
        <Column
          title="#"
          key="serialNumber"
          width={"20%"}
          render={(_: any, __: any, index: number) =>
            (currentPage - 1) * pageSize + index + 1
          }
        />
        <Column
          title="Service Center"
          dataIndex="serviceCenter"
          key="serviceCenter"
          className="bg-white"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => {
            if (text && text.length > 11) {
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 11)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="3nStar Sales Order"
          dataIndex="salesOrderNumber"
          key="salesOrderNumber"
          className="bg-white"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />
        <Column
          title="Item#"
          dataIndex="itemNumber"
          key="itemNumber"
          className="bg-white"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
        />

        <Column
          title="Quantity"
          dataIndex="totalQuantity"
          key="totalQuantity"
          className="bg-white"
          width={"20%"}
        />
      </Table>
    </div>
  );
};

export default InventoryTable;
