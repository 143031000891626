import React, { useState } from "react";
import {
  Drawer,
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  // Tag,
  message,
} from "antd";
import "./index.css";
import dayjs from "dayjs";
import { postApi } from "redux/apis";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface ExtendWarrantyDrawerProps {
  visible: boolean;
  onClose: (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void;
  formData: any;
  onSave: (data: any) => void;
}

const ExtendWarrantyDrawer: React.FC<ExtendWarrantyDrawerProps> = ({
  visible,
  onClose,
  formData,
  onSave,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    form.setFieldsValue({
      expiryDate: formData?.expiryDate
        ? dayjs.utc(formData.expiryDate) // Convert UTC to Local
        : null,
      invoiceDate: formData?.invoiceDate
        ? dayjs.utc(formData.invoiceDate).format("MM/DD/YYYY")
        : null,
      itemDesc: formData?.itemDesc,
      serialNumber: formData?.serialNumber,
      invoiceNumber: formData?.invoiceNumber,
      itemName: formData?.itemName,
      customerName: formData?.customerName,
      status: formData?.isExpired,
    });
  }, [formData, form]);

  const handleSubmit = async (values: any) => {
    const apiUrl = "/productWarrantyDetails/extend-warranty-via-file";
    setIsLoading(true);
    const formDataEntry = {
      serialNumber: formData.serialNumber,
      productName: formData.itemName,
      customerName: formData.customerName,
      newExpiryDate: dayjs(values.expiryDate).toDate(),
    };

    const response = await postApi(apiUrl, [formDataEntry]);
    console.log("API response:", response);

    if (response.status === 200) {
      onSave({
        ...formData,
        expiryDate: dayjs(values.expiryDate).format("MM/DD/YYYY"),
      });
      message.success("Warranty dates extended successfully");
      onClose(
        new MouseEvent("click") as unknown as React.MouseEvent<
          Element,
          MouseEvent
        >
      );
    }
    setIsLoading(false);
  };

  return (
    <Drawer
      title="Extend Warranty"
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={600}
      className="extend-warranty-drawer"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={formData}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Item Serial Number"
              name="serialNumber"
              className="customInput"
            >
              <Input
                disabled
                className="custom-input-disabled"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="3nStar Invoice Number"
              name="invoiceReferenceNumber"
              className="customInput"
            >
              <Input
                disabled
                style={{ marginBottom: "16px" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="3nStar Invoice Date"
              name="invoiceDate"
              className="customInput"
            >
              <Input
                disabled
                className="custom-input-disabled"
              />
            </Form.Item>
          </Col>

          <Col
            span={12}
            style={{ marginBottom: "16px" }}
          >
            <Form.Item
              label="Warranty Expiration Date"
              name="expiryDate"
              style={{ marginBottom: "16px" }}
              className="customInput"
              rules={[
                {
                  required: true,
                  message: "Please select warranty expiration date",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="MM/DD/YYYY"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Customer Name"
              name="customerName"
              className="customInput"
            >
              <Input
                disabled
                className="custom-input-disabled"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Item Name"
              name="itemName"
              className="customInput"
            >
              <Input
                disabled
                style={{ marginBottom: "16px" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Item Description"
              name="itemDesc"
              className="customInput"
            >
              <Input
                disabled
                style={{ marginBottom: "16px" }}
              />
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              label="Status"
              name="status"
              className="customInput"
            >
              <Tag color={!formData?.isExpired ? "green" : "red"}>
                {!formData?.isExpired
                  ? "In Warranty"
                  : formData.ruleName == "No_Rules_Matched"
                    ? "-"
                    : "Expired"}
              </Tag>
            </Form.Item>
          </Col> */}
        </Row>

        <div className="formButtons">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="custom-submit-btn"
            loading={isLoading}
          >
            Save
          </Button>
          <Button
            onClick={onClose}
            size="large"
            className="customBtn"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default ExtendWarrantyDrawer;
