import "./index.scss";
import SalesOrder from "components/SalesOrder";


export const SalesOrders = () => {
  return (
    <div>
      <SalesOrder />
    </div>
  );
};
