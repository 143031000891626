import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchProfileAction } from "redux/action/profileAction";
import { getCompanies } from "redux/slice/companySlice";
import { AppDispatch } from "redux/store";
import Cookies from "js-cookie";
import { capitalizeFirstLetter } from "utils/Common";
import { toastText } from "utils/utils";

export const ROLE_NAME = {
  SUPER_ADMIN: "Admin 3nStar",
  EMPLOYEE1: "3nStar Employee 1",
  EMPLOYEE2: "3nStar Employee 2",
  SERVICE_CENTER: "Service Center",
};

export function antStringToRegex(string: string) {
  return string.endsWith("*") ? string.replace("**", ".*") : string + "$";
}

const authorizedPaths = [
  {
    path: antStringToRegex("/settings/item"), // This must come first
    userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.EMPLOYEE1],
  },
  { path: antStringToRegex("/settings**"), userRoles: [ROLE_NAME.SUPER_ADMIN] },
  {
    path: antStringToRegex("/"),
    userRoles: [
      ROLE_NAME.SUPER_ADMIN,
      ROLE_NAME.EMPLOYEE1,
      ROLE_NAME.EMPLOYEE2,
    ],
  },
  {
    path: antStringToRegex("/sales"),
    userRoles: [
      ROLE_NAME.SUPER_ADMIN,
      ROLE_NAME.EMPLOYEE1,
      ROLE_NAME.EMPLOYEE2,
      ROLE_NAME.SERVICE_CENTER,
    ],
  },
  {
    path: antStringToRegex("/inventory-control**"),
    userRoles: [
      ROLE_NAME.SUPER_ADMIN,
      ROLE_NAME.EMPLOYEE1,
      ROLE_NAME.SERVICE_CENTER,
    ],
  },
  {
    path: antStringToRegex("/sales-order**"),
    userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.EMPLOYEE1],
  },
  {
    path: antStringToRegex("/shipping**"),
    userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.EMPLOYEE1],
  },
  {
    path: antStringToRegex("/packing-list**"),
    userRoles: [ROLE_NAME.SUPER_ADMIN, ROLE_NAME.EMPLOYEE1],
  },
  {
    path: antStringToRegex("/**"),
    userRoles: [
      ROLE_NAME.SUPER_ADMIN,
      ROLE_NAME.SERVICE_CENTER,
      ROLE_NAME.EMPLOYEE1,
      ROLE_NAME.EMPLOYEE2,
    ],
  },
];

export const AuthLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const authpath = ["/login", "/forgot-password", "/reset-password"];

  // // To change the title.
  const { pathname } = useLocation();

  const logoutHandler = () => {
    navigate("/login");
    Cookies.remove("accessToken");
    localStorage.clear();
    toastText(
      "You have been logged out due to inactivity. Please log in again to continue.",
      "error"
    );
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      logoutHandler(); // Clear tokens and redirect to login
    }, 30 * 60 * 1000);

    const resetTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        logoutHandler();
      }, 30 * 60 * 1000);
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, []);

  const path = window.location.pathname;

  useEffect(() => {
    const token = Cookies.get("accessToken");
    if (token) {
      dispatch(fetchProfileAction())
        .unwrap()
        .then((res) => {
          dispatch(getCompanies(res));
        })
        .catch((error: any) => {
          if (error.responseStatus === 401) {
            navigate("/access-denied");
          } else if (
            !(path === "/forgot-password" || path === "/reset-password")
          ) {
            navigate("/login"); //For Verify Email
          }
        });

      if (path === "/login") {
        navigate("/");
      }
    } else {
      if (!(path === "/forgot-password" || path === "/reset-password")) {
        navigate("/login"); //For Verify Email
      }
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("accessToken");
    const check = window.location.pathname.split("/").reverse();
    const url = check.filter(
      (item: any) =>
        item !== "localhost:3000" && item !== "" && item !== "http:"
    );
    if (url.length !== 0) {
      if (url.length < 3) {
        if (url.length < 2) {
          document.title = `${
            capitalizeFirstLetter(url[0]) + " | " + "3nStar"
          }`;
        } else {
          document.title = `${
            capitalizeFirstLetter(url[0]) +
            " | " +
            capitalizeFirstLetter(url[1]) +
            " | " +
            "3nStar"
          }`;
        }
      } else {
        document.title = `${
          capitalizeFirstLetter(url[1]) +
          " | " +
          capitalizeFirstLetter(url[0]) +
          " | " +
          "3nStar"
        }`;
      }
    } else {
      document.title = `3nStar`;
    }

    if (!authpath.includes(path) && !token) {
      navigate("/login");
    }
  }, [pathname]);

  // Get user role from Redux store
  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles?.[0]?.role?.roleName || null;

  // Check if the current path is authorized for the user's role
  const isPathAuthorized = (path: string, userRole: string | null): boolean => {
    if (!userRole) return false;

    const pathConfig = authorizedPaths.find((authPath) =>
      new RegExp(authPath.path).test(path)
    );

    if (!pathConfig) return false;

    if (!pathConfig.userRoles) return false;

    return pathConfig.userRoles.includes(userRole);
  };

  useEffect(() => {
    if (!roleName) return; // Wait until roleName is available

    const currentPath = location.pathname;
    if (!isPathAuthorized(currentPath, roleName)) {
      navigate("/login");
      Cookies.remove("accessToken");
    }
  }, [path, roleName]);

  return <Outlet />;
};
