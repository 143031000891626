import { createSlice } from "@reduxjs/toolkit";
import { qbdUserAction } from "redux/action/qbdUserAction";
import { toastText } from "utils/utils";

const initialState: any = {
  data: null,
  isLoading: false,
  error: null,
};

const QbdUserSlice = createSlice({
  name: "qbdUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(qbdUserAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(qbdUserAction.fulfilled, (state, action: any) => {
      state.isLoading = false;
      state.data = action?.payload;
    });
    builder.addCase(qbdUserAction.rejected, (state, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
      toastText(action?.payload?.message, "error");
    });
  },
});

export default QbdUserSlice;
