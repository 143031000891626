import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  InputRef,
} from "antd";
import Title from "antd/es/typography/Title";
import { getApi, postApi, putApi } from "redux/apis";
import styles from "./index.module.scss";
import { FORMDATA } from "constants/Data";
import dayjs from "dayjs";
import MissingPartsModal from "../MissingSparePartModel";
import { toastText } from "utils/utils";

interface AddShipmentManuallyModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  selectedShipment?: any;
  setSelectedShipment: React.Dispatch<React.SetStateAction<any>>;
  fetchAllShipmentDetails: any;
}

interface ServiceCenterOption {
  value: string | number;
  label: string;
}

const AddShipmentManuallyModal: React.FC<AddShipmentManuallyModalProps> = ({
  isVisible,
  handleCancel,
  selectedShipment,
  setSelectedShipment,
  fetchAllShipmentDetails,
}) => {
  const { shipmentFields } = FORMDATA;
  const [form] = Form.useForm();
  const [showMissingParts, setShowMissingParts] = useState(false);
  const [missingParts, setMissingParts] = useState([]);
  const [serviceCenters, setServiceCenters] = useState<ServiceCenterOption[]>(
    []
  );
  const [items, setItems] = useState<any[]>([]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
    { value: "SHIPPED", label: "Shipped", disabled: true },
  ]);

  const [itemsWithDetails, setItemsWithDetails] = useState<any[]>([]);

  const [itemsArray, setItemsArray] = useState<
    { itemName: string; quantity: string; itemDescription: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const salesOrderInputRef = useRef<InputRef>(null);

  const [serviceCenterLoading, setServiceCentersLoading] = useState(false);

  useEffect(() => {
    if (isVisible && form.getFieldValue("serviceCenterName")) {
      setTimeout(() => {
        salesOrderInputRef.current?.focus();
      }, 100); // Adding a slight delay ensures focus is set correctly
    }
  }, [isVisible, serviceCenters]);

  // Effect to set form values when selectedShipment changes
  useEffect(() => {
    if (selectedShipment && serviceCenters) {
      const serviceCenterId = serviceCenters?.find(
        (center) => center.label === selectedShipment.serviceCenter
      )?.value;
      const formValues = {
        serviceCenterName: serviceCenterId,
        salesOrder: selectedShipment.salesOrderNumber,
        deliveryDate: selectedShipment.deliveryDate
          ? dayjs(selectedShipment.deliveryDate)
          : null,
        itemNumber: selectedShipment.itemNumber,
        itemDescription: selectedShipment.itemDescription || null,
        status: selectedShipment.status,
        quantity: selectedShipment.quantity.toString(),
      };
      form.setFieldsValue(formValues);

      setStatusOptions(
        selectedShipment.deliveryDate
          ? [
              {
                value: "READY_TO_SHIP",
                label: "Ready to Ship",
                disabled: true,
              },
              { value: "SHIPPED", label: "Shipped", disabled: false },
            ]
          : [
              {
                value: "READY_TO_SHIP",
                label: "Ready to Ship",
                disabled: false,
              },
              { value: "SHIPPED", label: "Shipped", disabled: true },
            ]
      );
      form.setFieldsValue({ status: "READY_TO_SHIP" });
    } else {
      form.resetFields();
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
        { value: "SHIPPED", label: "Shipped", disabled: true },
      ]);
    }
  }, [selectedShipment, form]);

  useEffect(() => {
    //Don't Remove commented code
    // const fetchServiceCenters = async () => {
    //   try {
    //     const response = await getApi("/serviceCenter/shipment-dropdown");
    //     if (response.data.success) {
    //       setServiceCenters(
    //         response.data.data.map((center: any) => ({
    //           value: center.id,
    //           label: center.name,
    //         }))
    //       );
    //     }
    //   } catch (error) {
    //     console.error("Failed to fetch service centers:", error);
    //   }
    // };
    if (isVisible) {
      const fetchServiceCenters = async () => {
        try {
          setServiceCentersLoading(true);
          const response = await getApi("/serviceCenter/shipment-dropdown");
          if (response.data.success) {
            const transformedCenters = response.data.data.map(
              (center: any) => ({
                value: center.id,
                label: center.name,
              })
            );

            setServiceCenters(transformedCenters);

            // First check localStorage
            const storedServiceCenter = localStorage.getItem(
              "selectedServiceCenter"
            );
            if (storedServiceCenter) {
              const parsedServiceCenter = JSON.parse(storedServiceCenter);
              form.setFieldsValue({
                serviceCenterName: parsedServiceCenter.value,
              });
            }
            // If no localStorage and exactly one service center, use existing logic
            else if (transformedCenters.length === 1) {
              const singleServiceCenter = {
                value: transformedCenters[0].value,
                label: transformedCenters[0].label,
              };

              localStorage.setItem(
                "selectedServiceCenter",
                JSON.stringify(singleServiceCenter)
              );

              form.setFieldsValue({
                serviceCenterName: singleServiceCenter.value,
              });
            }
          }
        } catch (error) {
          console.error("Failed to fetch service centers:", error);
        } finally {
          setServiceCentersLoading(false);
        }
      };
      const fetchItems = async () => {
        try {
          const response = await getApi("/shipment/getProducts?type=true");
          if (response.data.data.length > 0) {
            setItemsWithDetails(response.data.data);
            setItems(
              response.data.data.map((center: any) => ({
                value: center.name,
                label: center.name,
              }))
            );
          }
        } catch (error) {
          console.error("Failed to fetch items:", error);
        }
      };
      fetchServiceCenters();
      fetchItems();
    }
  }, [isVisible]);

  const onFieldsChange = () => {
    const deliveryDate = form.getFieldValue("deliveryDate");
    if (deliveryDate) {
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: true },
        { value: "SHIPPED", label: "Shipped", disabled: false },
      ]);
      form.setFieldsValue({ status: "SHIPPED" });
    } else {
      setStatusOptions([
        { value: "READY_TO_SHIP", label: "Ready to Ship", disabled: false },
        { value: "SHIPPED", label: "Shipped", disabled: true },
      ]);
      form.setFieldsValue({ status: "READY_TO_SHIP" });
    }
  };
  const handleAddItem = () => {
    setItemsArray([
      ...itemsArray,
      { itemName: "", quantity: "", itemDescription: "" },
    ]);
  };

  const onFinish = async (values: any) => {
    // Validate itemsArray if it has any items
    if (itemsArray.length > 0) {
      const hasInvalidItems = itemsArray.some(
        (item) => !item.itemName || !item.quantity || Number(item.quantity) <= 0
      );

      if (hasInvalidItems) {
        toastText(
          "Please fill all required fields in additional items with valid values",
          "error"
        );
        return;
      }
    }
    try {
      const validItemsArray = itemsArray.filter(
        (item) => item.itemName && item.quantity && Number(item.quantity) > 0
      );
      const payload = {
        serviceCenterId: values.serviceCenterName,
        salesOrderNumber: values.salesOrder,
        deliveryDate: values.deliveryDate
          ? dayjs(values.deliveryDate).format("YYYY-MM-DD")
          : null,
        status: values.status,
        items: [
          {
            itemNumber: values.itemNumber,
            quantity: parseInt(values.quantity, 10),
            description: values.itemDescription,
          },
          ...validItemsArray.map((item) => ({
            itemNumber: item.itemName,
            quantity: parseInt(item.quantity, 10),
            description: item.itemDescription,
          })),
        ].filter((item) => item.itemNumber && item.quantity > 0),
      };

      let response;
      setIsLoading(true);
      if (selectedShipment) {
        response = await putApi(
          `/shipment/update-shipment/${selectedShipment.id}`,
          payload
        );
      } else {
        response = await postApi("/shipment/create-shipment", payload);
      }
      const message =
        response?.data?.message || "Shipment created successfully.";
      toastText(message, "success");
    } catch (error: any) {
      if (error.response?.data?.error?.additionalInfo?.missingParts) {
        setMissingParts(error.response.data.error.additionalInfo.missingParts);
        setShowMissingParts(true);
      } else {
        toastText("Something went wrong while creating shipment", "error");
      }
    } finally {
      setIsLoading(false);
      form.resetFields();
      setItemsArray([]);
      handleCancel();
      fetchAllShipmentDetails();
      setSelectedShipment(null);
    }
  };

  const handleItemChangea = (
    index: number,
    field: "itemName" | "quantity" | "itemDescription",
    value: string
  ) => {
    const updatedItems = [...itemsArray];
    updatedItems[index][field] = value;
    if (field === "itemName") {
      const selectedItem = itemsWithDetails.find((item) => item.name === value);
      updatedItems[index].itemDescription = selectedItem?.description || "";
    }
    setItemsArray(updatedItems);
  };

  const handleRemoveItem = (index: number) => {
    const updatedItems = itemsArray.filter((_, i) => i !== index);
    setItemsArray(updatedItems);
    form.setFieldsValue({
      items: {
        [index]: {
          itemName: undefined,
          quantity: undefined,
          itemDescription: undefined
        }
      }
    });
  };

  const handleItemChange = (selectedItemName: string) => {
    const selectedItem = itemsWithDetails.find(
      (item) => item.name === selectedItemName
    );

    form.setFieldValue("itemDescription", selectedItem?.description || "");
  };
  return (
    <>
      <Modal
        open={isVisible}
        onCancel={() => {
          handleCancel();
          setSelectedShipment(null);
          form.resetFields();
          setItemsArray([]);
        }}
        footer={null}
        maskClosable={true}
        closable={false}
        width={1100}
        className={styles["add-shipment-modal"]}
        styles={{
          body: {
            maxHeight: "calc(100vh - 100px)",
            overflow: "auto",
            padding: "2px",
          },
        }}
        destroyOnClose={true}
      >
        <div style={{ padding: "10px" }}>
          <Title level={4}>
            {selectedShipment
              ? "Edit Shipment Manually"
              : "Add Shipment Manually"}
          </Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            initialValues={{
              serviceCenterName: null,
              salesOrder: "",
              itemName: null,
              itemDescription: "",
              quantity: "",
              deliveryDate: null,
              status: "READY_TO_SHIP",
            }}
          >
            <Row gutter={24}>
              {shipmentFields.map((singleField: any, index: number) => {
                return (
                  <Col
                    span={
                      ["itemDescription", "itemNumber", "quantity"].includes(
                        singleField.id
                      )
                        ? 8
                        : 12
                    }
                    style={{ marginBottom: "16px" }}
                    key={index}
                  >
                    <label className={styles["side-drawer-form__role--label"]}>
                      {singleField.title}{" "}
                      {singleField?.required && (
                        <span
                          className="required-color"
                          style={{ marginLeft: "5px" }}
                        >
                          *
                        </span>
                      )}
                    </label>

                    {singleField.id === "serviceCenterName" && (
                      <Form.Item
                        name="serviceCenterName"
                        rules={[
                          {
                            required: true,
                            message: `Please select ${singleField.title}`,
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${singleField.title}`}
                          options={serviceCenters}
                          showSearch
                          optionFilterProp="label"
                          filterOption={(
                            input: string,
                            option?: ServiceCenterOption
                          ) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(value: string, option: any) => {
                            // Store the selected service center in localStorage
                            const selectedServiceCenter = {
                              value: value,
                              label: option.label,
                            };
                            localStorage.setItem(
                              "selectedServiceCenter",
                              JSON.stringify(selectedServiceCenter)
                            );

                            // If you need to handle the form value change
                            form.setFieldsValue({ serviceCenterName: value });
                          }}
                          loading={serviceCenterLoading}
                        />
                      </Form.Item>
                    )}

                    {singleField.id === "salesOrder" && (
                      <Form.Item
                        name="salesOrder"
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${singleField.title}`,
                          },
                        ]}
                      >
                        <Input
                          ref={salesOrderInputRef}
                          size="large"
                          placeholder={`Enter ${singleField.title}`}
                        />
                      </Form.Item>
                    )}

                    {singleField.id === "deliveryDate" && (
                      <Form.Item name="deliveryDate">
                        <DatePicker
                          placeholder={`Select ${singleField.title}`}
                          style={{ width: "100%" }}
                          size="large"
                          format="MM/DD/YYYY"
                        />
                      </Form.Item>
                    )}
                    {singleField.id === "status" && (
                      <Form.Item name="status">
                        <Select
                          placeholder={`Select ${singleField.title}`}
                          size="large"
                          options={statusOptions}
                        ></Select>
                      </Form.Item>
                    )}
                    {singleField.id === "itemNumber" && (
                      <Form.Item
                        name="itemNumber"
                        rules={[
                          {
                            required: true,
                            message: `Please select ${singleField.title}`,
                          },
                        ]}
                      >
                        <Select
                          size="large"
                          placeholder={`Select ${singleField.title}`}
                          showSearch
                          optionFilterProp="label"
                          options={items}
                          onChange={handleItemChange}
                        />
                      </Form.Item>
                    )}
                    {singleField.id === "quantity" && (
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${singleField.title}`,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="number"
                          placeholder={`Enter ${singleField.title}`}
                        />
                      </Form.Item>
                    )}
                    {singleField.id === "itemDescription" && (
                      <Form.Item name="itemDescription">
                        <Input.TextArea
                          size="large"
                          placeholder={`Enter ${singleField.title}`}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                      </Form.Item>
                    )}
                  </Col>
                );
              })}
              {itemsArray.map((item, index) => (
                <React.Fragment key={index}>
                  <Col
                    span={8}
                    style={{
                      marginBottom: "16px",
                      color: "#27272e",
                    }}
                  >
                    <Form.Item
                      label={
                        <span
                          className={styles["side-drawer-form__role--label"]}
                        >
                          Item Name
                          <span className="required-color" style={{ marginLeft: "5px" }}>*</span>
                        </span>
                      }
                      name={["items", index, "itemName"]}
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: "Please select an Item name",
                          validateTrigger: ["onChange", "onBlur"],
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Select Item Name"
                        options={items}
                        value={item.itemName}
                        showSearch
                        onChange={(value) =>
                          handleItemChangea(index, "itemName", value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={8}
                    style={{
                      marginBottom: "16px",
                      color: "#27272e",
                    }}
                  >
                    <Form.Item
                      label={
                        <span
                          className={styles["side-drawer-form__role--label"]}
                        >
                          Quantity
                          <span className="required-color" style={{ marginLeft: "5px" }}>*</span>
                        </span>
                      }
                      name={["items", index, "quantity"]}
                      required={false}
                      rules={[
                        {
                          required: true,
                          message: "Please enter quantity",
                          validateTrigger: "onChange",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        size="large"
                        placeholder="Enter Quantity"
                        value={item.quantity}
                        onChange={(e) =>
                          handleItemChangea(index, "quantity", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={8}
                    style={{
                      marginBottom: "16px",
                      fontSize: "1.6rem",
                      fontWeight: "600",
                      color: "#27272e",
                    }}
                  >
                    <Form.Item
                      label={<span className={styles["side-drawer-form__role--label"]}>
                      Item Description
                    </span>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter item description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Enter Item Description"
                        value={item.itemDescription}
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onChange={(e) =>
                          handleItemChangea(
                            index,
                            "itemDescription",
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{ marginBottom: "16px", textAlign: "right" }}
                  >
                    <Button
                      type="link"
                      danger
                      onClick={() => handleRemoveItem(index)}
                    >
                      Remove
                    </Button>
                  </Col>
                </React.Fragment>
              ))}

              {!selectedShipment && (<Col span={6}>
                <Button
                  type="text"
                  onClick={handleAddItem}
                  style={{ width: "100%" }}
                >
                  +Add New Item
                </Button>
              </Col>)}
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "20px",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="save-button"
                loading={isLoading}
              >
                {selectedShipment ? "Update" : "Save"}
              </Button>
              <Button
                size="large"
                htmlType="button"
                style={{
                  marginLeft: "10px",
                }}
                onClick={() => {
                  handleCancel();
                  setSelectedShipment(null);
                  form.resetFields();
                  setItemsArray([]);
                }}
                className="cancel-button"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {missingParts?.length > 0 && (
        <MissingPartsModal
          isVisible={showMissingParts}
          handleClose={() => setShowMissingParts(false)}
          missingParts={missingParts}
        />
      )}
    </>
  );
};

export default AddShipmentManuallyModal;
