import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { postApi } from "redux/apis";

export const loginAction = createAsyncThunk(
  "auth/Login",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi("/auth/login", data);
      if (response?.data?.statusCode === 200) {
        {
          const cookieExpiration = 30; // 30 days if true, 1 day if false
          Cookies.set("accessToken", response?.data?.data, {
            expires: cookieExpiration, // Set the cookie expiration
          });
          return response.data;
        }
      } else {
        throw Error(response?.data?.message);
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
