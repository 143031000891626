import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { qbdItemsAction } from "redux/action/itemsAction";
import { AppDispatch } from "redux/store";
import DynamicTable from "./Table";
import NewCompany from "./NoCompany/newCompany";
import { useNavigate } from "react-router-dom";
import { getApi, putApi } from "redux/apis";
import { toastText } from "utils/utils";

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  // Redux state
  const { items: ItemDataState, totalRecords } = useSelector(
    (state: any) => state?.qbdItems?.data || {}
  );

  const allActiveConnection = useSelector(
    (state: any) => state?.ActiveServices?.data
  );

  const [PageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemDataSource, setItemDataSource] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchFields, setSearchFields] = useState<string[]>([
    "name",
    "salesDescription",
  ]);

  const [itemModelOptions, setItemModelOptions] = useState([]);

  // Handlers
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  const resetButtonHandler = () => {
    setSearchValue("");
    setSearchFields(["name", "salesDescription"]);
    setCurrentPage(1);
  };

  const searchFieldsSelectHandler = (value: string[]) => {
    setSearchFields(value);
    setCurrentPage(1);
  };
  const profileData = useSelector((state: any) => state?.userProfile?.data);
  const roleName = profileData?.companyRoles[0]?.role?.roleName;

  const fetchItemModelOptions = async () => {
    try {
      const response = await getApi("/itemModel/model-options");
      setItemModelOptions(response?.data?.data?.data);
    } catch (error: any) {
      toastText(
        error.response?.data?.error?.message ||
          "Something went wrong in fetching item models",
        "error"
      );
    }
  };

  const handleModelChange = async (value: any, record: any) => {
    try {
      const response = await putApi("/items/update-model", {
        id: record.id,
        modelId: value,
      });
      setItemDataSource((prev) =>
        prev.map((item) => {
          if (item.id === record.id) {
            return { ...item, modelId: value };
          }
          return item;
        })
      );
      const message = response?.data?.message || "Model updated successfully";
      toastText(message, "success");
    } catch (error: any) {
      const message =
      error.response?.data?.error?.message ||
        "Something went wrong while updating model";
      toastText(message, "error");
    }
  };

  useEffect(() => {
    if (roleName == "Service Center") {
      navigate("/inventory-control");
    } else if (roleName == "Admin 3nStar") {
      navigate("");
    }
  }, []);
  // Fetch data from Redux action
  useEffect(() => {
    const query = {
      pageSize: PageSize,
      pageNumber: currentPage,
      fields: searchFields,
      searchValue: searchValue,
    };

    const fetchData = async () => {
      try {

        await dispatch(getActiveConnectionAction());
        await dispatch(qbdItemsAction(query));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data on component mount
  }, [dispatch, PageSize, currentPage, searchFields, searchValue]);

  // Update itemDataSource whenever ItemDataState changes
  useEffect(() => {
    if (ItemDataState) {
      setItemDataSource(ItemDataState);
    }
  }, [ItemDataState]);

  useEffect(() => {
    fetchItemModelOptions();
  }, []);

  return (
    <>
      <>
        {allActiveConnection &&
        allActiveConnection.length > 0 &&
        allActiveConnection[0].isActiveConnection ? (
          <div>
            <p
              style={{
                margin: "10px 0px",
                fontSize: "16px",
              }}
            >
              Item Inventory data for :&nbsp;
              <span
                style={{
                  fontWeight: "bolder",
                }}
              >
                {allActiveConnection &&
                  allActiveConnection.length > 0 &&
                  allActiveConnection[0].companyName}
              </span>
            </p>
            <DynamicTable
              itemDataSource={itemDataSource}
              paginationChangeHandler={paginationChangeHandler}
              PageSize={PageSize}
              currentPage={currentPage}
              totalRecords={totalRecords}
              performSearchHandler={performSearchHandler}
              searchValue={searchValue}
              searchFieldsSelectHandler={searchFieldsSelectHandler}
              resetButtonHandler={resetButtonHandler}
              searchFields={searchFields}
              itemModelOptions={itemModelOptions}
              handleModelChange={handleModelChange}
            />
          </div>
        ) : (
          <NewCompany />
        )}
      </>
    </>
  );
};

export default Home;
