import { TableActionHeader } from "components/Global";
import { ChangeEvent, useEffect, useState } from "react";
import SalesOrderTable from "./SalesOrderTable";
import { getApi } from "redux/apis";
import { toastText } from "utils/utils";

const SalesOrder = (props: any) => {
  const { packageId, isDefaultCustomer, handleCancel, fetchItems } = props;
  const [salesOrderData, setSalesOrderData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [defaultCustomer, setDefaultCustomer] = useState<any[]>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleCustomerChange = (customer: string | null) => {
    setSelectedCustomer(customer);
  };

  const performSortHandler = (type: string) => {
    if (type !== sortOrder) {
      setSortOrder(type);
      setSortOrder(type === "ascend" ? "asc" : type ? "desc" : "");
      setSortField("shipDate");
    }
  };

  const fetchAllSalesORderCustomerWise = async () => {
    try {
      if (!selectedCustomer) {
        setSalesOrderData([]);
        setTotalRecords(0);
        return;
      }

      const query: any = {
        searchValue: searchValue,
        customerId: selectedCustomer,
        sortBy: sortField,
        sortOrder: sortOrder,
      };
      setIsLoading(true);
      try {
        const response = await getApi("/sales-order/", query);
        setSalesOrderData(response?.data?.data?.data);
        setTotalRecords(response?.data?.data?.total);
      } catch (error) {
        console.log("error: ", error);
      }
    } catch (error: any) {
      if (error.response?.data?.error?.code !== 103) {
        toastText("Something went wrong in fetching sales order", "error");
      } else {
        toastText(error.response?.data?.error?.message, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitialLoad = async () => {
    if (packageId && isDefaultCustomer) {
      try {
        const query = {
          packagingId: packageId,
          searchValue: searchValue,
        };
        setIsLoading(true);
        const response = await getApi(
          "/packing-list/customer-sales-orders",
          query
        );
        const customer = response?.data?.data?.customer;
        const customerId = customer?.id;

        setSelectedCustomer(customerId);

        setDefaultCustomer(customer ? [customer] : []);

        setSalesOrderData(response?.data?.data?.salesOrders || []);
        setTotalRecords(response?.data?.data?.total || 0);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        toastText("Failed to load customer data", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (packageId && isDefaultCustomer) {
      handleInitialLoad();
    }
  }, [packageId, isDefaultCustomer, searchValue]);

  useEffect(() => {
    if (!isDefaultCustomer) {
      fetchAllSalesORderCustomerWise();
    }
  }, [searchValue, selectedCustomer, isDefaultCustomer, sortOrder, sortField]);

  return (
    <>
      <div>
        {!isDefaultCustomer && <TableActionHeader title={"Sales Order"} />}

        <SalesOrderTable
          salesOrderData={salesOrderData}
          isLoading={isLoading}
          performSearchHandler={performSearchHandler}
          totalRecords={totalRecords}
          searchValue={searchValue}
          fetchSalesOrder={fetchAllSalesORderCustomerWise}
          onCustomerChange={handleCustomerChange}
          selectedCustomer={selectedCustomer}
          isDefaultCustomer={isDefaultCustomer}
          defaultCustomer={defaultCustomer}
          packageId={packageId}
          sortDirection={sortOrder}
          performSortHandler={(type: any) => performSortHandler(type)}
          handleCancel={handleCancel}
          fetchItems={fetchItems}
        />
      </div>
    </>
  );
};

export default SalesOrder;
