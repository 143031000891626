import { Popover, Space, Table, Tag } from "antd";
import Ellipse from "components/Global/Ellipse";
import moment from "moment";
import { REGISTER_STATUS_CONFIG } from "utils/shipment";
import { EditActionSvg, SortSvgBottom, SortSvgTop } from "utils/svgs";
import { invalidText } from "utils/utils";
import { ChangeEvent } from "react";
import { pageSizeOptionsPaging } from "utils/constant";
import SearchAndFilter from "../SearchAndFilter";
import styles from "./index.module.scss";

const { Column } = Table;
interface Props {
  allRegisterData: any;
  setSelectedRegisterSP: (data: any) => void;
  paginationChangeHandler: (pageNo: number, pageSize: number) => void;
  currentPage: number;
  totalRecords: number;
  performSearchHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  openModalHandler: () => void;
  performSortHandler?: (type: string, current: string) => void;
  modifyPageSize: any;
  PageSize: number;
  sortDirection?: any;
  isLoading: boolean;
  handleOk: any;
  handleServiceCenterChange: any;
  selectedServiceCenter: any;
  handleSparePartsChange: any;
  selectedSpareParts: any;
  fetchSpareParts: any;
  handleFromDateChange: any;
  handleToDateChange: any;
  fromDate: any;
  toDate: any;
  handleSalesOrderNumberChange: any;
  selectedSalesOrderNumbers: any;
  handleProductNameChange: any;
  selectedProductName: any;
  isServiceCenter: any;
  handleSelectedStatus: any;
  selectedStatus: any;
  isEmployee: any;
  setFiltersReset: any;
}
const SortingIcon = (data: any) => {
  return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const RegisterSparePartTable = (props: Props) => {
  const {
    allRegisterData,
    isLoading,
    setSelectedRegisterSP,
    openModalHandler,
    totalRecords,
    searchValue,
    performSearchHandler,
    currentPage,
    performSortHandler,
    PageSize,
    modifyPageSize,
    paginationChangeHandler,
    sortDirection,
    isServiceCenter,
    handleOk,
    handleServiceCenterChange,
    selectedServiceCenter,
    handleSparePartsChange,
    selectedSpareParts,
    handleFromDateChange,
    handleToDateChange,
    fromDate,
    toDate,
    fetchSpareParts,
    handleSalesOrderNumberChange,
    selectedSalesOrderNumbers,
    handleProductNameChange,
    selectedProductName,
    handleSelectedStatus,
    selectedStatus,
    isEmployee,
    setFiltersReset,
  } = props;

  const editDataHandler = (data: any) => {
    openModalHandler();
    setSelectedRegisterSP(data);
  };

  const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
    performSortHandler &&
      performSortHandler(columnInfo.order, columnInfo?.column?.key);
  };
  const expandedRowRender = (record: any) => {
    return (
      <Table
        dataSource={[record]} // Pass the current record as the dataSource
        pagination={false}
        className="bg-white"
        style={{ width: "100%", padding: "1.5rem 0px" }}
      >
        <Column
          title="SparePart Description"
          dataIndex="itemDescription"
          key="itemDescription"
          className="bg-white"
          render={(text) =>
            text ? (
              <Ellipse
                message={text}
                maxLength={50}
                isTooltip={true}
                tooltipMessage={text}
              />
            ) : (
              "-"
            )
          }
        />
        <Column
          title="Product Name"
          dataIndex="productName"
          key="productName"
          className="bg-white"
          render={(text: string) => {
            if (invalidText(text)) return "-";
            if (text && text.length > 20) {
              // Adjust the length threshold as needed
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 20)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="Product Description"
          dataIndex="productDescription"
          key="productDescription"
          className="bg-white"
          render={(text: string) => {
            if (invalidText(text)) return "-";
            if (text && text.length > 20) {
              // Adjust the length threshold as needed
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 20)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="Customer Name"
          dataIndex="customerName"
          key="customerName"
          className="bg-white"
          render={(text: string) => (invalidText(text) ? "-" : text)}
        />
        <Column
          title="Service Center Ticket Number"
          dataIndex="serviceCenterTicket"
          key="serviceCenterTicket"
          className="bg-white"
          render={(text: string) => (invalidText(text) ? "-" : text)}
        />
        <Column
          title="Comment"
          dataIndex="registerComments"
          key="registerComments"
          className="bg-white"
          render={(text) =>
            text ? (
              <Ellipse
                message={text}
                maxLength={25}
                isTooltip={true}
                tooltipMessage={text}
              />
            ) : (
              "-"
            )
          }
        />
      </Table>
    );
  };
  [];

  return (
    <div className={styles["register-table__wrapper"]}>
      <SearchAndFilter
        performSearchHandler={performSearchHandler}
        searchValue={searchValue}
        isServiceCenter={isServiceCenter}
        handleServiceCenterChange={handleServiceCenterChange}
        selectedServiceCenter={selectedServiceCenter}
        handleSparePartsChange={handleSparePartsChange}
        selectedSpareParts={selectedSpareParts}
        handleFromDateChange={handleFromDateChange}
        handleToDateChange={handleToDateChange}
        onApply={handleOk}
        fromDate={fromDate}
        toDate={toDate}
        handleSalesOrderNumberChange={handleSalesOrderNumberChange}
        selectedSalesOrderNumbers={selectedSalesOrderNumbers}
        handleProductNameChange={handleProductNameChange}
        selectedProductName={selectedProductName}
        fetchSpareParts={fetchSpareParts}
        handleStatusChange={handleSelectedStatus}
        selectedStatus={selectedStatus}
        setFiltersReset={setFiltersReset}
      />

      <Table
        dataSource={allRegisterData}
        rowKey={(record) => record.id}
        loading={isLoading}
        expandable={{
          expandedRowRender,
        }}
        onChange={tableChangeHandler}
        pagination={{
          total: totalRecords,
          current: currentPage,
          onChange: paginationChangeHandler,
          pageSize: PageSize,
          pageSizeOptions: pageSizeOptionsPaging,
          showSizeChanger: true,
          onShowSizeChange: modifyPageSize,
        }}
        scroll={{
          y: "calc(100vh - 450px)",
          x: allRegisterData.length > 0 ? "100%" : "0",
        }}
      >
        <Column
          title="#"
          className="bg-white"
          key="serialNumber"
          width={"8%"}
          render={(_: any, __: any, index: number) =>
            (currentPage - 1) * PageSize + index + 1
          }
        />
        <Column
          title="Service Center"
          dataIndex="serviceCenter"
          key="serviceCenter"
          className="bg-white"
          width={"19%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => {
            if (text && text.length > 11) {
              return (
                <Popover content={text} trigger="hover">
                  <span>{text.slice(0, 11)}...</span>
                </Popover>
              );
            }
            return text;
          }}
        />
        <Column
          title="3nStar Sales Order"
          dataIndex="salesOrderNumber"
          key="salesOrderNumber"
          className="bg-white"
          width={"22%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => (invalidText(text) ? "-" : text)}
        />
        <Column
          title="Arrival Date"
          dataIndex="arrivalDate"
          key="arrivalDate"
          className="bg-white"
          width={"20%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) =>
            text ? moment(text).format("MM/DD/YYYY") : "-"
          }
        />
        <Column
          title="Spare Part Item"
          dataIndex="sparePartItem"
          key="sparePartItem"
          className="bg-white"
          width={"18%"}
          render={(text: string) => (invalidText(text) ? "-" : text)}
        />
        <Column
          title="Use Date"
          dataIndex="useDate"
          key="useDate"
          className="bg-white"
          width={"15%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(text: string) => {
            // Check if text is "-" or invalid
            if (
              !text ||
              text === "-" ||
              text === "null" ||
              text === "undefined"
            ) {
              return "-";
            }

            // Try to parse the date and check if it's valid
            const date = moment(text);
            if (!date.isValid()) {
              return "-";
            }

            return date.format("MM/DD/YYYY");
          }}
        />
        <Column
          title="Remaining Items"
          dataIndex="remainingItems"
          key="remainingItems"
          className="bg-white"
          width={"19%"}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          className="bg-white"
          width={"12%"}
          showSorterTooltip={{ title: "" }}
          defaultSortOrder={sortDirection}
          sorter={() => {
            return null as any;
          }}
          sortDirections={["ascend", "descend", "ascend"]}
          sortIcon={(data) => <SortingIcon data={data} />}
          render={(status: string) => {
            // Capitalizing first letter of each word
            const formattedStatus = status
              ?.replace(/_/g, " ") // Replaces underscores with spaces
              .toLowerCase() // Converts the string to lowercase
              .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalizes the first letter of each word

            return (
              <Tag
                color={
                  REGISTER_STATUS_CONFIG[
                    status as keyof typeof REGISTER_STATUS_CONFIG
                  ]?.color
                }
              >
                {formattedStatus}
              </Tag>
            );
          }}
        />
        <Column
          title="Actions"
          key="actions"
          className="bg-white"
          width={"10%"}
          render={(values: any, data: any) => {
            const useDate = moment(data.useDate); // Parse the useDate
            const currentDate = moment(); // Get the current date
            const daysDifference = currentDate.diff(useDate, "days"); // Calculate the difference in days

            // Logic to determine if edit action is enabled
            const isEditEnabled =
              !isEmployee && // Only enable for non-employee roles
              (data.status !== "IN_USE" ||
                (data.status === "IN_USE" && daysDifference <= 3));

            return (
              <Space size={20}>
                {isEditEnabled ? (
                  <div
                    className="cursor-pointer flex align-center justify-center"
                    onClick={() => editDataHandler(data)}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>
                ) : (
                  <div
                    className="flex align-center justify-center"
                    style={{
                      marginRight: "2rem",
                      opacity: 0.3, // Makes the icon appear disabled
                      cursor: "not-allowed", // Disables click interaction
                    }}
                  >
                    <EditActionSvg />
                  </div>
                )}
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default RegisterSparePartTable;
