import { useState, useEffect } from "react";
import { getApi } from "redux/apis";

interface InventoryCounts {
  inventory: number;
  registerSpareParts: number;
}

export const useInventoryCounts = () => {
  const [counts, setCounts] = useState<InventoryCounts>({
    inventory: 0,
    registerSpareParts: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchCounts = async () => {
    try {
      const response = await getApi("/inventory/count");
      //const sparePartsResponse = await getApi("/inventory/recieved-count");
      setCounts({
        inventory: response.data.data.shippedCount,
        registerSpareParts: response.data.data.receivedCount,
      });
    } catch (error) {
      console.error("Error fetching inventory counts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  return { counts, loading, refetchCounts: fetchCounts };
};
