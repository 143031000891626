import { FC } from "react";
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";

import { integrationsAccountCards } from "utils/staticObject";

const AccountingTable: FC = () => {
  return (
    // Render the DynamicCategoryTable component with the provided data source and type
    <DynamicCategoryTable
      dataSource={integrationsAccountCards}
      type={integrationsAccountCards[0]?.type}
    />
  );
};

export default AccountingTable;
