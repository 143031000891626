import axios from "axios";
import Cookies from "js-cookie";
// import { useDispatch } from "react-redux";

const endPoint = process.env.REACT_APP_API_ENDPOINT;

const apiConfig = (flag = false, params?: any) => {
  if (Cookies.get("accessToken")) {
    return {
      params: params,
      headers: {
        Authorization: `bearer ${Cookies.get("accessToken")}`,
        "Content-Type": flag ? "multipart/form-data" : "application/json",
      },
      method: "PUT,DELETE,POST,GET,OPTION",
    };
  }
  return { withCredentials: false };
};

export const getApi = (url?: string, params?: any) => {
  return axios.get(`${endPoint}${url}`, {
    params: params,
    ...apiConfig(false, params),
  });
};

export const postApi = (url: string, apiData?: any, flag?: boolean) => {
  return axios.post(`${endPoint}${url}`, apiData, apiConfig(flag));
};

export const postWithParamsApi = (url: string, apiData?: any, params?: any) => {
  return axios.post(`${endPoint}${url}`, apiData, apiConfig(false, params));
};

export const putApi = (url: string, apiData: any, flag?: boolean) => {
  return axios.put(`${endPoint}${url}`, apiData, apiConfig(flag));
};

export const putApiNoHeader = (url: string, apiData: any) => {
  if (Cookies.get("accessToken")) {
    return axios.put(`${endPoint}${url}`, apiData, {
      headers: {
        Authorization: `bearer ${Cookies.get("accessToken")}`,
      },
    });
  } else {
    // If there's no access token, return an error response or handle it as needed.
    return Promise.reject("No access token available");
  }
};

export const deleteApi = (url: string) => {
  return axios.delete(`${endPoint}${url}`, apiConfig());
};

export const deleteApiWithData = (url: string, apiData?: any) => {
  return axios.delete(`${endPoint}${url}`, {
    data: apiData,
    ...apiConfig(),
  });
};

export const getApiExcelforPdf = async (url?: string, params?: any) => {
  const configData = await apiConfig();

  return axios.get(`${endPoint}${url}`, {
    params: { ...params },
    // params: params,
    responseType: "arraybuffer",
    ...configData,
  });
};

export const getApiExcel = async (url?: string, params?: any) => {
  const configData = await apiConfig();
  return axios.get(`${endPoint}${url}`, {
    ...configData, // Spread this first
    params: { ...params },
    responseType: "arraybuffer",
  });
};

// export const getApiExcelforPdf = async (url?: string, params?: any) => {
//   try {
//     const configData = await apiConfig();
//     console.log('configData: ', configData);
//     return await axios.get(`${endPoint}${url}`, {
//       ...configData,
//       params: { ...params },
//       responseType: "arraybuffer",
//     });
//   } catch (error) {
//     console.error("Error fetching PDF data:", error);
//     throw error;
//   }
// };

export const getApiCSV = async (url?: string, params?: any) => {
  const configData = await apiConfig();
  return axios.get(`${endPoint}${url}`, {
    ...configData, // Spread this first
    params: { ...params },
    responseType: "arraybuffer",
  });
};
