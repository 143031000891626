import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the slice
const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

// Create the CompanySlice using createSlice
const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // Handler for setting company data
    getCompanies: (state, action) => {
      // Extract the companies data from the action payload
      const companies = action.payload?.data?.companies;

      if (companies?.length > 0) {
        // Store the company ID in localStorage (assuming you want the first company's ID)
        localStorage.setItem('companyId', companies[0]?.company?.id);
      }

      // Update the state with the companies data
      state.data = companies;
    },
  },
});

// Export the getCompanies action
export const { getCompanies } = CompanySlice.actions;
export default CompanySlice;
