import { Modal } from "antd";
import { FC } from "react";
import { CloseSvg, DeleteSvg } from "utils/svgs";
import styles from "./index.module.scss";
import { ConfirmDeleteProps } from "./types";

// ConfirmDelete popup
const ConfirmDelete: FC<ConfirmDeleteProps> = (props) => {
  // Inits
  const {
    handleCancel,
    handleOk,
    isModalOpen,
    deleteHandler,
    isAddUserLoading,
  } = props;

  //   JSX
  return (
    <Modal
      title={
        <div className={styles["confirm-delete-model"]}>
          <DeleteSvg />
          <h4 className={styles["confirm-delete-model__title"]}>
            Are you sure you want to delete?
          </h4>
        </div>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      width={500}
      closeIcon={<CloseSvg />}
      footer={[
        <div key={"wrapper"} className={styles["confirm-delete-model__button"]}>
          <button
            key="ok"
            className={`${styles["confirm-delete-model__button--save"]} ${
              styles["confirm-delete-model__button--btn"]
            } ${isAddUserLoading && "pointer-event-none"}`}
            onClick={deleteHandler}
          >
            {isAddUserLoading ? (
              <img
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
                height={40}
              />
            ) : (
              "Delete"
            )}
          </button>
          <button
            key="cancel"
            onClick={handleCancel}
            className={`${styles["confirm-delete-model__button--cancel"]} ${styles["confirm-delete-model__button--btn"]}`}
          >
            Cancel
          </button>
        </div>
      ]}
    ></Modal>
  );
};

export default ConfirmDelete;
