import { Button, Table } from "antd";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getApi } from "redux/apis";
import { invalidText, toastText } from "utils/utils";
import EditRecordModal from "../EditShippingRecords";
import "./index.scss";

interface DataSourceType {
  salesOrderId: string;
  packageId: string;
  salesOrder: string;
  date: string;
  shippingMethod: string;
  notes: string;
}

const DraftShippingList: React.FC = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedShipping, setSelectedShipping] = useState<DataSourceType[]>(
  //   []
  // );

  const [isShippingLoading, setIsShippingLoading] = useState(false);

  // Flatten the data into the required format for the table
  useEffect(() => {
    getDraftShipping();
  }, [selectedPackageId]);

  const getDraftShipping = async () => {
    try {
      setIsShippingLoading(true);
      const response = await getApi("/shipping/get-draft-shippinglist");
      const flattenedData: DataSourceType[] = response.data.data.flatMap(
        (item: any) =>
          item.salesOrders.map((salesOrder: any) => ({
            salesOrderId: salesOrder.id,
            packageId: item.id, // Main ID for the group
            salesOrder: salesOrder.refNum,
            date: salesOrder.txnDate, // Just using the date part
            shippingMethod: salesOrder.shippingMethod,
            notes: salesOrder.notes,
          }))
      );

      setDataSource(flattenedData);
    } catch (error) {
      console.error("Failed to fetch draft shipping list:", error);
    } finally {
      setIsShippingLoading(false);
    }
  };

  const handleEdit = (record: DataSourceType) => {
    // Find all records with the same packageId
    // const relatedRecords = dataSource.filter(
    //   (item: DataSourceType) => item.packageId === record.packageId
    // );

    // Pass the array to the modal even if it contains a single item
    // setSelectedShipping(relatedRecords);
    setSelectedPackageId(record.packageId);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedPackageId("");
  };

  const handleDelete = async () => {
    if (selectedPackageId) {
      try {
        setIsDeleteLoading(true);
        const query: any = {
          packageId: selectedPackageId,
        };
        const response = await getApi(
          "/shipping/delete-draft-shipping-order",
          query
        );
        const message =
          response?.data?.message || "Shipping deleted successfully";
        toastText(message, "success");
      } catch (error: any) {
        const message =
        error.response?.data?.error?.message ||
          "Something went wrong while deleting shipping";
        toastText(message, "error");
      } finally {
        setIsDeleteLoading(false);
        setSelectedPackageId("");
        setIsDeleteModalOpen(false);
        getDraftShipping();
      }
    }
  };

  const getRowSpan = (id: string, index: number | undefined) => {
    if (index === undefined) return 0;
    const groupedRows = dataSource.filter((item: any) => item.packageId === id);
    const firstRowIndex = dataSource.findIndex(
      (item: any) => item.packageId === id
    );
    return index === firstRowIndex ? groupedRows.length : 0;
  };

  const columns = [
    {
      title: "Sales Order",
      dataIndex: "salesOrder",
      key: "salesOrder",
      className: "bg-white",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: "bg-white",
      render: (text: string) =>
        text ? moment(text).format("MM/DD/YYYY") : "-",
    },
    {
      title: "Shipping Method",
      dataIndex: "shippingMethod",
      key: "shippingMethod",
      className: "bg-white",
       render: (text: string) => (invalidText(text) ? "-" : text),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      className: "bg-white",
      render: (text: string) => (invalidText(text) ? "-" : text),
    },
    {
      title: "Actions",
      key: "actions",
      className: "bg-white",
      render: (_: any, record: DataSourceType, index: number) => {
        const rowSpan = getRowSpan(record.packageId, index);
        return rowSpan > 0 ? (
          <>
            <Button type="link" onClick={() => handleEdit(record)}>
              Edit
            </Button>
            <Button
              type="link"
              danger
              onClick={() => {
                setSelectedPackageId(record.packageId);
                setIsDeleteModalOpen(true);
              }}
            >
              Delete
            </Button>
          </>
        ) : null;
      },
      onCell: (_: DataSourceType, index: number | undefined) => ({
        rowSpan:
          index !== undefined
            ? getRowSpan(dataSource[index].packageId, index)
            : 0,
      }),
    },
  ];

  // Confirm operation
  const handleOk = () => {
    setSelectedPackageId("");
    setIsDeleteModalOpen(false);
  };

  // Cancel operation
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setSelectedPackageId("");
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey={(record: any) => record.salesOrderId}
        bordered
        loading={isShippingLoading}
        scroll={{ y: "calc(100vh - 250px)" }}
      />
      <ConfirmDelete
        handleCancel={handleDeleteCancel}
        handleOk={handleOk}
        isModalOpen={isDeleteModalOpen}
        deleteHandler={handleDelete}
        isAddUserLoading={isDeleteLoading}
      />

      <EditRecordModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        selectedPackageId={selectedPackageId}
      />
    </div>
  );
};

export default DraftShippingList;
