import { Button, Col, Form, Input, Modal, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { postApi, putApi } from "redux/apis";
import { toastText } from "utils/utils";

interface ItemModalProps {
  isVisible: boolean;
  handleCancel: () => void;
  selectedModal?: any;
  setSelectedItemModal: React.Dispatch<React.SetStateAction<any>>;
  fetchAllItemModels: () => void;
}

const ManageItemModal: React.FC<ItemModalProps> = ({
  isVisible,
  handleCancel,
  selectedModal,
  setSelectedItemModal,
  fetchAllItemModels,
}) => {
  const [form] = Form.useForm(); // Initialize form
  const [isLoading, setIsLoading] = useState(false);

  // Update form values when `selectedModal` changes
  useEffect(() => {
    if (selectedModal) {
      form.setFieldsValue({ modelName: selectedModal.modelName || "" });
    } else {
      form.resetFields();
    }
  }, [selectedModal, form]);

  const onFinish = async (values: any) => {
    setIsLoading(true);

    try {
      const payload = {
        modelName: values.modelName.trim(),
      };

      // If name hasn't changed, just close the modal and return
      if (
        selectedModal &&
        selectedModal.modelName === values.modelName.trim()
      ) {
        handleCancel(); // Close the modal
        setIsLoading(false);
        return;
      }

      let response;
      if (selectedModal) {
        response = await putApi(
          `/itemModel/edit-model/${selectedModal.id}`,
          payload
        );
      } else {
        response = await postApi("/itemModel/add-model", payload);
      }

      const message = response?.data?.message;
      toastText(message, "success");
    } catch (error: any) {
      const message =
        error.response?.data?.error?.message ||
        "Something went wrong while updating model";

      toastText(message, "error");
    } finally {
      setIsLoading(false);
      form.resetFields();
      handleCancel();
      fetchAllItemModels();
      setSelectedItemModal(null);
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={() => {
        handleCancel();
        setSelectedItemModal(null);
        form.resetFields();
      }}
      footer={null}
      maskClosable
      closable={false}
      destroyOnClose
    >
      <div style={{ padding: "10px" }}>
        <Title level={4}>
          {selectedModal ? "Edit Item Model" : "Add Item Model"}
        </Title>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={24} style={{ marginBottom: "16px" }}>
              <label className={styles["side-drawer-form__role--label"]}>
                Item Model
                <span className="required-color" style={{ marginLeft: "5px" }}>
                  *
                </span>
              </label>
              <Form.Item
                name="modelName"
                rules={[
                  {
                    validator: (_, value) =>
                      !value || !value.trim()
                        ? Promise.reject(
                            new Error("Please enter a valid item model")
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input size="large" placeholder="Enter item model" />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="save-button"
              loading={isLoading}
            >
              {selectedModal ? "Update" : "Save"}
            </Button>
            <Button
              size="large"
              htmlType="button"
              style={{ marginLeft: "10px" }}
              className="cancel-button"
              onClick={() => {
                handleCancel();
                setSelectedItemModal(null);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ManageItemModal;
