/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoginLayoutBody } from "components/Login";
import { FORMDATA } from "constants/Data";
import { LoginLayout } from "layouts";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginAction } from "redux/action/loginAction";
import { fetchProfileAction } from "redux/action/profileAction";
import { getRoleActionTable } from "redux/action/roleTableAction";
import { toastText } from "utils/utils";

// Login page
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const recaptchaRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage("addToCache");
    }
  }, []);

  const onSubmit = (values: any) => {
    const { email, password } = values;
    const newValues = {
      username: email.toLowerCase(),
      password,
      recaptchaValue,
    };

    const handleLoginSuccess = () => {
      dispatch(fetchProfileAction() as any)
        .then((res: any) => {
          if (
            res.payload.data.companyRoles[0].role.roleName === "Admin 3nStar"
          ) {
            dispatch(getRoleActionTable({ url: `` }) as any);
          }
          setIsLoading(false);
          navigate("/");
        })
        .catch((error: any) => {
          toastText(error?.message, "error");
          setIsLoading(false);
          navigate("/login");
        });
    };

    const handleLoginError = (error: any) => {
      toastText(error?.message, "error");
      setIsLoading(false);
      navigate("/login");
    };

    if (isPasswordValidate) {
      setIsLoading(true);
      dispatch(loginAction(newValues) as any)
        .unwrap()
        .then(handleLoginSuccess)
        .catch(handleLoginError);
    }
  };

  // JSX for the Login page
  return (
    <LoginLayout>
      <LoginLayoutBody
        title="Log in"
        description="<p>
							Welcome to <strong> 3nStar! </strong>Please enter your
							details.
						</p>"
        formData={FORMDATA.loginFields}
        buttonTitle={"Sign in"}
        action={loginAction}
        onSubmit={onSubmit}
        redirectUrl="/forgot-password"
        redirectText="Forgot password?"
        isLoading={isLoading}
        setIsPasswordValidate={setIsPasswordValidate}
        handleRecaptcha={(value: string) => {
          setRecaptchaValue(value);
        }}
        recaptchaRef={recaptchaRef}
      ></LoginLayoutBody>
    </LoginLayout>
  );
};

export default Login;
